.autocomplete-containter {
  position: relative;
  font-size: 50px;
}

.downshift-dropdown {
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  z-index: 999;
  border-bottom: 1px solid blue;
  border-left: 1px solid blue;
  border-right: 1px solid blue;
  border-top: 1px solid gray;
  margin-top: -2px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 50px;
}

/* .inputAutoComplete {
  font-size: 20px;
}
.dropdown-item {
  font-size: 15px;
} */

.inputAutoComplete {
  font-size: 300px;
}

.dropdown-item {
  font-size: 15px;
}

/*  notification */
.container-error-notification {
  background-color: red;
  width: 350px;
  padding: 20px;
  display: flex;
}

.container-success-notification {
  background-color: rgb(69, 158, 69);
  width: 350px;
  padding: 20px;
  display: flex;
}

.container-warning-notification {
  background-color: #ed8f02;
  width: 425px;
  padding: 20px;
  display: flex;
}

.container-icon {
  text-align: center;
}

.container-text {
  text-align: center;
}

.text-success-notification {
  color: white;
  font-size: 18px;
  margin: 0px;
  margin-left: 5px;
  width: 100%;
  font-weight: 500;
}
.text-error-notification {
  color: white;
  font-size: 18px;
  margin: 0px;
  margin-left: 5px;
  width: 100%;
  font-weight: 500;
}

.checkIcon {
  font-size: 30px !important;
  color: white;
}

.fontBold {
  font-weight: bold;
}

.icon-items-carrito {
  z-index: 10;
  margin-left: 40px;
  /* margin-bottom: 30px; */
}

.tablaMontos {
  background-color: #dbdbdb;
  border: 2px solid black;
  text-align: center;
}

.tdMontos {
  border-collapse: collapse;
  border: 2px solid black;
  padding: 10px;
}

.textMELI {
  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nav-wrapper .span-sugerencias {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
}

.nav-wrapper:hover .span-sugerencias {
  opacity: 1;
  visibility: visible;
}
