.boton-deposito {
  background-color: lightgrey !important;
  color: grey !important;

  font-size: 20px !important;
}

.body-boton-deposito {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
