.cancelar {
  color: #ffff;
  font-size: 12px;
}
.aceptar {
  font-size: 12px;
  color: #ffff;
}
.modal-confirm-body {
  justify-content: center;
  padding-bottom: 20px;
}
