/* *::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 5px;
  border-radius: 13px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
  border-radius: 20px;
} */
.main-sidebar {
  z-index: 1030 !important;
}

.modalValidarToken .modal-backdrop {
  z-index: 1500 !important;
}

.modal {
  height: "" !important;
  top: "" !important;
  left: "" !important;
}

/* Iconos del sistemas CSS */
.iconValidNros {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: green !important;
  margin-left: 3px;
  margin-right: 3px;
}

.iconCheckPrecio {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: black !important;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconCheckPrecio:hover {
  color: lightgrey !important;
}

.iconEtiquetas {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: orangered !important;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconEtiquetas:hover {
  color: orange !important;
}

.iconVolver {
  font-size: 35px !important;
  font-weight: bold;
  color: #243b55 !important;
  cursor: pointer;
}

.iconAddToSucursal {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: black;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconAddToSucursal:hover {
  color: lightgrey;
}

.iconDeleteMedio {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: black;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconEdit {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: black;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconEdit:hover {
  color: lightgrey;
}

.iconRoom {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: black;
  margin-left: 3px;
  margin-right: 3px;
  cursor: help;
}

.iconRoom:hover {
  color: lightgrey;
}

.iconStockEdit {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: black;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconStockEdit:hover {
  color: lightgrey;
}

.iconCarritoEdit {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: black;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconCarritoEdit:hover {
  color: lightgrey;
}

.iconEliminarUbicacion {
  font-size: 30px !important;
  /* font-weight: bold; */
  color: rgb(197, 72, 72) !important;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconEliminarUbicacion:hover {
  color: red !important;
}

.iconDanger {
  font-size: 45px !important;
  /* font-weight: bold; */
  color: #c4183c !important;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconCarrito {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: black !important;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconCarrito:hover {
  color: grey !important;
}

.iconCarritoGrande {
  font-size: 30px !important;
  /* font-weight: bold; */
  color: black !important;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 10px;
}

.iconFlechaTabla {
  font-size: 20px !important;
  /* font-weight: bold; */
  color: gray !important;
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 1px;
  cursor: pointer;
}

.iconFactura {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: #56ab2f !important;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconFactura:hover {
  color: #a8e063 !important;
}

.iconSinFactura {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: #2948ff !important;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconSinFactura:hover {
  color: #396afc !important;
}

.iconNotaCredito {
  font-size: 23px !important;
  /* font-weight: bold; */
  color: #b31217 !important;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconNotaCredito:hover {
  color: #e43a15 !important;
}

.iconInfo {
  font-size: 23px !important;
  /* font-weight: bold; */
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconNotaCredito2 {
  font-size: 21px !important;
  /* font-weight: bold; */
  color: #b31217 !important;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconNotaCredito2:hover {
  color: #e43a15 !important;
}

.iconAddProveedor {
  font-size: 38px !important;
  /* font-weight: bold; */
  color: rgb(0, 123, 255) !important;
  margin-left: 3px;
  margin-top: 2px;
  margin-right: 3px;
  cursor: pointer;
}

.btn-aplicarFiltro {
  float: right;
  font-weight: bolder !important;
  border-radius: 0px !important;
}

.btn-aplicarFiltro:hover {
  font-size: 20px;
  /* padding-left: 5px;
    padding-right: 5px; */
}

.btn-quitarFiltro {
  float: right;
  font-weight: bolder !important;
  border-radius: 0px !important;
}

.btn-quitarFiltro:hover {
  font-size: 20px;
  /* padding-left: 5px;
    padding-right: 5px; */
}

.style-badge {
  margin-right: 12px;
  display: inline-flex;
  float: right;
}

.style-badge-done {
  display: inline-flex;
  float: right;
  margin-right: 12px;
}

.ExcelTable2007 {
  border: 1px solid rgb(0, 123, 255);
  border-width: 1px 0px 0px 1px;
  font-size: 11pt;
  font-family: Calibri;
  font-weight: 100;
  border-spacing: 0px;
  border-collapse: collapse;
  padding: 10px;
}

.ExcelTable2007 TH {
  background-color: rgb(0, 123, 255);
  color: white;
  text-align: center;
  background-repeat: repeat-x;
  font-weight: bold;
  font-size: 14px;
  border: 1px solid rgb(0, 123, 255);
  border-width: 0px 1px 1px 0px;
  height: 17px;
  line-height: 17px;
}

.ExcelTable2007 TD {
  border: 0px;
  background-color: white;
  padding: 0px 4px 0px 2px;
  border: 1px solid rgb(0, 123, 255);
  border-width: 0px 1px 1px 0px;
}

.ExcelTable2007 TD B {
  border: 0px;
  background-color: white;
  font-weight: bold;
}

.ExcelTable2007 TD.heading {
  color: white;
  background-color: rgb(0, 123, 255);
  text-align: center;
  border: 1px solid rgb(0, 123, 255);
  border-width: 0px 1px 1px 0px;
  font-weight: bold;
}

.ExcelTable2007 TH.heading {
  background-repeat: none;
}

TH {
  text-align: center;
}

.labelCerrar {
  font-size: 16px;
  cursor: pointer;
}

@media (max-width: 1260px) {
  .labelCerrar {
    font-size: 15px;
  }
}

@media (max-width: 1210px) {
  .labelCerrar {
    font-size: 14px;
  }
}

@media (max-width: 1141px) {
  .labelCerrar {
    font-size: 13px;
  }
}

@media (max-width: 1060px) {
  .labelCerrar {
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .labelCerrar {
    font-size: 16px;
  }
}

.labelIconCerrar {
  font-size: 30px;
}

@media (max-width: 1141px) {
  .labelIconCerrar {
    font-size: 27px;
  }
}

@media (max-width: 1060px) {
  .labelIconCerrar {
    font-size: 25px;
  }
}

@media (max-width: 991px) {
  .labelIconCerrar {
    font-size: 30px;
  }
}

.labelVerVentas {
  font-weight: bold;
  font-size: 17px;
}

@media (max-width: 1260px) {
  .labelVerVentas {
    font-size: 15px;
  }
}

@media (max-width: 1141px) {
  .labelVerVentas {
    font-size: 13px;
  }
}

@media (max-width: 1060px) {
  .labelVerVentas {
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .labelVerVentas {
    font-size: 17px;
  }
}

.imgWallet {
  width: 55px;
  height: 55px;
  margin-top: 30px;
}

.labelWallet {
  font-size: 16px;
}

@media (max-width: 1060px) {
  .labelWallet {
    font-size: 14px;
  }
}

@media (max-width: 1260px) {
  .imgWallet {
    width: 50px;
    height: 45px;
    margin-top: 35px;
  }
}

@media (max-width: 1141px) {
  .imgWallet {
    width: 45px;
    height: 40px;
    margin-top: 35px;
  }
}

@media (max-width: 1060px) {
  .imgWallet {
    width: 40px;
    height: 35px;
    margin-top: 40px;
  }
}

@media (max-width: 991px) {
  .imgWallet {
    width: 75px;
    height: 75px;
    margin-top: 20px;
    margin-left: 10px;
  }
}

.justify-xs-center {
  @media (max-width: 600px) {
    justify-content: center;
  }
}
