.border-card {
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 1px solid whitesmoke !important;
}
.add-btn {
  font-size: 30px !important;
  padding: 0px !important;
}
.cardBody-Datos {
  padding: 10px !important;
}
.fila-nombre {
  padding: 0px !important;
}
.col-input-agregar {
  font-size: 30px !important;
  padding: 0px !important;
}
.text-filtro {
  font-size: 20px;
  font-weight: bolder;
}
.btn-agregar {
  font-size: 15px !important;
}
.btn-pdf {
  color: blue !important;
  background-color: ghostwhite;
  margin: 10px;
  margin-bottom: 18px;
  padding-bottom: 20px !important;
}
.message-pdf {
  text-align: center;
  padding-left: 20px !important;
}
.filtro {
  width: 100px !important;
  height: 10px;
  padding-right: 0px !important;
}
.border-card-text {
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 1px solid whitesmoke !important;
  height: 180px !important;
  margin: 10px !important;
}
.precio-repuesto {
  font-size: 18px !important;
}
.thead {
  height: 37px;
  overflow-y: auto;
  overflow-x: hidden;
}

.border-buscador {
  border-radius: 100px !important;
}

.input-group-seamles .input-group {
  border-radius: 5000px 0 0 5000px !important;
  padding-left: 1rem !important;
}

.text-webkit-center {
  text-align: -webkit-center;
}

.border-card-zero {
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 0px !important;
}

.table-wrap {
  height: 300px;
  overflow: auto;
}

.border-card-radius {
  box-shadow: none !important;
  border: 1px solid #393d45 !important;
}

.row-service {
  border: 1px solid #b8daff !important;
}

.result-card {
  width: 250px;
  text-align: center;
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
.button-result-card {
  margin-right: 0px !important;
  text-align: center !important;
  font-size: 20px !important;
  border-radius: 30px !important;
}
.style-patente {
  width: 100%;
  font-size: 16px !important;
  font-weight: bolder !important;
  background-color: black !important;
}
.style-badge-estado {
  display: inline !important;
}
.style-patente-NuevaOt {
  font-size: 16px !important;
  font-weight: bolder !important;
  background-color: black !important;
}
.button-action-ot {
  text-align: center !important;
  font-weight: bolder !important;
  font-size: 15px !important;
  margin-right: 0 !important;
  width: 100% !important;
}
.style-tr-ot {
  font-size: 14px;
}
.style-badge-ot {
  width: 100% !important;
  font-weight: bolder !important;
  font-size: 16px !important;
  /* display: inline-flex !important */
}
.input-vehiculo {
  height: 35px !important;
}
.style-estado {
  font-weight: bolder !important;
}
.text-select {
  font-size: 17px !important;
  color: gray !important;
  margin-top: 0px !important;
  vertical-align: center !important;
}

.centrar-texto {
  text-align: center !important;
  float: center !important;
  vertical-align: center !important;
}
.debito {
  padding: 7px;
  border-radius: 100% !important;
  cursor: pointer;
  box-shadow: 2px 2px 10px #666 !important;
  width: 120px;
  height: 100px;
}
.credito {
  padding: 10px;
  border-radius: 100% !important;
  cursor: pointer;
  box-shadow: 2px 2px 10px #666 !important;
  width: 120px;
  height: 100px;
}
.mensaje-error {
  margin-left: 10px;
}
.label-cantRecibida {
  margin-top: 5px;
  font-weight: bold;
  margin-left: 5px;
}
.cantRecibida {
  font-weight: bold;
  width: 220px !important;
  /* display: inline !important; */
  margin-left: 10px;
  margin-right: 13px;
}
.badgeCostototal {
  width: 100%;
  height: 100%;
  font-size: 30px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.manualDigital {
  padding: 20px;
  width: 120px;
  height: 100px;
  border-radius: 100% !important;
  cursor: pointer;
  box-shadow: 2px 2px 10px #666 !important;
}
.mercadoPago {
  width: 120px;
  height: 100px;
  border-radius: 100% !important;
  cursor: pointer;
  box-shadow: 2px 2px 10px #666 !important;
}
.button-result-card-icon {
  margin-right: 0px !important;
  width: 100px !important;
  height: 35px;
  text-align: center !important;
  /* font-size: 20px !important; */
  border-radius: 30px !important;
}
.btn-rigth {
  text-align: right !important;
  float: right !important;
}
.button-result {
  /* font-size: 20px !important; */
  display: flex !important;
  border-radius: 30px !important;
  justify-content: center !important;
  width: 120px !important;
  text-align: center !important;
}

.tittle-card {
  height: 50px;
  margin-top: 7px;
  text-align: center !important;
  font-weight: bold !important;
}

.modalBodyVentaCorrecta {
  background-color: green !important;
  color: white;
  font-size: bold;
  border-radius: 10px;
  padding: 10px !important;
}
.modalLoadingPago {
  /* background-color: red !important; */
  color: white;
  width: 400px;
  height: 50px;
  font-size: bold;
  border-radius: 10px;
  padding: 10px !important;
}
.modalBodyVentaIncorrecta {
  background-color: red !important;
  color: white;
  font-size: bold;
  border-radius: 10px;
  padding: 10px !important;
}
.modalVentaCorrecta {
  margin-top: 300px !important;
}
.no-bottom-padding {
  padding-bottom: 0;
}

.style1 {
  margin: 1px !important;
  margin-bottom: 7px !important;
}
.modal-content {
  /* width: 550px !important; */
  float: center;
  margin-left: 83px;
}

.title {
  margin-left: 3px !important;
  margin-top: 5px;
  margin-bottom: 0px;
  color: black;
  text-align: left !important;
  font-weight: bold !important;
}
.title-service {
  margin-top: 7px;
  margin-bottom: 0px;
  background-color: #393d45;
  color: white;
  font-weight: bold !important;
  margin-left: 0px !important;
}

/* .card-services{
    height: calc(120vh - 300px) !important
  } */

.breadcrumb {
  margin-bottom: 0px !important;
}

.br-divider {
  border-top: 1px solid #8c8b8b !important;
  margin: 1px !important;
  margin-top: 3px !important;
  margin-bottom: 7px !important;
  height: 1px;
}

.list-group {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.btn_ {
  float: right !important;
  /* text-align: end !important; */
  margin-right: 60px !important;
  margin-bottom: -10 !important;
}

.labelCliente {
  float: center !important;
  text-align: center;
  font-size: 20px;
  justify-content: center;
}

.label_card_vehicle {
  color: black !important;
  font-size: 15px;
  border: 5px;
}

.label-vehicle {
  margin-left: 11px;
  margin-top: 10px;
  float: left !important;
  color: black !important;
  font-size: 15px;
}

.card-result {
  margin-top: 10px;
  color: black !important;
  margin-bottom: 10px;
}

.btn-dropdown {
  border-top-right-radius: 0px !important;
  margin-right: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.dropdown {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-right: 0px !important;
}

.inputRepuesto {
  float: right !important;
}

/* icons */

.icon_style_edit {
  color: blue !important;
  display: inline !important;
}

.icon_style_selec {
  color: green !important;
  display: inline !important;
}

.icon_style_add {
  font-size: "80px" !important;
  font-size-adjust: "80px" !important;
  font-weight: bold;
}

.icon_style_remove {
  color: red !important;
  display: inline !important;
}

.icon_style_check {
  color: rgb(0, 167, 0) !important;
  display: inline !important;
}

.icon_style_details {
  color: grey !important;
  display: inline !important;
}

.icon_style_reply {
  color: #007bff;
  font-size: "100px" !important;
  float: left !important;
  margin: 8px !important;
}

.td_icon {
  inline-size: 0px !important;
}

.card-consulta-ot {
  height: calc(100vh - 430px);
}

.body-consulta-ot {
  overflow-y: auto;
}

.table-tr-color {
  background-color: #17c671 !important;
  color: white !important;
  border-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.table-radius-top {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.table-option {
  font-size: 24px !important;
  cursor: pointer;
}

.back-option {
  font-size: 36px !important;
  cursor: pointer;
}
.thead-color-card-header {
  background: #393d45 !important;
  border-color: #393d45 !important;
  color: white;
}

.btn-nuevaOT {
  width: 100px !important;
  margin: 0px !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
  margin-top: 0px !important;
  float: right !important;
}
.btn-agregar-servicio {
  float: right !important;
}
.page-header {
  /* style={{ margin: "5px !important", padding:"10px !important" }} */
  padding-bottom: 0.3rem !important;
  padding-top: 0.1rem !important;
  margin-left: 10px !important;
}

.pb-4,
.py-4 {
  padding-bottom: 0.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 0.5rem !important;
}

.btn-collapse {
  margin-left: 15px;
  margin-top: 0px;
  color: #393d45 !important;
}
.cobro-cliente {
  font-size: 200px !important;
  text-align: center !important;
}
.text-collapse {
  margin-left: 10px;
  display: flex !important;
  margin-bottom: 5px !important;
  display: inline-block;
  /* color:#17c671; */
  text-align: left !important;
}

.btn-circle {
  border-radius: 100% !important;
  /* box-shadow: 2px 2px 10px #666 !important; */
  cursor: pointer !important;
  border: solid !important;
  border-color: #8c8b8b !important;
}
/* .p:hover {
    background: red !important;
  } */
.subrayado {
  text-decoration: none !important;
}
.subrayado-link {
  text-decoration: none !important;
}
.badge-collapse {
  /* margin-left: 10px !important; */
  margin-bottom: 10px !important;
  background-color: #dfe1df !important;
  border: 1px solid #c8ccc8 !important;
  display: flex !important;
  cursor: pointer;
}
.btn-agregar-actividad {
  margin-bottom: 10px !important;
  /* background-color:#fdfffd  !important; */
  /* border: 1px solid #c8ccc8!important; */
  display: flex !important;
  cursor: pointer;
}

.modal-detalle-body {
  background-color: #dfe1df !important;
}

.card-margen-cliente {
  margin-right: 0px !important;
  padding-right: 0px !important;
}

.card-margen-vehiculo {
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
