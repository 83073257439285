.autocomplete-containter {
  position: relative;
  font-size: 50px;
}

.downshift-dropdown {
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  z-index: 999;
  border-bottom: 1px solid blue;
  border-left: 1px solid blue;
  border-right: 1px solid blue;
  border-top: 1px solid gray;
  margin-top: -2px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 50px;
}

/* .inputAutoComplete {
  font-size: 20px;
}
.dropdown-item {
  font-size: 15px;
} */

.inputAutoComplete {
  font-size: 300px;
}

.dropdown-item {
  font-size: 15px;
}

/*  notification */
.container-error-notification {
  background-color: red;
  width: 350px;
  padding: 20px;
  display: flex;
}

.container-success-notification {
  background-color: rgb(69, 158, 69);
  width: 350px;
  padding: 20px;
  display: flex;
}

.container-warning-notification {
  background-color: #ed8f02;
  width: 425px;
  padding: 20px;
  display: flex;
}

.container-icon {
  text-align: center;
}

.container-text {
  text-align: center;
}

.text-success-notification {
  color: white;
  font-size: 18px;
  margin: 0px;
  margin-left: 5px;
  width: 100%;
  font-weight: 500;
}
.text-error-notification {
  color: white;
  font-size: 18px;
  margin: 0px;
  margin-left: 5px;
  width: 100%;
  font-weight: 500;
}

.checkIcon {
  font-size: 30px !important;
  color: white;
}

.fontBold {
  font-weight: bold;
}

.icon-items-carrito {
  z-index: 10;
  margin-left: 40px;
  /* margin-bottom: 30px; */
}

.tablaMontos {
  background-color: #dbdbdb;
  border: 2px solid black;
  text-align: center;
}

.tdMontos {
  border-collapse: collapse;
  border: 2px solid black;
  padding: 10px;
}

.textMELI {
  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nav-wrapper .span-sugerencias {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
}

.nav-wrapper:hover .span-sugerencias {
  opacity: 1;
  visibility: visible;
}

/* *::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 5px;
  border-radius: 13px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
  border-radius: 20px;
} */
.main-sidebar {
  z-index: 1030 !important;
}

.modalValidarToken .modal-backdrop {
  z-index: 1500 !important;
}

.modal {
  height: "" !important;
  top: "" !important;
  left: "" !important;
}

/* Iconos del sistemas CSS */
.iconValidNros {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: green !important;
  margin-left: 3px;
  margin-right: 3px;
}

.iconCheckPrecio {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: black !important;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconCheckPrecio:hover {
  color: lightgrey !important;
}

.iconEtiquetas {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: orangered !important;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconEtiquetas:hover {
  color: orange !important;
}

.iconVolver {
  font-size: 35px !important;
  font-weight: bold;
  color: #243b55 !important;
  cursor: pointer;
}

.iconAddToSucursal {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: black;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconAddToSucursal:hover {
  color: lightgrey;
}

.iconDeleteMedio {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: black;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconEdit {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: black;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconEdit:hover {
  color: lightgrey;
}

.iconRoom {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: black;
  margin-left: 3px;
  margin-right: 3px;
  cursor: help;
}

.iconRoom:hover {
  color: lightgrey;
}

.iconStockEdit {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: black;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconStockEdit:hover {
  color: lightgrey;
}

.iconCarritoEdit {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: black;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconCarritoEdit:hover {
  color: lightgrey;
}

.iconEliminarUbicacion {
  font-size: 30px !important;
  /* font-weight: bold; */
  color: rgb(197, 72, 72) !important;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconEliminarUbicacion:hover {
  color: red !important;
}

.iconDanger {
  font-size: 45px !important;
  /* font-weight: bold; */
  color: #c4183c !important;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconCarrito {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: black !important;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconCarrito:hover {
  color: grey !important;
}

.iconCarritoGrande {
  font-size: 30px !important;
  /* font-weight: bold; */
  color: black !important;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 10px;
}

.iconFlechaTabla {
  font-size: 20px !important;
  /* font-weight: bold; */
  color: gray !important;
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 1px;
  cursor: pointer;
}

.iconFactura {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: #56ab2f !important;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconFactura:hover {
  color: #a8e063 !important;
}

.iconSinFactura {
  font-size: 25px !important;
  /* font-weight: bold; */
  color: #2948ff !important;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconSinFactura:hover {
  color: #396afc !important;
}

.iconNotaCredito {
  font-size: 23px !important;
  /* font-weight: bold; */
  color: #b31217 !important;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconNotaCredito:hover {
  color: #e43a15 !important;
}

.iconInfo {
  font-size: 23px !important;
  /* font-weight: bold; */
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconNotaCredito2 {
  font-size: 21px !important;
  /* font-weight: bold; */
  color: #b31217 !important;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.iconNotaCredito2:hover {
  color: #e43a15 !important;
}

.iconAddProveedor {
  font-size: 38px !important;
  /* font-weight: bold; */
  color: rgb(0, 123, 255) !important;
  margin-left: 3px;
  margin-top: 2px;
  margin-right: 3px;
  cursor: pointer;
}

.btn-aplicarFiltro {
  float: right;
  font-weight: bolder !important;
  border-radius: 0px !important;
}

.btn-aplicarFiltro:hover {
  font-size: 20px;
  /* padding-left: 5px;
    padding-right: 5px; */
}

.btn-quitarFiltro {
  float: right;
  font-weight: bolder !important;
  border-radius: 0px !important;
}

.btn-quitarFiltro:hover {
  font-size: 20px;
  /* padding-left: 5px;
    padding-right: 5px; */
}

.style-badge {
  margin-right: 12px;
  display: inline-flex;
  float: right;
}

.style-badge-done {
  display: inline-flex;
  float: right;
  margin-right: 12px;
}

.ExcelTable2007 {
  border: 1px solid rgb(0, 123, 255);
  border-width: 1px 0px 0px 1px;
  font-size: 11pt;
  font-family: Calibri;
  font-weight: 100;
  border-spacing: 0px;
  border-collapse: collapse;
  padding: 10px;
}

.ExcelTable2007 TH {
  background-color: rgb(0, 123, 255);
  color: white;
  text-align: center;
  background-repeat: repeat-x;
  font-weight: bold;
  font-size: 14px;
  border: 1px solid rgb(0, 123, 255);
  border-width: 0px 1px 1px 0px;
  height: 17px;
  line-height: 17px;
}

.ExcelTable2007 TD {
  border: 0px;
  background-color: white;
  padding: 0px 4px 0px 2px;
  border: 1px solid rgb(0, 123, 255);
  border-width: 0px 1px 1px 0px;
}

.ExcelTable2007 TD B {
  border: 0px;
  background-color: white;
  font-weight: bold;
}

.ExcelTable2007 TD.heading {
  color: white;
  background-color: rgb(0, 123, 255);
  text-align: center;
  border: 1px solid rgb(0, 123, 255);
  border-width: 0px 1px 1px 0px;
  font-weight: bold;
}

.ExcelTable2007 TH.heading {
  background-repeat: none;
}

TH {
  text-align: center;
}

.labelCerrar {
  font-size: 16px;
  cursor: pointer;
}

@media (max-width: 1260px) {
  .labelCerrar {
    font-size: 15px;
  }
}

@media (max-width: 1210px) {
  .labelCerrar {
    font-size: 14px;
  }
}

@media (max-width: 1141px) {
  .labelCerrar {
    font-size: 13px;
  }
}

@media (max-width: 1060px) {
  .labelCerrar {
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .labelCerrar {
    font-size: 16px;
  }
}

.labelIconCerrar {
  font-size: 30px;
}

@media (max-width: 1141px) {
  .labelIconCerrar {
    font-size: 27px;
  }
}

@media (max-width: 1060px) {
  .labelIconCerrar {
    font-size: 25px;
  }
}

@media (max-width: 991px) {
  .labelIconCerrar {
    font-size: 30px;
  }
}

.labelVerVentas {
  font-weight: bold;
  font-size: 17px;
}

@media (max-width: 1260px) {
  .labelVerVentas {
    font-size: 15px;
  }
}

@media (max-width: 1141px) {
  .labelVerVentas {
    font-size: 13px;
  }
}

@media (max-width: 1060px) {
  .labelVerVentas {
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .labelVerVentas {
    font-size: 17px;
  }
}

.imgWallet {
  width: 55px;
  height: 55px;
  margin-top: 30px;
}

.labelWallet {
  font-size: 16px;
}

@media (max-width: 1060px) {
  .labelWallet {
    font-size: 14px;
  }
}

@media (max-width: 1260px) {
  .imgWallet {
    width: 50px;
    height: 45px;
    margin-top: 35px;
  }
}

@media (max-width: 1141px) {
  .imgWallet {
    width: 45px;
    height: 40px;
    margin-top: 35px;
  }
}

@media (max-width: 1060px) {
  .imgWallet {
    width: 40px;
    height: 35px;
    margin-top: 40px;
  }
}

@media (max-width: 991px) {
  .imgWallet {
    width: 75px;
    height: 75px;
    margin-top: 20px;
    margin-left: 10px;
  }
}

.justify-xs-center {
  @media (max-width: 600px) {
    justify-content: center;
  }
}

.pointer-table tr {
  cursor: pointer;
}

.pointer-table tr:hover {
  background-color: aliceblue;
}

.cancelar {
  color: #ffff;
  font-size: 12px;
}
.aceptar {
  font-size: 12px;
  color: #ffff;
}
.modal-confirm-body {
  justify-content: center;
  padding-bottom: 20px;
}

.boton-deposito {
  background-color: lightgrey !important;
  color: grey !important;

  font-size: 20px !important;
}

.body-boton-deposito {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.border-card {
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 1px solid whitesmoke !important;
}
.add-btn {
  font-size: 30px !important;
  padding: 0px !important;
}
.cardBody-Datos {
  padding: 10px !important;
}
.fila-nombre {
  padding: 0px !important;
}
.col-input-agregar {
  font-size: 30px !important;
  padding: 0px !important;
}
.text-filtro {
  font-size: 20px;
  font-weight: bolder;
}
.btn-agregar {
  font-size: 15px !important;
}
.btn-pdf {
  color: blue !important;
  background-color: ghostwhite;
  margin: 10px;
  margin-bottom: 18px;
  padding-bottom: 20px !important;
}
.message-pdf {
  text-align: center;
  padding-left: 20px !important;
}
.filtro {
  width: 100px !important;
  height: 10px;
  padding-right: 0px !important;
}
.border-card-text {
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 1px solid whitesmoke !important;
  height: 180px !important;
  margin: 10px !important;
}
.precio-repuesto {
  font-size: 18px !important;
}
.thead {
  height: 37px;
  overflow-y: auto;
  overflow-x: hidden;
}

.border-buscador {
  border-radius: 100px !important;
}

.input-group-seamles .input-group {
  border-radius: 5000px 0 0 5000px !important;
  padding-left: 1rem !important;
}

.text-webkit-center {
  text-align: -webkit-center;
}

.border-card-zero {
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 0px !important;
}

.table-wrap {
  height: 300px;
  overflow: auto;
}

.border-card-radius {
  box-shadow: none !important;
  border: 1px solid #393d45 !important;
}

.row-service {
  border: 1px solid #b8daff !important;
}

.result-card {
  width: 250px;
  text-align: center;
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
.button-result-card {
  margin-right: 0px !important;
  text-align: center !important;
  font-size: 20px !important;
  border-radius: 30px !important;
}
.style-patente {
  width: 100%;
  font-size: 16px !important;
  font-weight: bolder !important;
  background-color: black !important;
}
.style-badge-estado {
  display: inline !important;
}
.style-patente-NuevaOt {
  font-size: 16px !important;
  font-weight: bolder !important;
  background-color: black !important;
}
.button-action-ot {
  text-align: center !important;
  font-weight: bolder !important;
  font-size: 15px !important;
  margin-right: 0 !important;
  width: 100% !important;
}
.style-tr-ot {
  font-size: 14px;
}
.style-badge-ot {
  width: 100% !important;
  font-weight: bolder !important;
  font-size: 16px !important;
  /* display: inline-flex !important */
}
.input-vehiculo {
  height: 35px !important;
}
.style-estado {
  font-weight: bolder !important;
}
.text-select {
  font-size: 17px !important;
  color: gray !important;
  margin-top: 0px !important;
  vertical-align: center !important;
}

.centrar-texto {
  text-align: center !important;
  float: center !important;
  vertical-align: center !important;
}
.debito {
  padding: 7px;
  border-radius: 100% !important;
  cursor: pointer;
  box-shadow: 2px 2px 10px #666 !important;
  width: 120px;
  height: 100px;
}
.credito {
  padding: 10px;
  border-radius: 100% !important;
  cursor: pointer;
  box-shadow: 2px 2px 10px #666 !important;
  width: 120px;
  height: 100px;
}
.mensaje-error {
  margin-left: 10px;
}
.label-cantRecibida {
  margin-top: 5px;
  font-weight: bold;
  margin-left: 5px;
}
.cantRecibida {
  font-weight: bold;
  width: 220px !important;
  /* display: inline !important; */
  margin-left: 10px;
  margin-right: 13px;
}
.badgeCostototal {
  width: 100%;
  height: 100%;
  font-size: 30px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.manualDigital {
  padding: 20px;
  width: 120px;
  height: 100px;
  border-radius: 100% !important;
  cursor: pointer;
  box-shadow: 2px 2px 10px #666 !important;
}
.mercadoPago {
  width: 120px;
  height: 100px;
  border-radius: 100% !important;
  cursor: pointer;
  box-shadow: 2px 2px 10px #666 !important;
}
.button-result-card-icon {
  margin-right: 0px !important;
  width: 100px !important;
  height: 35px;
  text-align: center !important;
  /* font-size: 20px !important; */
  border-radius: 30px !important;
}
.btn-rigth {
  text-align: right !important;
  float: right !important;
}
.button-result {
  /* font-size: 20px !important; */
  display: flex !important;
  border-radius: 30px !important;
  justify-content: center !important;
  width: 120px !important;
  text-align: center !important;
}

.tittle-card {
  height: 50px;
  margin-top: 7px;
  text-align: center !important;
  font-weight: bold !important;
}

.modalBodyVentaCorrecta {
  background-color: green !important;
  color: white;
  font-size: bold;
  border-radius: 10px;
  padding: 10px !important;
}
.modalLoadingPago {
  /* background-color: red !important; */
  color: white;
  width: 400px;
  height: 50px;
  font-size: bold;
  border-radius: 10px;
  padding: 10px !important;
}
.modalBodyVentaIncorrecta {
  background-color: red !important;
  color: white;
  font-size: bold;
  border-radius: 10px;
  padding: 10px !important;
}
.modalVentaCorrecta {
  margin-top: 300px !important;
}
.no-bottom-padding {
  padding-bottom: 0;
}

.style1 {
  margin: 1px !important;
  margin-bottom: 7px !important;
}
.modal-content {
  /* width: 550px !important; */
  float: center;
  margin-left: 83px;
}

.title {
  margin-left: 3px !important;
  margin-top: 5px;
  margin-bottom: 0px;
  color: black;
  text-align: left !important;
  font-weight: bold !important;
}
.title-service {
  margin-top: 7px;
  margin-bottom: 0px;
  background-color: #393d45;
  color: white;
  font-weight: bold !important;
  margin-left: 0px !important;
}

/* .card-services{
    height: calc(120vh - 300px) !important
  } */

.breadcrumb {
  margin-bottom: 0px !important;
}

.br-divider {
  border-top: 1px solid #8c8b8b !important;
  margin: 1px !important;
  margin-top: 3px !important;
  margin-bottom: 7px !important;
  height: 1px;
}

.list-group {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.btn_ {
  float: right !important;
  /* text-align: end !important; */
  margin-right: 60px !important;
  margin-bottom: -10 !important;
}

.labelCliente {
  float: center !important;
  text-align: center;
  font-size: 20px;
  justify-content: center;
}

.label_card_vehicle {
  color: black !important;
  font-size: 15px;
  border: 5px;
}

.label-vehicle {
  margin-left: 11px;
  margin-top: 10px;
  float: left !important;
  color: black !important;
  font-size: 15px;
}

.card-result {
  margin-top: 10px;
  color: black !important;
  margin-bottom: 10px;
}

.btn-dropdown {
  border-top-right-radius: 0px !important;
  margin-right: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.dropdown {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-right: 0px !important;
}

.inputRepuesto {
  float: right !important;
}

/* icons */

.icon_style_edit {
  color: blue !important;
  display: inline !important;
}

.icon_style_selec {
  color: green !important;
  display: inline !important;
}

.icon_style_add {
  font-size: "80px" !important;
  font-size-adjust: "80px" !important;
  font-weight: bold;
}

.icon_style_remove {
  color: red !important;
  display: inline !important;
}

.icon_style_check {
  color: rgb(0, 167, 0) !important;
  display: inline !important;
}

.icon_style_details {
  color: grey !important;
  display: inline !important;
}

.icon_style_reply {
  color: #007bff;
  font-size: "100px" !important;
  float: left !important;
  margin: 8px !important;
}

.td_icon {
  inline-size: 0px !important;
}

.card-consulta-ot {
  height: calc(100vh - 430px);
}

.body-consulta-ot {
  overflow-y: auto;
}

.table-tr-color {
  background-color: #17c671 !important;
  color: white !important;
  border-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.table-radius-top {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.table-option {
  font-size: 24px !important;
  cursor: pointer;
}

.back-option {
  font-size: 36px !important;
  cursor: pointer;
}
.thead-color-card-header {
  background: #393d45 !important;
  border-color: #393d45 !important;
  color: white;
}

.btn-nuevaOT {
  width: 100px !important;
  margin: 0px !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
  margin-top: 0px !important;
  float: right !important;
}
.btn-agregar-servicio {
  float: right !important;
}
.page-header {
  /* style={{ margin: "5px !important", padding:"10px !important" }} */
  padding-bottom: 0.3rem !important;
  padding-top: 0.1rem !important;
  margin-left: 10px !important;
}

.pb-4,
.py-4 {
  padding-bottom: 0.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 0.5rem !important;
}

.btn-collapse {
  margin-left: 15px;
  margin-top: 0px;
  color: #393d45 !important;
}
.cobro-cliente {
  font-size: 200px !important;
  text-align: center !important;
}
.text-collapse {
  margin-left: 10px;
  display: flex !important;
  margin-bottom: 5px !important;
  display: inline-block;
  /* color:#17c671; */
  text-align: left !important;
}

.btn-circle {
  border-radius: 100% !important;
  /* box-shadow: 2px 2px 10px #666 !important; */
  cursor: pointer !important;
  border: solid !important;
  border-color: #8c8b8b !important;
}
/* .p:hover {
    background: red !important;
  } */
.subrayado {
  text-decoration: none !important;
}
.subrayado-link {
  text-decoration: none !important;
}
.badge-collapse {
  /* margin-left: 10px !important; */
  margin-bottom: 10px !important;
  background-color: #dfe1df !important;
  border: 1px solid #c8ccc8 !important;
  display: flex !important;
  cursor: pointer;
}
.btn-agregar-actividad {
  margin-bottom: 10px !important;
  /* background-color:#fdfffd  !important; */
  /* border: 1px solid #c8ccc8!important; */
  display: flex !important;
  cursor: pointer;
}

.modal-detalle-body {
  background-color: #dfe1df !important;
}

.card-margen-cliente {
  margin-right: 0px !important;
  padding-right: 0px !important;
}

.card-margen-vehiculo {
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.no-select {
  -webkit-user-select: none;
  user-select: none;
}

.has-nota-credito {
  min-width: 98%;
}

#ventaDetalles {
  max-height: 100vh;
  overflow: scroll;
}

.cancelar {
  color: #ffff;
  font-size: 12px;
}
.aceptar {
  font-size: 12px;
  color: #ffff;
}
.modal-confirm-body {
  justify-content: center;
  padding-bottom: 20px;
}

.cancelar {
  color: #ffff;
  font-size: 12px;
}
.aceptar {
  font-size: 12px;
  color: #ffff;
}
.modal-confirm-body {
  justify-content: center;
  padding-bottom: 20px;
}

.cancelar {
  color: #ffff;
  font-size: 12px;
}
.aceptar {
  font-size: 12px;
  color: #ffff;
}
.modal-confirm-body {
  justify-content: center;
  padding-bottom: 20px;
}

.card-gray {
  border: 1px solid lightgrey !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  background-color: whitesmoke !important;
}
.divder-card-gray {
  border-bottom: 1px solid lightgray !important;
}

div ul {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.divClient {
  /* height: calc(100vh - 299px); */
  height: calc(100vh - 315px);
  border-left: 1px solid lightgray;
  border-right: 1px solid #d3d3d3;
  overflow: auto;
}

.rowCaja {
  height: calc(100vh - 320px);
}

.rowTablaCaja {
  height: calc(100vh - 450px);
}

.tablaCaja {
  max-height: calc(100vh - 450px);
}

.divClient3 {
  height: calc(100vh - 350px);
}

.tablaDetalleVenta {
  height: calc(100vh - 380px);
  /* border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray; */
  overflow: auto;
}

.colum {
  padding: 10px;
}

.ml-sm-auto {
  padding-top: 0px;
  padding-bottom: 0px;
  padding: 0px;
}

.page-header.py-4.no-gutters.row {
  padding-bottom: 10px !important;
  padding-top: 7px !important;
}

.botonCaja {
  height: 46px;
  width: 105px;
}

.colBoton {
  padding-right: 10px !important;
  padding-left: 5px !important;
  padding-top: 15px !important;
}

.rowBtn {
  padding-top: 12px;
}

.inputCash {
  padding-bottom: 8px;
}

.inputCashArqueo {
  border-radius: 10px !important;
  font-size: 20px !important;
}

.ventasDiv {
  height: 350px;
}

.labelCajaNombre {
  font-size: 18px !important;
  color: black;
}

.labelCaja {
  font-size: 18px !important;
  border: 1px solid gray;
  border-radius: 3px;
  width: 130px;
  text-align: center;
  color: black;
}

.labelCajaMenor-0 {
  font-size: 18px !important;
  background-color: rgba(211, 64, 28, 0.788);
  border: 1px solid gray;
  border-radius: 3px;
  width: 130px;
  text-align: center;
  color: white;
}

.caja-entrada {
  color: darkgreen;
  font-weight: bold;
  text-align: center;
}

.caja-salida {
  color: darkred;
  font-weight: bold;
  text-align: center;
}

.tableSelected {
  border: 2px solid rgba(93, 136, 216, 0.37) !important;
  background-color: rgba(54, 54, 77, 0.137) !important;
  margin-top: 1px;
  margin-bottom: 1px;
}

.noSelect {
  font-weight: bold;
  font-size: 15px;
}

.headerToggle {
  padding: 0px !important;
  padding-left: 15px !important;
  padding-top: 7px !important;
}

/* ------TABLA DE MOSTRAR VENTAS----- */
.mostrarVentas {
  height: calc(100vh - 330px);
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  overflow: overlay;
}

/* --------------------- */

/* ---------TAMAÑO Y DE TEXTO------ */
.FS14 {
  font-size: 14px;
}

/* ------------------------------------ */

/* --------------BOTONES DE CAJA------------ */
.FSBTNCAJA {
  font-size: 0.875rem;
}

/* ----------------------------------- */

/* ------------LABEL DE ARQUEO---------- */
.colArq {
  border-radius: 10px !important;
  background-color: rgb(238, 238, 238) !important;
  border: 1px solid lightgrey !important;
}

.lalbelArq {
  margin-top: 4px;
  margin-bottom: 4px;
}

:root {
  --blue: #007bff;
  --indigo: #674eec;
  --purple: #8445f7;
  --pink: #ff4169;
  --red: #c4183c;
  --orange: #fb7906;
  --yellow: #ffb400;
  --green: #17c671;
  --teal: #1adba2;
  --cyan: #00b8d8;
  --white: #fff;
  --gray: #868e96;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #5a6169;
  --success: #17c671;
  --info: #00b8d8;
  --warning: #ffb400;
  --danger: #c4183c;
  --light: #fbfbfb;
  --dark: #212529;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  --font-family-monospace: "Roboto Mono", Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

@media (max-width: 575.98px) {
  html {
    font-size: 15px;
  }
}

body {
  font-size: 1rem;
  font-weight: 300;
  color: #5a6169;
  background-color: #fff;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

b,
strong {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  display: block;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.75rem;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-weight: 400;
  color: #3d5170;
}

.h1,
h1 {
  font-size: 3.052rem;
  line-height: 3rem;
}

.h1,
.h2,
h1,
h2 {
  letter-spacing: -0.0625rem;
}

.h2,
h2 {
  font-size: 2.441rem;
}

.h2,
.h3,
h2,
h3 {
  line-height: 2.25rem;
}

.h3,
h3 {
  font-size: 1.953rem;
}

.h4,
h4 {
  font-size: 1.563rem;
  line-height: 2rem;
}

.h5,
h5 {
  font-size: 1.25rem;
}

.h5,
.h6,
h5,
h6 {
  line-height: 1.5rem;
}

.h6,
h6 {
  font-size: 1rem;
}

.lead {
  line-height: 1.875rem;
}

.display-1,
.display-2,
.display-3,
.display-4 {
  margin-bottom: 0.75rem;
}

.display-1 {
  font-size: 7.451rem;
  line-height: 1;
}

.display-2 {
  font-size: 5.96rem;
  line-height: 1;
}

.display-3 {
  font-size: 4.768rem;
  line-height: 1;
}

.display-4 {
  font-size: 3.815rem;
  line-height: 1;
}

p {
  margin-bottom: 1.75rem;
}

hr {
  margin-top: 1.125rem;
  margin-bottom: 1.125rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.small,
small {
  font-size: 80%;
  font-weight: 300;
}

.mark,
mark {
  padding: 0.2em;
  background-color: #fff09e;
}

.blockquote {
  margin-bottom: 0.75rem;
  font-size: 1.5rem;
}

.blockquote-footer {
  font-size: 1.125rem;
}

.img-thumbnail {
  padding: 0;
  border: none;
  background-color: #fff;
  border-radius: 0.375rem;
  box-shadow: none;
}

.figure-img {
  margin-bottom: 0.75rem;
}

.figure-caption {
  font-size: 1rem;
  color: #868e96;
}

code,
kbd,
pre,
samp {
  font-family:
    Roboto Mono,
    Menlo,
    Monaco,
    Consolas,
    Liberation Mono,
    Courier New,
    monospace;
}

code,
kbd {
  font-size: 0.75rem;
  padding: 0.1875rem 0.8125rem;
}

kbd {
  color: #fff;
  background-color: #212529;
  border-radius: 0.625rem;
  box-shadow: none;
}

kbd kbd {
  font-weight: 500;
}

pre {
  margin-bottom: 0.75rem;
  font-size: 0.75rem;
  color: #212529;
  line-height: 1.375rem;
}

.pre-scrollable {
  max-height: 340px;
}

.table {
  background-color: initial;
}

.table td,
.table th {
  padding: 0.75rem;
}

.table .table {
  background-color: #fff;
}

.table-sm td,
.table-sm th {
  padding: 0.3rem;
}

.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #b8daff;
}

.table-hover .table-primary:hover,
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #d1d3d5;
}

.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c4c6c9;
}

.table-success,
.table-success > td,
.table-success > th {
  background-color: #beefd7;
}

.table-hover .table-success:hover,
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #aaeaca;
}

.table-info,
.table-info > td,
.table-info > th {
  background-color: #b8ebf4;
}

.table-hover .table-info:hover,
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a2e5f1;
}

.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #ffeab8;
}

.table-hover .table-warning:hover,
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe29f;
}

.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #eebec8;
}

.table-hover .table-danger:hover,
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #e9aab7;
}

.table-light,
.table-light > td,
.table-light > th {
  background-color: #fefefe;
}

.table-hover .table-light:hover,
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f1f1f1;
}

.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #c1c2c3;
}

.table-hover .table-dark:hover,
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b4b5b6;
}

.table-active,
.table-active > td,
.table-active > th,
.table-hover .table-active:hover,
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: hsla(0, 0%, 100%, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: hsla(0, 0%, 100%, 0.075);
}

.form-control {
  height: auto;
  padding: 0.4375rem 0.75rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #e1e5eb;
  font-weight: 300;
  will-change: border-color, box-shadow;
  border-radius: 0.25rem;
  box-shadow: none;
  transition:
    box-shadow 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.form-control:hover {
  border-color: #b3bdcc;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  box-shadow:
    0 0.313rem 0.719rem rgba(0, 123, 255, 0.1),
    0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

.form-control:focus,
.form-control:focus:hover {
  border-color: #007bff;
}

.form-control::placeholder {
  color: #868e96;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f5f6f7;
}

.form-control:disabled:hover,
.form-control[readonly]:hover {
  border-color: #e1e5eb;
  cursor: not-allowed;
}

.form-control[readonly]:not(:disabled):focus {
  box-shadow: none;
  border-color: #e1e5eb;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.09375rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

form label:hover,
select.form-control:hover {
  cursor: pointer;
}

.col-form-label {
  padding-top: calc(0.4375rem + 1px);
  padding-bottom: calc(0.4375rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.8125rem + 1px);
  padding-bottom: calc(0.8125rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.4286rem + 1px);
  padding-bottom: calc(0.4286rem + 1px);
  font-size: 0.6875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  line-height: 1.5;
  font-weight: 300;
}

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-middle > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  padding: 0.4286rem 0.625rem;
  font-size: 0.6875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-sm
  > .input-group-append
  > select.input-group-text:not([size]):not([multiple]),
.input-group-sm
  > .input-group-middle
  > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm
  > .input-group-prepend
  > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > select.form-control:not([size]):not([multiple]),
select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.88845rem + 2px);
}

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-middle > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text {
  padding: 0.8125rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.375rem;
}

.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-lg
  > .input-group-append
  > select.input-group-text:not([size]):not([multiple]),
.input-group-lg
  > .input-group-middle
  > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg
  > .input-group-prepend
  > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.9375rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  margin-top: 0.25rem;
}

.form-check {
  padding-left: 1.25rem;
}

.form-check-input {
  margin-top: 0.313rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #868e96;
}

.form-check-inline {
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  margin-right: 0.3125rem;
}

.valid-feedback {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #17c671;
}

.valid-tooltip {
  background-color: rgba(23, 198, 113, 0.8);
}

.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  border-color: #17c671;
  box-shadow: 0 5px 11.5px rgba(23, 198, 113, 0.1);
}

.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
  box-shadow:
    0 5px 11.5px rgba(23, 198, 113, 0.1),
    0 1px 1px 0.1rem rgba(23, 198, 113, 0.2);
}

.custom-select.is-valid:hover,
.form-control.is-valid:hover,
.was-validated .custom-select:valid:hover,
.was-validated .form-control:valid:hover {
  border-color: #17c671;
}

.custom-control-input.is-valid ~ .custom-control-label,
.form-check-input.is-valid ~ .form-check-label,
.was-validated .custom-control-input:valid ~ .custom-control-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #17c671;
}

.custom-control-input.is-valid ~ .custom-control-label:before,
.was-validated .custom-control-input:valid ~ .custom-control-label:before {
  background-color: #57eca4;
  border-color: #2ae68b;
}

.custom-control-input.is-valid:checked ~ .custom-control-label:before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label:before {
  background-color: #2ae68b;
}

.custom-control-input.is-valid:focus ~ .custom-control-label:before,
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label:before {
  box-shadow:
    0 0.313rem 0.719rem rgba(23, 198, 113, 0.1),
    0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  color: #17c671;
  border-color: #17c671;
}

.custom-file-input.is-valid ~ .custom-file-label:after,
.was-validated .custom-file-input:valid ~ .custom-file-label:after {
  background-color: #b3f6d5;
  border-color: #2ae68b;
  color: #17c671;
}

.custom-file-input:focus.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:focus:valid ~ .custom-file-label {
  border-color: #17c671;
  box-shadow:
    0 5px 11.5px rgba(23, 198, 113, 0.1),
    0 1px 1px 0.1rem rgba(23, 198, 113, 0.2);
}

.custom-file-input:hover.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:hover:valid ~ .custom-file-label {
  border-color: #17c671;
}

.custom-toggle
  .custom-control-input:not(:checked).is-valid
  ~ .custom-control-label:before,
.was-validated
  .custom-toggle
  .custom-control-input:not(:checked):valid
  ~ .custom-control-label:before {
  background-color: #fff;
}

.custom-toggle
  .custom-control-input.is-invalid:focus
  ~ .custom-control-label:before,
.was-validated
  .custom-toggle
  .custom-control-input:invalid:focus
  ~ .custom-control-label:before {
  box-shadow:
    0 0.313rem 0.719rem rgba(23, 198, 113, 0.1),
    0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

.invalid-feedback {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #c4183c;
}

.invalid-tooltip {
  background-color: rgba(196, 24, 60, 0.8);
}

.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
  border-color: #c4183c;
  box-shadow: 0 5px 11.5px rgba(196, 24, 60, 0.1);
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow:
    0 5px 11.5px rgba(196, 24, 60, 0.1),
    0 1px 1px 0.1rem rgba(196, 24, 60, 0.2);
}

.custom-select.is-invalid:hover,
.form-control.is-invalid:hover,
.was-validated .custom-select:invalid:hover,
.was-validated .form-control:invalid:hover {
  border-color: #c4183c;
}

.custom-control-input.is-invalid ~ .custom-control-label,
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #c4183c;
}

.custom-control-input.is-invalid ~ .custom-control-label:before,
.was-validated .custom-control-input:invalid ~ .custom-control-label:before {
  background-color: #ea5876;
  border-color: #e52a51;
}

.custom-control-input.is-invalid:checked ~ .custom-control-label:before,
.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label:before {
  background-color: #e52a51;
}

.custom-control-input.is-invalid:focus ~ .custom-control-label:before,
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label:before {
  box-shadow:
    0 0.313rem 0.719rem rgba(196, 24, 60, 0.1),
    0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  color: #c4183c;
  border-color: #c4183c;
}

.custom-file-input.is-invalid ~ .custom-file-label:after,
.was-validated .custom-file-input:invalid ~ .custom-file-label:after {
  background-color: #f6b2c0;
  border-color: #e52a51;
  color: #c4183c;
}

.custom-file-input:focus.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:focus:invalid ~ .custom-file-label {
  border-color: #c4183c;
  box-shadow:
    0 5px 11.5px rgba(196, 24, 60, 0.1),
    0 1px 1px 0.1rem rgba(196, 24, 60, 0.2);
}

.custom-file-input:hover.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:hover:invalid ~ .custom-file-label {
  border-color: #c4183c;
}

.custom-toggle
  .custom-control-input:not(:checked).is-invalid
  ~ .custom-control-label:before,
.was-validated
  .custom-toggle
  .custom-control-input:not(:checked):invalid
  ~ .custom-control-label:before {
  background-color: #fff;
}

.custom-toggle .custom-control-input.is-valid ~ .custom-control-label:before,
.was-validated
  .custom-toggle
  .custom-control-input:valid
  ~ .custom-control-label:before {
  background-color: #17c671;
}

.custom-toggle .custom-control-input.is-invalid ~ .custom-control-label:after,
.was-validated
  .custom-toggle
  .custom-control-input:invalid
  ~ .custom-control-label:after {
  background-color: #eb8c95;
}

.custom-toggle
  .custom-control-input.is-invalid:focus
  ~ .custom-control-label:before,
.was-validated
  .custom-toggle
  .custom-control-input:invalid:focus
  ~ .custom-control-label:before {
  box-shadow:
    0 0.313rem 0.719rem rgba(196, 24, 60, 0.1),
    0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

@media (min-width: 576px) {
  .form-inline .form-check-input {
    margin-right: 0.313rem;
  }
}

.btn {
  font-weight: 400;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    sans-serif;
  border: 1px solid transparent;
  padding: 0.5625rem 1rem;
  font-size: 0.75rem;
  line-height: 1.125;
  border-radius: 0.25rem;
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.btn.hover,
.btn:hover {
  cursor: pointer;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.btn:not([disabled]):not(.disabled).active,
.btn:not([disabled]):not(.disabled):active {
  background-image: none;
  box-shadow: none;
}

.btn.btn-squared {
  border-radius: 0;
}

.btn.btn-pill {
  border-radius: 50px;
}

.btn-primary {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
  box-shadow: none;
}

.btn-primary:hover {
  color: #fff;
  background-color: #006fe6;
  border-color: #006fe6;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(0, 123, 255, 0.25);
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow:
    0 0 0 3px rgba(0, 123, 255, 0.15),
    0 3px 15px rgba(0, 123, 255, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #007bff;
  border-color: #007bff;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #006fe6;
  border-color: #0062cc;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-secondary {
  color: #fff;
  border-color: #5a6169;
  background-color: #5a6169;
  box-shadow: none;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #4e545b;
  border-color: #4e545b;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(90, 97, 105, 0.25);
}

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow:
    0 0 0 3px rgba(90, 97, 105, 0.15),
    0 3px 15px rgba(90, 97, 105, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #5a6169;
  border-color: #5a6169;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #4e545b;
  border-color: #42484e;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-success {
  color: #fff;
  border-color: #17c671;
  background-color: #17c671;
  box-shadow: none;
}

.btn-success:hover {
  color: #fff;
  background-color: #14af64;
  border-color: #14af64;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(23, 198, 113, 0.25);
}

.btn-success.focus,
.btn-success:focus {
  box-shadow:
    0 0 0 3px rgba(23, 198, 113, 0.15),
    0 3px 15px rgba(23, 198, 113, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #17c671;
  border-color: #17c671;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #14af64;
  border-color: #129857;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-info {
  color: #fff;
  border-color: #00b8d8;
  background-color: #00b8d8;
  box-shadow: none;
}

.btn-info:hover {
  color: #fff;
  background-color: #00a2bf;
  border-color: #00a2bf;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(0, 184, 216, 0.25);
}

.btn-info.focus,
.btn-info:focus {
  box-shadow:
    0 0 0 3px rgba(0, 184, 216, 0.15),
    0 3px 15px rgba(0, 184, 216, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: #00b8d8;
  border-color: #00b8d8;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #00a2bf;
  border-color: #008da5;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-warning {
  color: #212529;
  border-color: #ffb400;
  background-color: #ffb400;
  box-shadow: none;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e6a200;
  border-color: #e6a200;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(255, 180, 0, 0.25);
}

.btn-warning.focus,
.btn-warning:focus {
  box-shadow:
    0 0 0 3px rgba(255, 180, 0, 0.15),
    0 3px 15px rgba(255, 180, 0, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #ffb400;
  border-color: #ffb400;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #e6a200;
  border-color: #cc9000;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-danger {
  color: #fff;
  border-color: #c4183c;
  background-color: #c4183c;
  box-shadow: none;
}

.btn-danger:hover {
  color: #fff;
  background-color: #ad1535;
  border-color: #ad1535;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(196, 24, 60, 0.25);
}

.btn-danger.focus,
.btn-danger:focus {
  box-shadow:
    0 0 0 3px rgba(196, 24, 60, 0.15),
    0 3px 15px rgba(196, 24, 60, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #c4183c;
  border-color: #c4183c;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ad1535;
  border-color: #97122e;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-light {
  color: #212529;
  border-color: #fbfbfb;
  background-color: #fbfbfb;
  box-shadow: none;
}

.btn-light:hover {
  color: #212529;
  background-color: #eee;
  border-color: #eee;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px hsla(0, 0%, 98.4%, 0.25);
}

.btn-light.focus,
.btn-light:focus {
  box-shadow:
    0 0 0 3px hsla(0, 0%, 98.4%, 0.15),
    0 3px 15px hsla(0, 0%, 98.4%, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-light.disabled,
.btn-light:disabled {
  background-color: #fbfbfb;
  border-color: #fbfbfb;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #eee;
  border-color: #e2e2e2;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-dark {
  color: #fff;
  border-color: #212529;
  background-color: #212529;
  box-shadow: none;
}

.btn-dark:hover {
  color: #fff;
  background-color: #16181b;
  border-color: #16181b;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(33, 37, 41, 0.25);
}

.btn-dark.focus,
.btn-dark:focus {
  box-shadow:
    0 0 0 3px rgba(33, 37, 41, 0.15),
    0 3px 15px rgba(33, 37, 41, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-dark.disabled,
.btn-dark:disabled {
  background-color: #212529;
  border-color: #212529;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #16181b;
  border-color: #0a0c0d;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-white {
  color: #212529;
  border-color: #fff;
  background-color: #fff;
  box-shadow: none;
}

.btn-white:hover {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px hsla(0, 0%, 100%, 0.25);
}

.btn-white.focus,
.btn-white:focus {
  box-shadow:
    0 0 0 3px hsla(0, 0%, 100%, 0.15),
    0 3px 15px hsla(0, 0%, 100%, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-white.disabled,
.btn-white:disabled {
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-white:not(:disabled):not(.disabled).active,
.btn-white:not(:disabled):not(.disabled):active,
.show > .btn-white.dropdown-toggle {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #e6e6e6;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-black {
  box-shadow: none;
}

.btn-black,
.btn-black:hover {
  color: #fff;
  border-color: #000;
  background-color: #000;
}

.btn-black:hover {
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(0, 0, 0, 0.25);
}

.btn-black.focus,
.btn-black:focus {
  box-shadow:
    0 0 0 3px rgba(0, 0, 0, 0.15),
    0 3px 15px rgba(0, 0, 0, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-black.disabled,
.btn-black:disabled {
  background-color: #000;
  border-color: #000;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-black:not(:disabled):not(.disabled).active,
.btn-black:not(:disabled):not(.disabled):active,
.show > .btn-black.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-primary {
  background-color: initial;
  background-image: none;
  border-color: #007bff;
  color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(0, 123, 255, 0.25);
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow:
    0 0 0 3px rgba(0, 123, 255, 0.15),
    0 3px 15px rgba(0, 123, 255, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: initial;
  box-shadow: none;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-secondary {
  background-color: initial;
  background-image: none;
  border-color: #5a6169;
  color: #5a6169;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #5a6169;
  border-color: #5a6169;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(90, 97, 105, 0.25);
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow:
    0 0 0 3px rgba(90, 97, 105, 0.15),
    0 3px 15px rgba(90, 97, 105, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #5a6169;
  background-color: initial;
  box-shadow: none;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5a6169;
  border-color: #5a6169;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-success {
  background-color: initial;
  background-image: none;
  border-color: #17c671;
  color: #17c671;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #17c671;
  border-color: #17c671;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(23, 198, 113, 0.25);
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow:
    0 0 0 3px rgba(23, 198, 113, 0.15),
    0 3px 15px rgba(23, 198, 113, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #17c671;
  background-color: initial;
  box-shadow: none;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #17c671;
  border-color: #17c671;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-info {
  background-color: initial;
  background-image: none;
  border-color: #00b8d8;
  color: #00b8d8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #00b8d8;
  border-color: #00b8d8;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(0, 184, 216, 0.25);
}

.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow:
    0 0 0 3px rgba(0, 184, 216, 0.15),
    0 3px 15px rgba(0, 184, 216, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #00b8d8;
  background-color: initial;
  box-shadow: none;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #00b8d8;
  border-color: #00b8d8;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-warning {
  background-color: initial;
  background-image: none;
  border-color: #ffb400;
  color: #ffb400;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffb400;
  border-color: #ffb400;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(255, 180, 0, 0.25);
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow:
    0 0 0 3px rgba(255, 180, 0, 0.15),
    0 3px 15px rgba(255, 180, 0, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffb400;
  background-color: initial;
  box-shadow: none;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffb400;
  border-color: #ffb400;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-danger {
  background-color: initial;
  background-image: none;
  border-color: #c4183c;
  color: #c4183c;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #c4183c;
  border-color: #c4183c;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(196, 24, 60, 0.25);
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow:
    0 0 0 3px rgba(196, 24, 60, 0.15),
    0 3px 15px rgba(196, 24, 60, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #c4183c;
  background-color: initial;
  box-shadow: none;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #c4183c;
  border-color: #c4183c;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-light {
  background-color: initial;
  background-image: none;
  border-color: #fbfbfb;
  color: #212529;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #fbfbfb;
  border-color: #fbfbfb;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px hsla(0, 0%, 98.4%, 0.25);
}

.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow:
    0 0 0 3px hsla(0, 0%, 98.4%, 0.15),
    0 3px 15px hsla(0, 0%, 98.4%, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #fbfbfb;
  background-color: initial;
  box-shadow: none;
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #fbfbfb;
  border-color: #fbfbfb;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-dark {
  background-color: initial;
  background-image: none;
  border-color: #212529;
  color: #212529;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(33, 37, 41, 0.25);
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow:
    0 0 0 3px rgba(33, 37, 41, 0.15),
    0 3px 15px rgba(33, 37, 41, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #212529;
  background-color: initial;
  box-shadow: none;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-white {
  background-color: initial;
  background-image: none;
  border-color: #fff;
  color: #212529;
  color: #fff;
}

.btn-outline-white:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px hsla(0, 0%, 100%, 0.25);
}

.btn-outline-white.focus,
.btn-outline-white:focus {
  box-shadow:
    0 0 0 3px hsla(0, 0%, 100%, 0.15),
    0 3px 15px hsla(0, 0%, 100%, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-white.disabled,
.btn-outline-white:disabled {
  color: #fff;
  background-color: initial;
  box-shadow: none;
}

.btn-outline-white:not(:disabled):not(.disabled).active,
.btn-outline-white:not(:disabled):not(.disabled):active,
.show > .btn-outline-white.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:not(:disabled):not(.disabled).active:focus,
.btn-outline-white:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-white:not(:disabled):not(.disabled).active,
.btn-outline-white:not(:disabled):not(.disabled):active {
  color: #000;
}

.btn-outline-black {
  background-color: initial;
  background-image: none;
  border-color: #000;
  color: #000;
}

.btn-outline-black:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(0, 0, 0, 0.25);
}

.btn-outline-black.focus,
.btn-outline-black:focus {
  box-shadow:
    0 0 0 3px rgba(0, 0, 0, 0.15),
    0 3px 15px rgba(0, 0, 0, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-black.disabled,
.btn-outline-black:disabled {
  color: #000;
  background-color: initial;
  box-shadow: none;
}

.btn-outline-black:not(:disabled):not(.disabled).active,
.btn-outline-black:not(:disabled):not(.disabled):active,
.show > .btn-outline-black.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-black:not(:disabled):not(.disabled).active:focus,
.btn-outline-black:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-black.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-black:not(:disabled):not(.disabled).active,
.btn-outline-black:not(:disabled):not(.disabled):active {
  color: #fff;
}

.btn-link {
  font-weight: 300;
  color: #007bff;
}

.btn-link:hover {
  color: #0056b3;
}

.btn-link.focus,
.btn-link:focus,
.btn-link:hover {
  text-decoration: underline;
}

.btn-link:disabled {
  color: #868e96;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.75rem 1.75rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.4286rem 0.875rem;
  font-size: 0.6875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

.fade {
  transition: opacity 0.2s ease-in-out;
}

.collapsing {
  transition: height 0.35s ease-in-out;
}

i.material-icons {
  font-size: inherit;
  position: relative;
}

.dropdown-menu {
  z-index: 1000;
  min-width: 10rem;
  padding: 10px 0;
  margin: 0;
  font-size: 1rem;
  color: #5a6169;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  box-shadow:
    0 0.5rem 4rem rgba(0, 0, 0, 0.11),
    0 10px 20px rgba(0, 0, 0, 0.05),
    0 2px 3px rgba(0, 0, 0, 0.06);
}

.dropdown-menu-small {
  box-shadow:
    0 0.5rem 2rem rgba(0, 0, 0, 0.11),
    0 3px 10px rgba(0, 0, 0, 0.05),
    0 2px 3px rgba(0, 0, 0, 0.06);
  padding: 0.25rem 0;
  font-size: 0.813rem;
}

.dropdown-menu-small .dropdown-item {
  padding: 0.375rem 0.875rem;
  font-size: 0.813rem;
}

.dropdown-menu-small .dropdown-divider {
  margin: 0.25rem 0;
}

.dropup .dropdown-menu {
  margin-bottom: 0;
}

.dropright .dropdown-menu {
  margin-left: 0;
}

.dropleft .dropdown-menu {
  margin-right: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.75rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  padding: 0.5rem 1.25rem;
  font-weight: 300;
  color: #212529;
  font-size: 0.9375rem;
  transition:
    background-color 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06),
    color 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  background-color: #eceeef;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  background-color: #c3c7cc;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #868e96;
}

.dropdown-item.disabled:hover,
.dropdown-item:disabled:hover {
  background: none;
  cursor: not-allowed;
}

.dropdown-header {
  padding: 10px 1.25rem;
  font-size: 0.6875rem;
  color: #868e96;
}

.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group,
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.65625rem;
  padding-left: 0.65625rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control {
  margin-left: -1px;
}

.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label:after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label:after {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group.input-group-seamless > .form-control {
  border-radius: 0.25rem;
}

.input-group.input-group-seamless > .input-group-append,
.input-group.input-group-seamless > .input-group-prepend {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4;
}

.input-group.input-group-seamless > .input-group-append .input-group-text,
.input-group.input-group-seamless > .input-group-prepend .input-group-text {
  padding: 0.75rem 0.625rem;
  background: transparent;
  border: none;
}

.input-group.input-group-seamless > .input-group-append {
  right: 0;
}

.input-group.input-group-seamless > .input-group-middle {
  right: 0;
  left: 0;
}

.input-group.input-group-seamless > .input-group-prepend {
  left: 0;
}

.input-group.input-group-seamless > .custom-select:not(:last-child),
.input-group.input-group-seamless > .form-control:not(:last-child) {
  padding-right: 40px;
}

.input-group.input-group-seamless > .custom-select:not(:first-child),
.input-group.input-group-seamless > .form-control:not(:first-child) {
  padding-left: 40px;
}

.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #abb6bf;
  background-color: #fff;
  border: 1px solid #e1e5eb;
  border-radius: 0.25rem;
}

.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .input-group-middle > .btn,
.input-group > .input-group-middle > .input-group-text {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.input-group-middle {
  display: flex;
}

.custom-control {
  min-height: 1.5rem;
  padding-left: 1.688rem;
}

.custom-control:hover {
  cursor: pointer;
}

.custom-control .custom-control-label:before {
  pointer-events: all;
}

.custom-control-inline {
  margin-right: 1rem;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  border-color: transparent;
  background-color: #007bff;
  box-shadow: none;
}

.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow:
    0 0.313rem 0.719rem rgba(0, 123, 255, 0.1),
    0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

.custom-control-input:active ~ .custom-control-label:before {
  color: #fff;
  background-color: #b3d7ff;
  box-shadow: none;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #868e96;
}

.custom-control-input:disabled ~ .custom-control-label:hover {
  cursor: not-allowed;
}

.custom-control-input:disabled ~ .custom-control-label:before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: static;
}

.custom-control-label:hover {
  cursor: pointer;
}

.custom-control-label:before {
  top: 0.1875rem;
  left: 0;
  width: 1.125rem;
  height: 1.125rem;
  background-color: #fff;
  border: 1px solid #e1e5eb;
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
  box-shadow: none;
}

.custom-control-label:after {
  top: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label:before {
  border-radius: 2px;
}

.custom-checkbox .custom-control-label:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 7px;
  width: 5px;
  height: 11px;
  opacity: 0;
  transform: rotate(45deg) scale(0);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transition:
    transform 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
  transition-delay: 0.1s;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  background-image: none;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  opacity: 1;
  transform: rotate(45deg) scale(1);
  background-image: none;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label:before {
  border: none;
  background-color: #007bff;
  box-shadow: none;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label:after {
  content: "";
  position: absolute;
  transform: scale(1);
  background-image: none;
  background-color: #fff;
  border: none;
  width: 10px;
  height: 2px;
  top: 11px;
  left: 4px;
  opacity: 1;
  transition: none;
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label:before {
  background: #e9ecef;
  border-color: #e1e5eb;
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label:after {
  border-color: #e1e5eb;
}

.custom-radio .custom-control-label:before {
  border-radius: 50%;
}

.custom-radio .custom-control-label:after {
  content: "";
  border-radius: 50%;
  -webkit-transform: scale(0);
  background-image: none !important;
  position: absolute;
  background: #fff;
  width: 8px;
  height: 8px;
  top: 8px;
  left: 5px;
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
  transition-delay: 0.1s;
  opacity: 0;
  transform: scale(0);
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #007bff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  opacity: 1;
  transform: scale(1);
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label:before {
  background-color: #a8aeb4;
  background: #e9ecef;
  border-color: #e1e5eb;
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label:after {
  background: #e1e5eb;
}

.custom-select {
  height: calc(2.09375rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background: #fff
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #e1e5eb;
  font-weight: 300;
  font-size: 0.8125rem;
  transition:
    box-shadow 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
  border-radius: 0.25rem;
}

.custom-select:focus {
  border-color: #007bff;
  box-shadow:
    0 0.313rem 0.719rem rgba(0, 123, 255, 0.1),
    0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select:hover:not(:focus):not(:disabled) {
  cursor: pointer;
  border-color: #b3bdcc;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
}

.custom-select:disabled {
  color: #868e96;
  background-color: #e9ecef;
}

.custom-select-sm {
  height: calc(1.88845rem + 2px);
  font-size: 0.75rem;
}

.custom-select-lg,
.custom-select-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.custom-select-lg {
  height: calc(2.9375rem + 2px);
  font-size: 1.25rem;
}

.custom-file {
  height: calc(2.0625rem + 2px);
  font-size: 0.8125rem;
  transition:
    box-shadow 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.custom-file-input {
  min-width: 14rem;
  height: calc(2.0625rem + 2px);
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #007bff;
  color: #495057;
  box-shadow:
    0 0.313rem 0.719rem rgba(0, 123, 255, 0.1),
    0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

.custom-file-input:focus ~ .custom-file-label:after {
  border-color: #007bff;
  color: #007bff;
  background: #e6f2ff;
}

.custom-file-input:focus ~ .custom-file-label:hover {
  border-color: #007bff;
}

.custom-file-input:lang(en) ~ .custom-file-label:after {
  content: "Browse";
}

.custom-file-input:not(:disabled):hover {
  cursor: pointer;
}

.custom-file-input:not(:disabled):hover ~ .custom-file-label,
.custom-file-input:not(:disabled):hover ~ .custom-file-label:before {
  border-color: #b3bdcc;
}

.custom-file-input:disabled + .custom-file-label {
  color: #868e96;
  background-color: #f8f9fa;
}

.custom-file-label {
  height: calc(2.0625rem + 2px);
  background-color: #fff;
  border: 1px solid #e1e5eb;
  font-weight: 300;
  box-shadow: none;
  transition:
    box-shadow 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border-color 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
  border-radius: 0.25rem;
}

.custom-file-label,
.custom-file-label:after {
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #495057;
}

.custom-file-label:after {
  height: 2.0625rem;
  border-left: 1px solid #e1e5eb;
  background-color: #e9ecef;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-toggle {
  position: relative;
  padding-left: 3.75rem;
}

.custom-toggle .custom-control-label:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 3.125rem;
  height: 1.75rem;
  background: #fff;
  border-radius: 100px;
  border: 0.0625rem solid #e1e5eb;
}

.custom-toggle .custom-control-label:after {
  content: "";
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  background: #e1e5eb;
  border-radius: 6.25rem;
  transition: 0.35s;
}

.custom-toggle .custom-control-input:checked ~ .custom-control-label:before {
  background: #17c671;
  border-color: #17c671;
}

.custom-toggle .custom-control-input:checked ~ .custom-control-label:after {
  left: 2.875rem;
  transform: translateX(-100%);
  background: #fff;
}

.custom-toggle
  .custom-control-input:checked:disabled
  ~ .custom-control-label:before {
  background: #e9ecef;
  border-color: #e1e5eb;
}

.custom-toggle
  .custom-control-input:checked:disabled
  ~ .custom-control-label:after {
  background: #e1e5eb;
}

.custom-toggle
  .custom-control-input:active:not(:disabled)
  ~ .custom-control-label:after {
  width: 1.625rem;
}

.custom-toggle
  .custom-control-input:active:not(:checked)
  ~ .custom-control-label:before {
  background-color: #fff;
}

.custom-toggle
  .custom-control-input:disabled:active
  ~ .custom-control-label:before {
  background-color: #e9ecef;
}

.custom-toggle .custom-control-input:focus ~ .custom-control-label:before {
  box-shadow:
    0 0.313rem 0.719rem rgba(23, 198, 113, 0.1),
    0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

.custom-toggle
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label:before {
  box-shadow:
    0 0.313rem 0.719rem rgba(0, 123, 255, 0.1),
    0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

.custom-toggle.custom-toggle-sm {
  padding-left: 2.625rem;
}

.custom-toggle.custom-toggle-sm .custom-control-label:before {
  top: 0.1875rem;
  position: absolute;
  display: block;
  width: 2.1875rem;
  height: 1.125rem;
  background: #fff;
  border-radius: 100px;
  border: 0.0625rem solid #e1e5eb;
}

.custom-toggle.custom-toggle-sm .custom-control-label:after {
  content: "";
  position: absolute;
  top: 0.375rem;
  left: 0.1875rem;
  width: 0.75rem;
  height: 0.75rem;
}

.custom-toggle.custom-toggle-sm
  .custom-control-input:checked
  ~ .custom-control-label:after {
  left: 1.9375rem;
}

.custom-toggle.custom-toggle-sm
  .custom-control-input:active:not(:disabled)
  ~ .custom-control-label:after {
  width: 1rem;
}

.nav {
  font-size: 0.875rem;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    sans-serif;
}

.nav-link {
  padding: 0.625rem;
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.nav-link.disabled {
  color: #868e96;
}

.nav-tabs {
  border-bottom: 1px solid #d1d4d8;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef;
}

.nav-tabs .nav-link.disabled {
  color: #868e96;
}

.nav-tabs .nav-link.disabled:hover {
  cursor: not-allowed;
  border-color: transparent;
}

.nav-tabs .nav-link:hover {
  border-color: #e7e9ea;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #ddd;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.375rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-pills:hover {
  background-color: #fdfdfd;
}

.nav-outlined-pills .nav-link {
  border-radius: 0.375rem;
  border: 1px solid transparent;
}

.nav-outlined-pills .nav-link.active,
.show > .nav-outlined-pills .nav-link {
  background: none;
  color: #007bff;
  border-color: #007bff;
}

.nav-outlined-pills .nav-link:hover {
  border-color: #e7e9ea;
}

.nav-blue .nav-link.active {
  background-color: #007bff;
  border-color: #0074f0;
  color: #fff;
}

.nav-blue .nav-link.disabled {
  color: #868e96;
}

.nav-blue .nav-link.disabled:hover {
  cursor: not-allowed;
  border-color: transparent;
}

.nav-blue .nav-link {
  color: #007bff;
}

.nav-blue.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #3395ff;
  color: #007bff;
}

.nav-blue.nav-outlined-pills .nav-link.active:hover {
  border-color: #3395ff;
}

.nav-blue.nav-outlined-pills .nav-link {
  color: #007bff;
}

.nav-indigo .nav-link.active {
  background-color: #674eec;
  border-color: #5b40eb;
  color: #fff;
}

.nav-indigo .nav-link.disabled {
  color: #868e96;
}

.nav-indigo .nav-link.disabled:hover {
  cursor: not-allowed;
  border-color: transparent;
}

.nav-indigo .nav-link {
  color: #674eec;
}

.nav-indigo.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #8f7cf1;
  color: #674eec;
}

.nav-indigo.nav-outlined-pills .nav-link.active:hover {
  border-color: #8f7cf1;
}

.nav-indigo.nav-outlined-pills .nav-link {
  color: #674eec;
}

.nav-purple .nav-link.active {
  background-color: #8445f7;
  border-color: #7a36f6;
  color: #fff;
}

.nav-purple .nav-link.disabled {
  color: #868e96;
}

.nav-purple .nav-link.disabled:hover {
  cursor: not-allowed;
  border-color: transparent;
}

.nav-purple .nav-link {
  color: #8445f7;
}

.nav-purple.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #a476f9;
  color: #8445f7;
}

.nav-purple.nav-outlined-pills .nav-link.active:hover {
  border-color: #a476f9;
}

.nav-purple.nav-outlined-pills .nav-link {
  color: #8445f7;
}

.nav-pink .nav-link.active {
  background-color: #ff4169;
  border-color: #ff325d;
  color: #fff;
}

.nav-pink .nav-link.disabled {
  color: #868e96;
}

.nav-pink .nav-link.disabled:hover {
  cursor: not-allowed;
  border-color: transparent;
}

.nav-pink .nav-link {
  color: #ff4169;
}

.nav-pink.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #ff7491;
  color: #ff4169;
}

.nav-pink.nav-outlined-pills .nav-link.active:hover {
  border-color: #ff7491;
}

.nav-pink.nav-outlined-pills .nav-link {
  color: #ff4169;
}

.nav-red .nav-link.active {
  background-color: #c4183c;
  border-color: #b61638;
  color: #fff;
}

.nav-red .nav-link.disabled {
  color: #868e96;
}

.nav-red .nav-link.disabled:hover {
  cursor: not-allowed;
  border-color: transparent;
}

.nav-red .nav-link {
  color: #c4183c;
}

.nav-red.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #e52a51;
  color: #c4183c;
}

.nav-red.nav-outlined-pills .nav-link.active:hover {
  border-color: #e52a51;
}

.nav-red.nav-outlined-pills .nav-link {
  color: #c4183c;
}

.nav-orange .nav-link.active {
  background-color: #fb7906;
  border-color: #ee7204;
  color: #fff;
}

.nav-orange .nav-link.disabled {
  color: #868e96;
}

.nav-orange .nav-link.disabled:hover {
  cursor: not-allowed;
  border-color: transparent;
}

.nav-orange .nav-link {
  color: #fb7906;
}

.nav-orange.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #fc9438;
  color: #fb7906;
}

.nav-orange.nav-outlined-pills .nav-link.active:hover {
  border-color: #fc9438;
}

.nav-orange.nav-outlined-pills .nav-link {
  color: #fb7906;
}

.nav-yellow .nav-link.active {
  background-color: #ffb400;
  border-color: #f0a900;
  color: #212529;
}

.nav-yellow .nav-link.disabled {
  color: #868e96;
}

.nav-yellow .nav-link.disabled:hover {
  cursor: not-allowed;
  border-color: transparent;
}

.nav-yellow .nav-link {
  color: #ffb400;
}

.nav-yellow.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #ffc333;
  color: #ffb400;
}

.nav-yellow.nav-outlined-pills .nav-link.active:hover {
  border-color: #ffc333;
}

.nav-yellow.nav-outlined-pills .nav-link {
  color: #ffb400;
}

.nav-green .nav-link.active {
  background-color: #17c671;
  border-color: #15b869;
  color: #fff;
}

.nav-green .nav-link.disabled {
  color: #868e96;
}

.nav-green .nav-link.disabled:hover {
  cursor: not-allowed;
  border-color: transparent;
}

.nav-green .nav-link {
  color: #17c671;
}

.nav-green.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #2ae68b;
  color: #17c671;
}

.nav-green.nav-outlined-pills .nav-link.active:hover {
  border-color: #2ae68b;
}

.nav-green.nav-outlined-pills .nav-link {
  color: #17c671;
}

.nav-teal .nav-link.active {
  background-color: #1adba2;
  border-color: #18cd98;
  color: #212529;
}

.nav-teal .nav-link.disabled {
  color: #868e96;
}

.nav-teal .nav-link.disabled:hover {
  cursor: not-allowed;
  border-color: transparent;
}

.nav-teal .nav-link {
  color: #1adba2;
}

.nav-teal.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #40e8b7;
  color: #1adba2;
}

.nav-teal.nav-outlined-pills .nav-link.active:hover {
  border-color: #40e8b7;
}

.nav-teal.nav-outlined-pills .nav-link {
  color: #1adba2;
}

.nav-cyan .nav-link.active {
  background-color: #00b8d8;
  border-color: #00abc9;
  color: #fff;
}

.nav-cyan .nav-link.disabled {
  color: #868e96;
}

.nav-cyan .nav-link.disabled:hover {
  cursor: not-allowed;
  border-color: transparent;
}

.nav-cyan .nav-link {
  color: #00b8d8;
}

.nav-cyan.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #0cdbff;
  color: #00b8d8;
}

.nav-cyan.nav-outlined-pills .nav-link.active:hover {
  border-color: #0cdbff;
}

.nav-cyan.nav-outlined-pills .nav-link {
  color: #00b8d8;
}

.nav-white .nav-link.active {
  background-color: #fff;
  border-color: #f7f7f7;
  color: #212529;
}

.nav-white .nav-link.disabled {
  color: #868e96;
}

.nav-white .nav-link.disabled:hover {
  cursor: not-allowed;
  border-color: transparent;
}

.nav-white .nav-link {
  color: #fff;
}

.nav-white.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #fff;
  color: #fff;
}

.nav-white.nav-outlined-pills .nav-link.active:hover {
  border-color: #fff;
}

.nav-white.nav-outlined-pills .nav-link {
  color: #fff;
}

.nav-gray .nav-link.active {
  background-color: #868e96;
  border-color: #7e868f;
  color: #fff;
}

.nav-gray .nav-link.disabled {
  color: #868e96;
}

.nav-gray .nav-link.disabled:hover {
  cursor: not-allowed;
  border-color: transparent;
}

.nav-gray .nav-link {
  color: #868e96;
}

.nav-gray.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #a1a8ae;
  color: #868e96;
}

.nav-gray.nav-outlined-pills .nav-link.active:hover {
  border-color: #a1a8ae;
}

.nav-gray.nav-outlined-pills .nav-link {
  color: #868e96;
}

.nav-gray-dark .nav-link.active {
  background-color: #343a40;
  border-color: #2d3238;
  color: #fff;
}

.nav-gray-dark .nav-link.disabled {
  color: #868e96;
}

.nav-gray-dark .nav-link.disabled:hover {
  cursor: not-allowed;
  border-color: transparent;
}

.nav-gray-dark .nav-link {
  color: #343a40;
}

.nav-gray-dark.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #4b545c;
  color: #343a40;
}

.nav-gray-dark.nav-outlined-pills .nav-link.active:hover {
  border-color: #4b545c;
}

.nav-gray-dark.nav-outlined-pills .nav-link {
  color: #343a40;
}

.navbar {
  padding: 0.75rem 1.5rem;
}

.navbar-brand {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  margin-right: 1.5rem;
  font-size: 0.9rem;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-weight: 400;
}

.navbar-text {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.navbar-toggler {
  padding: 0.5rem;
  font-size: 1rem;
  background: #fff;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
  background: none;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: hsla(0, 0%, 100%, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: hsla(0, 0%, 100%, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: hsla(0, 0%, 100%, 0.25);
}

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: hsla(0, 0%, 100%, 0.5);
  border-color: hsla(0, 0%, 100%, 0.1);
  background: none;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: hsla(0, 0%, 100%, 0.5);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  background-color: #fff;
  border: none;
  border-radius: 0.625rem;
  box-shadow:
    0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.card .list-group-item {
  padding: 0.8125rem 1.875rem;
}

.card .card-text {
  margin-bottom: 1.5625rem;
}

.card a:hover {
  text-decoration: none;
}

.card-small {
  box-shadow:
    0 2px 0 rgba(90, 97, 105, 0.11),
    0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06),
    0 7px 70px rgba(90, 97, 105, 0.1);
}

.card-small .card-body,
.card-small .card-footer,
.card-small .card-header {
  padding: 1rem;
}

.card-body {
  padding: 1.875rem;
}

.card-body > p:last-child {
  margin-bottom: 0;
}

.card-title {
  font-weight: 500;
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -1.09375rem;
}

.card-link {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    sans-serif;
}

.card-link + .card-link {
  margin-left: 1.875rem;
}

.card-header {
  padding: 1.09375rem 1.875rem;
  background-color: #fff;
  border-bottom: none;
}

.card-header:first-child {
  border-radius: 0.625rem 0.625rem 0 0;
}

.card-footer {
  padding: 1.09375rem 1.875rem;
  background-color: #fff;
  border-top: none;
}

.card-footer:last-child {
  border-radius: 0 0 0.625rem 0.625rem;
}

.card-header-tabs {
  margin-bottom: -1rem;
  border-bottom: 0;
}

.card-header-tabs .nav-link,
.card-header-tabs .nav-link:hover {
  border-bottom: transparent;
}

.card-header-pills {
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}

.card-header-pills:hover {
  background: transparent;
}

.card-img-overlay {
  padding: 1.875rem 2.1875rem;
  background: rgba(90, 97, 105, 0.5);
  border-radius: 0.625rem;
}

.card-img-overlay .card-title {
  color: #fff;
}

.card-img {
  border-radius: 0.625rem;
}

.card-img-top {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.card-img-bottom {
  border-bottom-right-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.card-deck .card {
  margin-bottom: 0.9375rem;
}

@media (min-width: 576px) {
  .card-deck {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }

  .card-deck .card {
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }
}

.card-group > .card {
  box-shadow:
    0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
}

.card-group > .card:last-child .card-body,
.card-group > .card:last-child .card-footer {
  border-right: none;
}

.card-group .card-body,
.card-group .card-footer {
  border-right: 1px solid #e7e9ea;
}

@media (min-width: 576px) {
  .card-group {
    box-shadow:
      0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
      0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
      0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
      0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
    border-radius: 0.625rem;
  }

  .card-group > .card {
    box-shadow: none;
  }

  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:only-child {
    border-radius: 0.625rem;
  }

  .card-group > .card:only-child .card-header,
  .card-group > .card:only-child .card-img-top {
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
  }

  .card-group > .card:only-child .card-footer,
  .card-group > .card:only-child .card-img-bottom {
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
  }

  .card-group > .card:not(:first-child):not(:last-child):not(:only-child),
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-footer,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-header,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-img-bottom,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-img-top {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 2.1875rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }
}

.pagination {
  padding-left: 0;
  list-style: none;
  border-radius: 0.375rem;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-size: 0.875rem;
}

.page-link {
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: none;
  margin: 0;
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.page-link:focus,
.page-link:hover {
  color: #0056b3;
  background-color: #f5f5f6;
  border-color: #dfe1e3;
}

.page-item {
  box-shadow:
    0 0.125rem 0.9375rem rgba(90, 97, 105, 0.1),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.15);
}

.page-item:first-child {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  overflow: hidden;
}

.page-item:last-child {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  overflow: hidden;
}

.page-item:last-child .page-link {
  border-right: none;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #a8aeb4;
  background-color: #fff;
  border-color: #dfe1e3;
}

.pagination-lg .page-link {
  padding: 0.9375rem 1.5625rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.6875rem;
  font-size: 0.6875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
}

.badge {
  padding: 0.375rem 0.5rem;
  font-size: 75%;
  font-weight: 500;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    sans-serif;
  color: #fff;
  border-radius: 0.375rem;
}

a.badge {
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.badge-pill {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 10rem;
}

.badge-squared {
  border-radius: 0;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:focus,
.badge-primary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-outline-primary {
  background: none;
  border: 1px solid #007bff;
  color: #007bff;
}

.badge-secondary {
  color: #fff;
  background-color: #5a6169;
}

.badge-secondary[href]:focus,
.badge-secondary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #42484e;
}

.badge-outline-secondary {
  background: none;
  border: 1px solid #5a6169;
  color: #5a6169;
}

.badge-success {
  color: #fff;
  background-color: #17c671;
}

.badge-success[href]:focus,
.badge-success[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #129857;
}

.badge-outline-success {
  background: none;
  border: 1px solid #17c671;
  color: #17c671;
}

.badge-info {
  color: #fff;
  background-color: #00b8d8;
}

.badge-info[href]:focus,
.badge-info[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #008da5;
}

.badge-outline-info {
  background: none;
  border: 1px solid #00b8d8;
  color: #00b8d8;
}

.badge-warning {
  color: #212529;
  background-color: #ffb400;
}

.badge-warning[href]:focus,
.badge-warning[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #cc9000;
}

.badge-outline-warning {
  background: none;
  border: 1px solid #ffb400;
  color: #ffb400;
}

.badge-danger {
  color: #fff;
  background-color: #c4183c;
}

.badge-danger[href]:focus,
.badge-danger[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #97122e;
}

.badge-outline-danger {
  background: none;
  border: 1px solid #c4183c;
  color: #c4183c;
}

.badge-light {
  color: #212529;
  background-color: #fbfbfb;
}

.badge-light[href]:focus,
.badge-light[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #e2e2e2;
}

.badge-outline-light {
  background: none;
  border: 1px solid #fbfbfb;
  color: #fbfbfb;
  color: #212529;
}

.badge-dark {
  color: #fff;
  background-color: #212529;
}

.badge-dark[href]:focus,
.badge-dark[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0a0c0d;
}

.badge-outline-dark {
  background: none;
  border: 1px solid #212529;
  color: #212529;
}

.jumbotron {
  padding: 38px 42px;
  margin-bottom: 2rem;
  background-color: #eceeef;
  border-radius: 0.5rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 0;
}

.alert-link {
  font-weight: 500;
}

.alert-dismissible .close {
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.alert-dismissible .close:hover {
  cursor: pointer;
}

.alert-primary {
  color: #f5faff;
  background-color: #007bff;
}

.alert-primary .alert-link {
  color: #f5faff;
}

.alert-secondary {
  color: #d9dcdf;
  background-color: #5a6169;
}

.alert-secondary .alert-link {
  color: #d9dcdf;
}

.alert-success {
  color: #d7fae9;
  background-color: #17c671;
}

.alert-success .alert-link {
  color: #d7fae9;
}

.alert-info {
  background-color: #00b8d8;
}

.alert-info,
.alert-info .alert-link {
  color: #cef8ff;
}

.alert-warning {
  color: #fffcf5;
  background-color: #ffb400;
}

.alert-warning .alert-link {
  color: #fffcf5;
}

.alert-danger {
  color: #fad7de;
  background-color: #c4183c;
}

.alert-danger .alert-link {
  color: #fad7de;
}

.alert-light {
  color: #fff;
  background-color: #fbfbfb;
  color: #212529;
}

.alert-light .alert-link {
  color: #fff;
  color: #212529;
}

.alert-dark {
  background-color: #212529;
}

.alert-dark,
.alert-dark .alert-link {
  color: #959faa;
}

.progress-wrapper {
  position: relative;
  color: #5a6169;
}

.progress-wrapper .progress-label {
  font-size: 0.8125rem;
}

.progress-wrapper .progress-value {
  position: absolute;
  top: 6px;
  right: 0;
  color: #5a6169;
}

.progress {
  height: 0.625rem;
  font-size: 0.625rem;
  line-height: 0.625rem;
  background-color: #f5f5f6;
  margin-top: 6px;
  border-radius: 1.25rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(90, 97, 105, 0.15);
}

.progress-sm {
  height: 0.3125rem;
}

.progress-lg,
.progress-lg .progress-bar {
  height: 0.9375rem;
}

.progress-bar {
  height: 0.625rem;
  line-height: 0.625rem;
  color: #fff;
  background-color: #007bff;
  transition: width 0.6s ease;
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    hsla(0, 0%, 100%, 0.15) 25%,
    transparent 0,
    transparent 50%,
    hsla(0, 0%, 100%, 0.15) 0,
    hsla(0, 0%, 100%, 0.15) 75%,
    transparent 0,
    transparent
  );
  background-size: 0.625rem 0.625rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.list-group-small .list-group-item {
  padding: 0.625rem 1rem;
  font-size: 0.8125rem;
}

.list-group-item-action {
  color: #5a6169;
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  color: #5a6169;
  background-color: #f7f8f8;
}

.list-group-item-action:active {
  color: #5a6169;
  background-color: #eceeef;
}

.list-group-item {
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-weight: 300;
}

.list-group-item:first-child {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #868e96;
  background-color: #fff;
}

.list-group-item.active {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

a.list-group-item-primary,
button.list-group-item-primary {
  color: #004085;
}

a.list-group-item-primary:focus,
a.list-group-item-primary:hover,
button.list-group-item-primary:focus,
button.list-group-item-primary:hover {
  color: #004085;
  background-color: #9fcdff;
}

a.list-group-item-primary.active,
button.list-group-item-primary.active {
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #2f3237;
  background-color: #d1d3d5;
}

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #2f3237;
}

a.list-group-item-secondary:focus,
a.list-group-item-secondary:hover,
button.list-group-item-secondary:focus,
button.list-group-item-secondary:hover {
  color: #2f3237;
  background-color: #c4c6c9;
}

a.list-group-item-secondary.active,
button.list-group-item-secondary.active {
  background-color: #2f3237;
  border-color: #2f3237;
}

.list-group-item-success {
  color: #0c673b;
  background-color: #beefd7;
}

a.list-group-item-success,
button.list-group-item-success {
  color: #0c673b;
}

a.list-group-item-success:focus,
a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
  color: #0c673b;
  background-color: #aaeaca;
}

a.list-group-item-success.active,
button.list-group-item-success.active {
  background-color: #0c673b;
  border-color: #0c673b;
}

.list-group-item-info {
  color: #006070;
  background-color: #b8ebf4;
}

a.list-group-item-info,
button.list-group-item-info {
  color: #006070;
}

a.list-group-item-info:focus,
a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
  color: #006070;
  background-color: #a2e5f1;
}

a.list-group-item-info.active,
button.list-group-item-info.active {
  background-color: #006070;
  border-color: #006070;
}

.list-group-item-warning {
  color: #855e00;
  background-color: #ffeab8;
}

a.list-group-item-warning,
button.list-group-item-warning {
  color: #855e00;
}

a.list-group-item-warning:focus,
a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
  color: #855e00;
  background-color: #ffe29f;
}

a.list-group-item-warning.active,
button.list-group-item-warning.active {
  background-color: #855e00;
  border-color: #855e00;
}

.list-group-item-danger {
  color: #660c1f;
  background-color: #eebec8;
}

a.list-group-item-danger,
button.list-group-item-danger {
  color: #660c1f;
}

a.list-group-item-danger:focus,
a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
  color: #660c1f;
  background-color: #e9aab7;
}

a.list-group-item-danger.active,
button.list-group-item-danger.active {
  background-color: #660c1f;
  border-color: #660c1f;
}

.list-group-item-light {
  color: #838383;
  background-color: #fefefe;
}

a.list-group-item-light,
button.list-group-item-light {
  color: #838383;
}

a.list-group-item-light:focus,
a.list-group-item-light:hover,
button.list-group-item-light:focus,
button.list-group-item-light:hover {
  color: #838383;
  background-color: #f1f1f1;
}

a.list-group-item-light.active,
button.list-group-item-light.active {
  background-color: #838383;
  border-color: #838383;
}

.list-group-item-dark {
  color: #111315;
  background-color: #c1c2c3;
}

a.list-group-item-dark,
button.list-group-item-dark {
  color: #111315;
}

a.list-group-item-dark:focus,
a.list-group-item-dark:hover,
button.list-group-item-dark:focus,
button.list-group-item-dark:hover {
  color: #111315;
  background-color: #b4b5b6;
}

a.list-group-item-dark.active,
button.list-group-item-dark.active {
  background-color: #111315;
  border-color: #111315;
}

.close {
  font-size: 1.5rem;
  font-weight: 500;
  text-shadow: none;
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.close,
.close:focus,
.close:hover {
  color: #8c949d;
}

.modal {
  z-index: 1050;
}

.modal-dialog {
  margin: 0.625rem;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
}

.modal-dialog-centered {
  min-height: calc(100% - 1.25rem);
}

.modal-content {
  background-color: #fff;
  border: none;
  border-radius: 0.5rem;
  box-shadow:
    0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
}

.modal-backdrop {
  z-index: 1040;
  background-color: #5a6169;
}

.modal-backdrop.show {
  opacity: 0.12;
}

.modal-header {
  padding: 0.9375rem 2.1875rem;
  border-bottom: 1px solid #dfe1e3;
}

.modal-title {
  line-height: 1.5;
}

.modal-body {
  padding: 1.875rem 2.1875rem;
}

.modal-footer {
  padding: 0.9375rem 2.1875rem;
  border-top: 1px solid #dfe1e3;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.875rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.75rem);
  }

  .modal-content {
    box-shadow:
      0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
      0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
      0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
      0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  z-index: 1070;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.6875rem;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .arrow {
  width: 5px;
  height: 5px;
}

.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
  padding: 5px 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow:before,
.bs-tooltip-top .arrow:before {
  border-width: 5px 2.5px 0;
  border-top-color: #fff;
}

.bs-tooltip-auto[x-placement^="right"],
.bs-tooltip-right {
  padding: 0 5px;
}

.bs-tooltip-auto[x-placement^="right"] .arrow,
.bs-tooltip-right .arrow {
  width: 5px;
  height: 5px;
}

.bs-tooltip-auto[x-placement^="right"] .arrow:before,
.bs-tooltip-right .arrow:before {
  border-width: 2.5px 5px 2.5px 0;
  border-right-color: #fff;
}

.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 5px 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow:before,
.bs-tooltip-bottom .arrow:before {
  border-width: 0 2.5px 5px;
  border-bottom-color: #fff;
}

.bs-tooltip-auto[x-placement^="left"],
.bs-tooltip-left {
  padding: 0 5px;
}

.bs-tooltip-auto[x-placement^="left"] .arrow,
.bs-tooltip-left .arrow {
  width: 5px;
  height: 5px;
}

.bs-tooltip-auto[x-placement^="left"] .arrow:before,
.bs-tooltip-left .arrow:before {
  border-width: 2.5px 0 2.5px 5px;
  border-left-color: #fff;
}

.tooltip-inner {
  max-width: 200px;
  padding: 7px 13px;
  color: #5a6169;
  border-radius: 0.375rem;
}

.popover,
.tooltip-inner {
  background-color: #fff;
  box-shadow:
    0 3px 15px rgba(90, 97, 105, 0.1),
    0 2px 3px rgba(90, 97, 105, 0.2);
}

.popover {
  z-index: 1060;
  max-width: 276px;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.6875rem;
  border: none;
  padding: 0;
  border-radius: 0.5rem;
}

.popover .arrow {
  width: 10px;
  height: 5px;
  margin: 0 0.5rem;
}

.popover .arrow:after,
.popover .arrow:before {
  border-width: 11px;
}

.bs-popover-auto[x-placement^="top"],
.bs-popover-top {
  margin-bottom: 5px;
}

.bs-popover-auto[x-placement^="top"] .arrow,
.bs-popover-top .arrow {
  bottom: -6px;
}

.bs-popover-auto[x-placement^="top"] .arrow:after,
.bs-popover-auto[x-placement^="top"] .arrow:before,
.bs-popover-top .arrow:after,
.bs-popover-top .arrow:before {
  border-width: 5px 5px 0;
}

.bs-popover-auto[x-placement^="top"] .arrow:before,
.bs-popover-top .arrow:before {
  border-top-color: rgba(0, 0, 0, 0.05);
}

.bs-popover-auto[x-placement^="top"] .arrow:after,
.bs-popover-top .arrow:after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-auto[x-placement^="right"],
.bs-popover-right {
  margin-left: 5px;
}

.bs-popover-auto[x-placement^="right"] .arrow,
.bs-popover-right .arrow {
  left: -6px;
  width: 5px;
  height: 10px;
  margin: 0.5rem 0;
}

.bs-popover-auto[x-placement^="right"] .arrow:after,
.bs-popover-auto[x-placement^="right"] .arrow:before,
.bs-popover-right .arrow:after,
.bs-popover-right .arrow:before {
  border-width: 5px 5px 5px 0;
}

.bs-popover-auto[x-placement^="right"] .arrow:before,
.bs-popover-right .arrow:before {
  border-right-color: rgba(0, 0, 0, 0.05);
}

.bs-popover-auto[x-placement^="right"] .arrow:after,
.bs-popover-right .arrow:after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom {
  margin-top: 5px;
}

.bs-popover-auto[x-placement^="bottom"] .arrow,
.bs-popover-bottom .arrow {
  top: -6px;
}

.bs-popover-auto[x-placement^="bottom"] .arrow:after,
.bs-popover-auto[x-placement^="bottom"] .arrow:before,
.bs-popover-bottom .arrow:after,
.bs-popover-bottom .arrow:before {
  border-width: 0 5px 5px;
}

.bs-popover-auto[x-placement^="bottom"] .arrow:before,
.bs-popover-bottom .arrow:before {
  border-bottom-color: rgba(0, 0, 0, 0.05);
}

.bs-popover-auto[x-placement^="bottom"] .arrow:after,
.bs-popover-bottom .arrow:after {
  top: 1px;
  border-bottom-color: #fff;
}

.bs-popover-auto[x-placement^="bottom"] .popover-header:before,
.bs-popover-bottom .popover-header:before {
  width: 10px;
  margin-left: -5px;
  border-bottom: 1px solid #f5f5f6;
}

.bs-popover-auto[x-placement^="left"],
.bs-popover-left {
  margin-right: 5px;
}

.bs-popover-auto[x-placement^="left"] .arrow,
.bs-popover-left .arrow {
  right: -6px;
  width: 5px;
  height: 10px;
  margin: 0.5rem 0;
}

.bs-popover-auto[x-placement^="left"] .arrow:after,
.bs-popover-auto[x-placement^="left"] .arrow:before,
.bs-popover-left .arrow:after,
.bs-popover-left .arrow:before {
  border-width: 5px 0 5px 5px;
}

.bs-popover-auto[x-placement^="left"] .arrow:before,
.bs-popover-left .arrow:before {
  border-left-color: rgba(0, 0, 0, 0.05);
}

.bs-popover-auto[x-placement^="left"] .arrow:after,
.bs-popover-left .arrow:after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 14px 20px;
  font-size: 1rem;
  color: #3d5170;
  line-height: 14px;
  background-color: #f5f5f6;
  border-bottom: 1px solid #e7e9ea;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}

.popover-body {
  padding: 15px 20px;
  color: #5a6169;
}

.carousel {
  box-shadow:
    0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
}

.carousel-item {
  transition: transform 0.6s ease;
}

.carousel-control-next,
.carousel-control-prev {
  width: 15%;
  color: #fff;
  opacity: 0.5;
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 20px;
  height: 20px;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  margin-right: 15%;
  margin-left: 15%;
}

.carousel-indicators li {
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  background-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 3px;
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  right: 15%;
  left: 15%;
  color: #fff;
}

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  touch-action: none;
  user-select: none;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
  background: #eceeef;
  border-radius: 5px;
  box-shadow: inset 0 1px 2px rgba(90, 97, 105, 0.1);
  margin: 35px 0;
}

.noUi-target:focus {
  outline: 0;
  box-shadow:
    0 0 8px rgba(0, 123, 255, 0.65),
    0 3px 15px rgba(90, 97, 105, 0.1),
    0 2px 3px rgba(90, 97, 105, 0.2);
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  position: absolute;
  will-change: transform;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform-origin: 0 0;
}

.noUi-connect:focus,
.noUi-origin:focus {
  outline: 0;
}

.noUi-connect {
  background: #007bff;
  border-radius: 5px;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -17px;
  left: auto;
}

.noUi-rtl .noUi-value-horizontal {
  transform: translate(50%, 50%);
}

.noUi-rtl .noUi-value-vertical {
  transform: translateY(50%);
}

.noUi-vertical {
  width: 5px;
}

.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-vertical .noUi-handle {
  left: -10px;
  top: -11.5px;
}

.noUi-vertical .noUi-handle:after,
.noUi-vertical .noUi-handle:before {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

.noUi-vertical .noUi-tooltip {
  transform: translateY(-50%);
  top: 50%;
  right: 30px;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-horizontal {
  height: 5px;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-horizontal .noUi-handle {
  left: -11.5px;
  top: -10px;
}

.noUi-horizontal .noUi-tooltip {
  transform: translate(-50%);
  left: 50%;
  bottom: 30px;
}

.noUi-handle {
  position: absolute;
  border: 1px solid #e7e9ea;
  border-radius: 50%;
  box-shadow:
    0 3px 15px rgba(90, 97, 105, 0.1),
    0 2px 3px rgba(90, 97, 105, 0.2);
  background: #fff;
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.noUi-handle:hover {
  cursor: grab;
  cursor: -moz-grab;
}

.noUi-handle:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
}

.noUi-handle:focus {
  outline: 0;
  box-shadow:
    0 0 8px rgba(0, 123, 255, 0.65),
    0 3px 15px rgba(90, 97, 105, 0.1),
    0 2px 3px rgba(90, 97, 105, 0.2);
}

.noUi-handle:after {
  left: 17px;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-connects {
  border-radius: 5px;
}

.noUi-draggable {
  cursor: ew-resize;
}

.noUi-active {
  transform: scale(1.1);
}

[disabled] .noUi-connect {
  background: #b8b8b8;
}

[disabled].noUi-handle,
[disabled] .noUi-handle,
[disabled].noUi-target {
  cursor: not-allowed;
}

[disabled] .noUi-handle {
  background: #f2f3f4;
}

[disabled] .noUi-handle:focus {
  box-shadow:
    0 3px 15px rgba(90, 97, 105, 0.1),
    0 2px 3px rgba(90, 97, 105, 0.2);
}

.noUi-pips,
.noUi-pips * {
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #a8aeb4;
  font-size: 12px;
}

.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.noUi-value-sub {
  color: #a8aeb4;
  font-size: 10px;
}

.noUi-marker {
  position: absolute;
}

.noUi-marker,
.noUi-marker-large,
.noUi-marker-sub {
  background: #a8aeb4;
}

.noUi-pips-horizontal {
  padding: 10px 0;
  height: auto;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  transform: translate3d(-50%, 50%, 0);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 1px;
  height: 4px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 7px;
}

.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  transform: translate3d(0, -50%, 0);
  padding-left: 15px;
}

.noUi-marker-vertical.noUi-marker {
  width: 4px;
  height: 1px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 7px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.375rem;
  background: #fff;
  color: #5a6169;
  box-shadow:
    0 3px 15px rgba(90, 97, 105, 0.1),
    0 2px 3px rgba(90, 97, 105, 0.2);
}

.slider-primary .noUi-connect {
  background: #007bff;
}

.slider-secondary .noUi-connect {
  background: #5a6169;
}

.slider-success .noUi-connect {
  background: #17c671;
}

.slider-info .noUi-connect {
  background: #00b8d8;
}

.slider-warning .noUi-connect {
  background: #ffb400;
}

.slider-danger .noUi-connect {
  background: #c4183c;
}

.slider-light .noUi-connect {
  background: #fbfbfb;
}

.slider-dark .noUi-connect {
  background: #212529;
}

.datepicker {
  border-radius: 0.625rem;
  direction: ltr;
}

.datepicker-inline {
  width: 220px;
}

.datepicker-rtl {
  direction: rtl;
}

.datepicker-rtl.dropdown-menu {
  left: auto;
}

.datepicker-rtl table tr td span {
  float: right;
}

.datepicker-dropdown {
  top: 0;
  left: 0;
  padding: 20px 22px;
}

.datepicker-dropdown:after,
.datepicker-dropdown:before {
  content: "";
  display: inline-block;
  border-top: 0;
  position: absolute;
}

.datepicker-dropdown:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
}

.datepicker-dropdown:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
}

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #c3c7cc;
}

.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}

.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.datepicker table tr td {
  border-radius: 50%;
}

.datepicker table tr th {
  border-radius: 0.375rem;
  font-weight: 500;
}

.datepicker table tr td,
.datepicker table tr th {
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
  width: 36px;
  height: 36px;
  border: none;
  text-align: center;
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: initial;
}

.datepicker table tr td.new,
.datepicker table tr td.old {
  color: #c3c7cc;
}

.datepicker table tr td.day:hover,
.datepicker table tr td.focused {
  background: #eceeef;
  cursor: pointer;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #e7e9ea;
  cursor: default;
}

.datepicker table tr td.highlighted {
  border-radius: 0;
}

.datepicker table tr td.highlighted.focused {
  background: #007bff;
}

.datepicker table tr td.highlighted.disabled,
.datepicker table tr td.highlighted.disabled:active {
  background: #007bff;
  color: #5a6169;
}

.datepicker table tr td.today {
  background: #e6f2ff;
}

.datepicker table tr td.today.focused {
  background: #f5f5f6;
}

.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:active {
  background: #f5f5f6;
  color: #868e96;
}

.datepicker table tr td.range {
  background: #007bff;
  color: #fff;
  border-radius: 0;
}

.datepicker table tr td.range.focused {
  background: #0067d6;
}

.datepicker table tr td.range.day.disabled:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:active {
  background: #0062cc;
  color: #3395ff;
}

.datepicker table tr td.range.highlighted.focused {
  background: #cbd3da;
}

.datepicker table tr td.range.highlighted.disabled,
.datepicker table tr td.range.highlighted.disabled:active {
  background: #e9ecef;
  color: #e7e9ea;
}

.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:active {
  background: #007bff;
  color: #fff;
}

.datepicker table tr td.day.range-start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.datepicker table tr td.day.range-end {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.datepicker table tr td.day.range-start.range-end {
  border-radius: 50%;
}

.datepicker table tr td.active,
.datepicker table tr td.active.highlighted,
.datepicker table tr td.active.highlighted:hover,
.datepicker table tr td.active:hover,
.datepicker table tr td.day.range:hover,
.datepicker table tr td.selected,
.datepicker table tr td.selected.highlighted,
.datepicker table tr td.selected.highlighted:hover,
.datepicker table tr td.selected:hover {
  background: #007bff;
  color: #fff;
}

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  border-radius: 4px;
}

.datepicker table tr td span.focused,
.datepicker table tr td span:hover {
  background: #e9ecef;
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #e7e9ea;
  cursor: default;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:hover {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td span.new,
.datepicker table tr td span.old {
  color: #868e96;
}

.datepicker .datepicker-switch {
  width: 145px;
}

.datepicker .datepicker-switch,
.datepicker .next,
.datepicker .prev,
.datepicker tfoot tr th {
  cursor: pointer;
}

.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover {
  background: #e9ecef;
}

.datepicker .next.disabled,
.datepicker .prev.disabled {
  visibility: hidden;
}

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}

.input-daterange input {
  text-align: center;
}

.bg-primary {
  background-color: #007bff !important;
}

.bg-primary.card .card-body,
.bg-primary.card .card-footer,
.bg-primary.card .card-header,
.bg-primary.card .card-title {
  background-color: #0062cc !important;
}

.bg-primary.card .card-footer,
.bg-primary.card .card-header {
  background: #0074f0;
}

a.bg-primary:focus,
a.bg-primary:hover {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #5a6169 !important;
}

.bg-secondary.card .card-body,
.bg-secondary.card .card-footer,
.bg-secondary.card .card-header,
.bg-secondary.card .card-title {
  background-color: #42484e !important;
}

.bg-secondary.card .card-footer,
.bg-secondary.card .card-header {
  background: #535961;
}

a.bg-secondary:focus,
a.bg-secondary:hover {
  background-color: #42484e !important;
}

.bg-success {
  background-color: #17c671 !important;
}

.bg-success.card .card-body,
.bg-success.card .card-footer,
.bg-success.card .card-header,
.bg-success.card .card-title {
  background-color: #129857 !important;
}

.bg-success.card .card-footer,
.bg-success.card .card-header {
  background: #15b869;
}

a.bg-success:focus,
a.bg-success:hover {
  background-color: #129857 !important;
}

.bg-info {
  background-color: #00b8d8 !important;
}

.bg-info.card .card-body,
.bg-info.card .card-footer,
.bg-info.card .card-header,
.bg-info.card .card-title {
  background-color: #008da5 !important;
}

.bg-info.card .card-footer,
.bg-info.card .card-header {
  background: #00abc9;
}

a.bg-info:focus,
a.bg-info:hover {
  background-color: #008da5 !important;
}

.bg-warning {
  background-color: #ffb400 !important;
}

.bg-warning.card .card-body,
.bg-warning.card .card-footer,
.bg-warning.card .card-header,
.bg-warning.card .card-title {
  background-color: #cc9000 !important;
}

.bg-warning.card .card-footer,
.bg-warning.card .card-header {
  background: #f0a900;
}

a.bg-warning:focus,
a.bg-warning:hover {
  background-color: #cc9000 !important;
}

.bg-danger {
  background-color: #c4183c !important;
}

.bg-danger.card .card-body,
.bg-danger.card .card-footer,
.bg-danger.card .card-header,
.bg-danger.card .card-title {
  background-color: #97122e !important;
}

.bg-danger.card .card-footer,
.bg-danger.card .card-header {
  background: #b61638;
}

a.bg-danger:focus,
a.bg-danger:hover {
  background-color: #97122e !important;
}

.bg-light {
  background-color: #fbfbfb !important;
}

.bg-light.card .card-body,
.bg-light.card .card-footer,
.bg-light.card .card-header,
.bg-light.card .card-title {
  background-color: #e2e2e2 !important;
}

.bg-light.card .card-footer,
.bg-light.card .card-header {
  background: #f3f3f3;
}

a.bg-light:focus,
a.bg-light:hover {
  background-color: #e2e2e2 !important;
}

.bg-dark {
  background-color: #212529 !important;
}

.bg-dark.card .card-body,
.bg-dark.card .card-footer,
.bg-dark.card .card-header,
.bg-dark.card .card-title {
  background-color: #0a0c0d !important;
}

.bg-dark.card .card-footer,
.bg-dark.card .card-header {
  background: #1a1d21;
}

a.bg-dark:focus,
a.bg-dark:hover {
  background-color: #0a0c0d !important;
}

.border {
  border: 1px solid #e1e5eb !important;
}

.border-top {
  border-top: 1px solid #e1e5eb !important;
}

.border-right {
  border-right: 1px solid #e1e5eb !important;
}

.border-bottom {
  border-bottom: 1px solid #e1e5eb !important;
}

.border-left {
  border-left: 1px solid #e1e5eb !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #5a6169 !important;
}

.border-success {
  border-color: #17c671 !important;
}

.border-info {
  border-color: #00b8d8 !important;
}

.border-warning {
  border-color: #ffb400 !important;
}

.border-danger {
  border-color: #c4183c !important;
}

.border-light {
  border-color: #fbfbfb !important;
}

.border-dark {
  border-color: #212529 !important;
}

.rounded {
  border-radius: 0.375rem !important;
}

.rounded-top {
  border-top-left-radius: 0.375rem !important;
}

.rounded-right,
.rounded-top {
  border-top-right-radius: 0.375rem !important;
}

.rounded-bottom,
.rounded-right {
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-bottom,
.rounded-left {
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-left {
  border-top-left-radius: 0.375rem !important;
}

.text-monospace {
  font-family:
    Roboto Mono,
    Menlo,
    Monaco,
    Consolas,
    Liberation Mono,
    Courier New,
    monospace;
}

.font-weight-normal {
  font-weight: 300;
}

.font-weight-bold {
  font-weight: 500;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #0062cc !important;
}

.text-secondary {
  color: #5a6169 !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #42484e !important;
}

.text-success {
  color: #17c671 !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #129857 !important;
}

.text-info {
  color: #00b8d8 !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #008da5 !important;
}

.text-warning {
  color: #ffb400 !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #cc9000 !important;
}

.text-danger {
  color: #c4183c !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #97122e !important;
}

.text-light {
  color: #fbfbfb !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #e2e2e2 !important;
}

.text-dark {
  color: #212529 !important;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #0a0c0d !important;
}

.text-body {
  color: #5a6169 !important;
}

a.text-white:focus,
a.text-white:hover {
  color: #e6e6e6 !important;
}

.text-black {
  color: #000;
}

a.text-black:focus,
a.text-black:hover {
  color: #000 !important;
}

.text-muted {
  color: #868e96 !important;
}

.with-shadows {
  box-shadow:
    0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
}

:root {
  --salmon: #ff4169;
  --royal-blue: #674eec;
  --java: #1adba2;
}

html {
  font-size: 16px;
  font-weight: 500;
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
  html {
    font-size: 16px;
    font-weight: 400;
  }
}

body {
  background: #f5f6f8;
  font-size: 15px;
  font-weight: 500;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body {
    font-size: 13px;
    font-weight: 400;
  }
}

a {
  color: #007bff;
}

a:hover {
  color: #0056b3;
}

label {
  font-weight: 400;
}

.datepicker {
  font-size: 0.75rem;
  padding: 0.625rem;
}

.datepicker table tr td,
.datepicker table tr th {
  width: 1.875rem;
  height: 1.875rem;
}

.btn-black:not([disabled]):not(.disabled).active,
.btn-black:not([disabled]):not(.disabled):active,
.btn-white:not([disabled]):not(.disabled).active,
.btn-white:not([disabled]):not(.disabled):active {
  box-shadow: none !important;
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}

.btn-black:not([disabled]):not(.disabled).active + .btn,
.btn-black:not([disabled]):not(.disabled):active + .btn,
.btn-white:not([disabled]):not(.disabled).active + .btn,
.btn-white:not([disabled]):not(.disabled):active + .btn {
  border-left: 1px solid #007bff;
}

.btn-black:hover,
.btn-white:hover {
  background: #fff;
  border-color: #fff;
  box-shadow:
    0 0.125rem 0.625rem rgba(129, 142, 163, 0.2),
    0 0.0625rem 0.125rem rgba(129, 142, 163, 0.3);
}

.btn.btn-white {
  border: 1px solid #e1e5eb;
  color: #3d5170;
}

.btn.btn-white:not([disabled]):not(.disabled).active-primary.active,
.btn.btn-white:not([disabled]):not(.disabled).active-primary:active {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}

.btn.btn-white:not([disabled]):not(.disabled).active-primary.active + .btn,
.btn.btn-white:not([disabled]):not(.disabled).active-primary:active + .btn {
  border-left: 1px solid red;
}

.btn.btn-white:not([disabled]):not(.disabled).active-secondary.active,
.btn.btn-white:not([disabled]):not(.disabled).active-secondary:active {
  background-color: #5a6169;
  border-color: #5a6169;
  color: #fff;
}

.btn.btn-white:not([disabled]):not(.disabled).active-secondary.active + .btn,
.btn.btn-white:not([disabled]):not(.disabled).active-secondary:active + .btn {
  border-left: 1px solid red;
}

.btn.btn-white:not([disabled]):not(.disabled).active-success.active,
.btn.btn-white:not([disabled]):not(.disabled).active-success:active {
  background-color: #17c671;
  border-color: #17c671;
  color: #fff;
}

.btn.btn-white:not([disabled]):not(.disabled).active-success.active + .btn,
.btn.btn-white:not([disabled]):not(.disabled).active-success:active + .btn {
  border-left: 1px solid red;
}

.btn.btn-white:not([disabled]):not(.disabled).active-info.active,
.btn.btn-white:not([disabled]):not(.disabled).active-info:active {
  background-color: #00b8d8;
  border-color: #00b8d8;
  color: #fff;
}

.btn.btn-white:not([disabled]):not(.disabled).active-info.active + .btn,
.btn.btn-white:not([disabled]):not(.disabled).active-info:active + .btn {
  border-left: 1px solid red;
}

.btn.btn-white:not([disabled]):not(.disabled).active-warning.active,
.btn.btn-white:not([disabled]):not(.disabled).active-warning:active {
  background-color: #ffb400;
  border-color: #ffb400;
  color: #212529;
}

.btn.btn-white:not([disabled]):not(.disabled).active-warning.active + .btn,
.btn.btn-white:not([disabled]):not(.disabled).active-warning:active + .btn {
  border-left: 1px solid red;
}

.btn.btn-white:not([disabled]):not(.disabled).active-danger.active,
.btn.btn-white:not([disabled]):not(.disabled).active-danger:active {
  background-color: #c4183c;
  border-color: #c4183c;
  color: #fff;
}

.btn.btn-white:not([disabled]):not(.disabled).active-danger.active + .btn,
.btn.btn-white:not([disabled]):not(.disabled).active-danger:active + .btn {
  border-left: 1px solid red;
}

.btn.btn-white:not([disabled]):not(.disabled).active-light.active,
.btn.btn-white:not([disabled]):not(.disabled).active-light:active {
  background-color: #fbfbfb;
  border-color: #fbfbfb;
  color: #212529;
}

.btn.btn-white:not([disabled]):not(.disabled).active-light.active + .btn,
.btn.btn-white:not([disabled]):not(.disabled).active-light:active + .btn {
  border-left: 1px solid red;
}

.btn.btn-white:not([disabled]):not(.disabled).active-dark.active,
.btn.btn-white:not([disabled]):not(.disabled).active-dark:active {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
}

.btn.btn-white:not([disabled]):not(.disabled).active-dark.active + .btn,
.btn.btn-white:not([disabled]):not(.disabled).active-dark:active + .btn {
  border-left: 1px solid red;
}

.btn.btn-white:not([disabled]):not(.disabled).active-salmon.active,
.btn.btn-white:not([disabled]):not(.disabled).active-salmon:active {
  background-color: #ff4169;
  border-color: #ff4169;
  color: #fff;
}

.btn.btn-white:not([disabled]):not(.disabled).active-salmon.active + .btn,
.btn.btn-white:not([disabled]):not(.disabled).active-salmon:active + .btn {
  border-left: 1px solid red;
}

.btn.btn-white:not([disabled]):not(.disabled).active-royal-blue.active,
.btn.btn-white:not([disabled]):not(.disabled).active-royal-blue:active {
  background-color: #674eec;
  border-color: #674eec;
  color: #fff;
}

.btn.btn-white:not([disabled]):not(.disabled).active-royal-blue.active + .btn,
.btn.btn-white:not([disabled]):not(.disabled).active-royal-blue:active + .btn {
  border-left: 1px solid red;
}

.btn.btn-white:not([disabled]):not(.disabled).active-java.active,
.btn.btn-white:not([disabled]):not(.disabled).active-java:active {
  background-color: #1adba2;
  border-color: #1adba2;
  color: #212529;
}

.btn.btn-white:not([disabled]):not(.disabled).active-java.active + .btn,
.btn.btn-white:not([disabled]):not(.disabled).active-java:active + .btn {
  border-left: 1px solid red;
}

.btn-salmon {
  color: #fff;
  border-color: #ff4169;
  background-color: #ff4169;
  box-shadow: none;
}

.btn-salmon:hover {
  color: #fff;
  background-color: #ff2855;
  border-color: #ff2855;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(255, 65, 105, 0.25);
}

.btn-salmon.focus,
.btn-salmon:focus {
  box-shadow:
    0 0 0 3px rgba(255, 65, 105, 0.15),
    0 3px 15px rgba(255, 65, 105, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-salmon.disabled,
.btn-salmon:disabled {
  background-color: #ff4169;
  border-color: #ff4169;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-salmon:not(:disabled):not(.disabled).active,
.btn-salmon:not(:disabled):not(.disabled):active,
.show > .btn-salmon.dropdown-toggle {
  color: #fff;
  background-color: #ff2855;
  border-color: #ff0e41;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-royal-blue {
  color: #fff;
  border-color: #674eec;
  background-color: #674eec;
  box-shadow: none;
}

.btn-royal-blue:hover {
  color: #fff;
  background-color: #5337ea;
  border-color: #5337ea;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(103, 78, 236, 0.25);
}

.btn-royal-blue.focus,
.btn-royal-blue:focus {
  box-shadow:
    0 0 0 3px rgba(103, 78, 236, 0.15),
    0 3px 15px rgba(103, 78, 236, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-royal-blue.disabled,
.btn-royal-blue:disabled {
  background-color: #674eec;
  border-color: #674eec;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-royal-blue:not(:disabled):not(.disabled).active,
.btn-royal-blue:not(:disabled):not(.disabled):active,
.show > .btn-royal-blue.dropdown-toggle {
  color: #fff;
  background-color: #5337ea;
  border-color: #3f20e7;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-java {
  color: #212529;
  border-color: #1adba2;
  background-color: #1adba2;
  box-shadow: none;
}

.btn-java:hover {
  color: #fff;
  background-color: #17c491;
  border-color: #17c491;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(26, 219, 162, 0.25);
}

.btn-java.focus,
.btn-java:focus {
  box-shadow:
    0 0 0 3px rgba(26, 219, 162, 0.15),
    0 3px 15px rgba(26, 219, 162, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-java.disabled,
.btn-java:disabled {
  background-color: #1adba2;
  border-color: #1adba2;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-java:not(:disabled):not(.disabled).active,
.btn-java:not(:disabled):not(.disabled):active,
.show > .btn-java.dropdown-toggle {
  color: #fff;
  background-color: #17c491;
  border-color: #15ad80;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-accent {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
  box-shadow: none;
}

.btn-accent:hover {
  color: #fff;
  background-color: #006fe6;
  border-color: #006fe6;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(0, 123, 255, 0.25);
}

.btn-accent.focus,
.btn-accent:focus {
  box-shadow:
    0 0 0 3px rgba(0, 123, 255, 0.15),
    0 3px 15px rgba(0, 123, 255, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-accent.disabled,
.btn-accent:disabled {
  background-color: #007bff;
  border-color: #007bff;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-accent:not(:disabled):not(.disabled).active,
.btn-accent:not(:disabled):not(.disabled):active,
.show > .btn-accent.dropdown-toggle {
  color: #fff;
  background-color: #006fe6;
  border-color: #0062cc;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-salmon {
  background-color: initial;
  background-image: none;
  border-color: #ff4169;
  color: #ff4169;
}

.btn-outline-salmon:hover {
  color: #fff;
  background-color: #ff4169;
  border-color: #ff4169;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(255, 65, 105, 0.25);
}

.btn-outline-salmon.focus,
.btn-outline-salmon:focus {
  box-shadow:
    0 0 0 3px rgba(255, 65, 105, 0.15),
    0 3px 15px rgba(255, 65, 105, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-salmon.disabled,
.btn-outline-salmon:disabled {
  color: #ff4169;
  background-color: initial;
  box-shadow: none;
}

.btn-outline-salmon:not(:disabled):not(.disabled).active,
.btn-outline-salmon:not(:disabled):not(.disabled):active,
.show > .btn-outline-salmon.dropdown-toggle {
  color: #fff;
  background-color: #ff4169;
  border-color: #ff4169;
}

.btn-outline-salmon:not(:disabled):not(.disabled).active:focus,
.btn-outline-salmon:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-salmon.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-royal-blue {
  background-color: initial;
  background-image: none;
  border-color: #674eec;
  color: #674eec;
}

.btn-outline-royal-blue:hover {
  color: #fff;
  background-color: #674eec;
  border-color: #674eec;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(103, 78, 236, 0.25);
}

.btn-outline-royal-blue.focus,
.btn-outline-royal-blue:focus {
  box-shadow:
    0 0 0 3px rgba(103, 78, 236, 0.15),
    0 3px 15px rgba(103, 78, 236, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-royal-blue.disabled,
.btn-outline-royal-blue:disabled {
  color: #674eec;
  background-color: initial;
  box-shadow: none;
}

.btn-outline-royal-blue:not(:disabled):not(.disabled).active,
.btn-outline-royal-blue:not(:disabled):not(.disabled):active,
.show > .btn-outline-royal-blue.dropdown-toggle {
  color: #fff;
  background-color: #674eec;
  border-color: #674eec;
}

.btn-outline-royal-blue:not(:disabled):not(.disabled).active:focus,
.btn-outline-royal-blue:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-royal-blue.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-java {
  background-color: initial;
  background-image: none;
  border-color: #1adba2;
  color: #1adba2;
}

.btn-outline-java:hover {
  color: #212529;
  background-color: #1adba2;
  border-color: #1adba2;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(26, 219, 162, 0.25);
}

.btn-outline-java.focus,
.btn-outline-java:focus {
  box-shadow:
    0 0 0 3px rgba(26, 219, 162, 0.15),
    0 3px 15px rgba(26, 219, 162, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-java.disabled,
.btn-outline-java:disabled {
  color: #1adba2;
  background-color: initial;
  box-shadow: none;
}

.btn-outline-java:not(:disabled):not(.disabled).active,
.btn-outline-java:not(:disabled):not(.disabled):active,
.show > .btn-outline-java.dropdown-toggle {
  color: #212529;
  background-color: #1adba2;
  border-color: #1adba2;
}

.btn-outline-java:not(:disabled):not(.disabled).active:focus,
.btn-outline-java:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-java.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-accent {
  background-color: initial;
  background-image: none;
  border-color: #007bff;
  color: #007bff;
}

.btn-outline-accent:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(0, 123, 255, 0.25);
}

.btn-outline-accent.focus,
.btn-outline-accent:focus {
  box-shadow:
    0 0 0 3px rgba(0, 123, 255, 0.15),
    0 3px 15px rgba(0, 123, 255, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-accent.disabled,
.btn-outline-accent:disabled {
  color: #007bff;
  background-color: initial;
  box-shadow: none;
}

.btn-outline-accent:not(:disabled):not(.disabled).active,
.btn-outline-accent:not(:disabled):not(.disabled):active,
.show > .btn-outline-accent.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-accent:not(:disabled):not(.disabled).active:focus,
.btn-outline-accent:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-accent.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-group-sm i {
  transform: scale(1.3);
}

.navbar-light .navbar-brand {
  font-weight: 500;
  color: #3d5170;
}

.nav-link {
  font-size: 0.8125rem;
  font-weight: 400;
}

.nav-link-icon {
  color: #818ea3;
}

.nav-link-icon:focus,
.nav-link-icon:hover {
  color: #66748b;
}

.nav-link-icon__wrapper {
  position: relative;
}

.nav-link-icon i {
  font-size: 1.25rem;
}

.nav-link-icon i.material-icons {
  font-size: 1.5625rem;
}

.badge-salmon {
  color: #fff;
  background-color: #ff4169;
}

.badge-salmon[href]:focus,
.badge-salmon[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #ff0e41;
}

.badge-outline-salmon {
  background: none;
  border: 1px solid #ff4169;
  color: #ff4169;
}

.badge-royal-blue {
  color: #fff;
  background-color: #674eec;
}

.badge-royal-blue[href]:focus,
.badge-royal-blue[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #3f20e7;
}

.badge-outline-royal-blue {
  background: none;
  border: 1px solid #674eec;
  color: #674eec;
}

.badge-java {
  color: #212529;
  background-color: #1adba2;
}

.badge-java[href]:focus,
.badge-java[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #15ad80;
}

.badge-outline-java {
  background: none;
  border: 1px solid #1adba2;
  color: #1adba2;
}

.badge-accent {
  color: #fff;
  background-color: #007bff;
}

.badge-accent[href]:focus,
.badge-accent[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-outline-accent {
  background: none;
  border: 1px solid #007bff;
  color: #007bff;
}

.alert-salmon {
  color: #fff;
  background-color: #ff4169;
}

.alert-salmon .alert-link {
  color: #fff;
}

.alert-royal-blue {
  color: #fff;
  background-color: #674eec;
}

.alert-royal-blue .alert-link {
  color: #fff;
}

.alert-java {
  background-color: #1adba2;
}

.alert-java,
.alert-java .alert-link {
  color: #edfdf8;
}

.alert-accent {
  color: #f5faff;
  background-color: #007bff;
}

.alert-accent .alert-link {
  color: #f5faff;
}

.alert-dismissible .close {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.input-group {
  min-width: 7.5rem;
}

.input-group-text {
  font-size: 0.8125rem;
}

.input-group.input-group-seamless > .custom-select:not(:first-child),
.input-group.input-group-seamless > .form-control:not(:first-child) {
  padding-left: 1.875rem;
}

.input-group-text i {
  transform: scale(1.1);
}

.input-group-text i.fa {
  font-size: 0.75rem;
}

.input-group-text i.material-icons {
  top: 0;
  font-size: 0.8125rem;
}

.custom-select-sm {
  font-size: 0.6875rem;
}

.custom-select-lg,
.custom-select-sm {
  border-radius: 0.25rem;
}

.custom-control {
  line-height: 1.5rem;
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label:after {
  border-color: #becad6;
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label:after,
.custom-toggle
  .custom-control-input:checked:disabled
  ~ .custom-control-label:after {
  background: #becad6;
}

.slider-salmon .noUi-connect {
  background: #ff4169;
}

.slider-royal-blue .noUi-connect {
  background: #674eec;
}

.slider-java .noUi-connect {
  background: #1adba2;
}

.slider-accent .noUi-connect {
  background: #007bff;
}

.noUi-pips {
  font-size: 0.625rem;
}

.noUi-tooltip {
  font-size: 0.75rem;
  padding: 5px 10px;
}

.noUi-horizontal .noUi-tooltip {
  bottom: 1.625rem;
}

.noUi-horizontal .noUi-handle {
  left: -0.625rem;
  top: -0.5rem;
}

.noUi-handle {
  width: 23px;
  height: 23px;
}

.sc-legend-container {
  margin: 0;
  padding: 10px 30px 7px;
}

.sc-legend-container li {
  display: inline-block;
  margin-right: 0.75rem;
  font-size: 12px;
  color: #818ea3;
}

.sc-legend {
  position: relative;
  padding-left: 25px;
}

.sc-legend__label {
  position: absolute;
  width: 20px;
  border-radius: 10px;
  height: 2px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

div[class^="sc-tooltip-"] {
  background: #fff;
  border-radius: 7px;
  font-size: 11px;
  opacity: 1;
  position: absolute;
  transition: all 0.1s ease;
  transform: translate(-50%);
  padding: 5px 10px;
  box-shadow:
    0 2px 0 rgba(90, 97, 105, 0.11),
    0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06),
    0 7px 70px rgba(90, 97, 105, 0.1);
  pointer-events: none;
  min-width: 120px;
  white-space: nowrap;
}

div[class^="sc-tooltip-"] thead {
  text-align: center;
}

div[class^="sc-tooltip-"] thead tr th {
  font-weight: 500;
}

.sc-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.thumbnail {
  border: 1px solid #d4d4d4;
}

i.material-icons {
  top: 2px;
}

.dropdown .fa,
.dropdown .material-icons {
  color: #c3c7cc;
  margin-right: 0.25rem;
}

.dropdown .dropdown-item:hover {
  background-color: #fafafb;
}

.card-header {
  position: relative;
}

.card-header h1,
.card-header h2,
.card-header h3,
.card-header h4,
.card-header h5,
.card-header h6 {
  font-weight: 500;
}

.card-header .block-handle {
  position: absolute;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMFYweiIgaWQ9ImEiLz48L2RlZnM+PGNsaXBQYXRoIGlkPSJiIj48dXNlIG92ZXJmbG93PSJ2aXNpYmxlIiB4bGluazpocmVmPSIjYSIvPjwvY2xpcFBhdGg+PHBhdGggY2xpcC1wYXRoPSJ1cmwoI2IpIiBkPSJNMjAgOUg0djJoMTZWOXpNNCAxNWgxNnYtMkg0djJ6Ii8+PC9zdmc+);
  background-position: 50%;
  background-size: 95%;
  width: 1.125rem;
  height: 0.5rem;
  top: 50%;
  right: 0.9375rem;
  transform: translateY(-50%);
  opacity: 0.15;
}

.card-header .block-handle:hover {
  cursor: -webkit-grab;
}

.card .view-report {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 0.75rem;
}

.card .view-report a {
  color: #3d5170;
}

.card .view-report a:hover {
  color: #007bff;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-auto {
  overflow: auto;
}

.no-shadow {
  box-shadow: none !important;
}

.bg-salmon {
  background-color: #ff4169 !important;
}

.bg-salmon.card .card-body,
.bg-salmon.card .card-footer,
.bg-salmon.card .card-header,
.bg-salmon.card .card-title {
  background-color: #ff0e41 !important;
}

.bg-salmon.card .card-footer,
.bg-salmon.card .card-header {
  background: #ff325d;
}

a.bg-salmon:focus,
a.bg-salmon:hover {
  background-color: #ff0e41 !important;
}

.bg-royal-blue {
  background-color: #674eec !important;
}

.bg-royal-blue.card .card-body,
.bg-royal-blue.card .card-footer,
.bg-royal-blue.card .card-header,
.bg-royal-blue.card .card-title {
  background-color: #3f20e7 !important;
}

.bg-royal-blue.card .card-footer,
.bg-royal-blue.card .card-header {
  background: #5b40eb;
}

a.bg-royal-blue:focus,
a.bg-royal-blue:hover {
  background-color: #3f20e7 !important;
}

.bg-java {
  background-color: #1adba2 !important;
}

.bg-java.card .card-body,
.bg-java.card .card-footer,
.bg-java.card .card-header,
.bg-java.card .card-title {
  background-color: #15ad80 !important;
}

.bg-java.card .card-footer,
.bg-java.card .card-header {
  background: #18cd98;
}

a.bg-java:focus,
a.bg-java:hover {
  background-color: #15ad80 !important;
}

.text-salmon {
  color: #ff4169 !important;
}

a.text-salmon:focus,
a.text-salmon:hover {
  color: #ff0e41 !important;
}

.text-royal-blue {
  color: #674eec !important;
}

a.text-royal-blue:focus,
a.text-royal-blue:hover {
  color: #3f20e7 !important;
}

.text-java {
  color: #1adba2 !important;
}

a.text-java:focus,
a.text-java:hover {
  color: #15ad80 !important;
}

.text-fiord-blue {
  color: #3d5170 !important;
}

a.text-fiord-blue:focus,
a.text-fiord-blue:hover {
  color: #2b394f !important;
}

.text-shuttle-gray {
  color: #5a6169 !important;
}

a.text-shuttle-gray:focus,
a.text-shuttle-gray:hover {
  color: #42484e !important;
}

.text-reagent-gray {
  color: #818ea3 !important;
}

a.text-reagent-gray:focus,
a.text-reagent-gray:hover {
  color: #66748b !important;
}

.text-mischka {
  color: #cacedb !important;
}

a.text-mischka:focus,
a.text-mischka:hover {
  color: #acb2c6 !important;
}

.text-athens-gray {
  color: #e9ecef !important;
}

a.text-athens-gray:focus,
a.text-athens-gray:hover {
  color: #cbd3da !important;
}

.text-accent {
  color: #007bff !important;
}

a.text-accent:focus,
a.text-accent:hover {
  color: #0062cc !important;
}

.text-light {
  color: #818ea3 !important;
}

.text-semibold {
  font-weight: 400;
}

.rounded {
  border-radius: 5px !important;
}

.rounded-top {
  border-top-left-radius: 5px !important;
}

.rounded-right,
.rounded-top {
  border-top-right-radius: 5px !important;
}

.rounded-bottom,
.rounded-right {
  border-bottom-right-radius: 5px !important;
}

.rounded-bottom,
.rounded-left {
  border-bottom-left-radius: 5px !important;
}

.rounded-left {
  border-top-left-radius: 5px !important;
}

.border-salmon {
  border-color: #ff4169 !important;
}

.border-royal-blue {
  border-color: #674eec !important;
}

.border-java {
  border-color: #1adba2 !important;
}

.border-accent {
  border-color: #007bff !important;
}

.dataTables_wrapper {
  background: #fff;
  border-radius: 0.375rem;
  box-shadow:
    0 2px 0 rgba(90, 97, 105, 0.11),
    0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06),
    0 7px 70px rgba(90, 97, 105, 0.1);
}

.dataTables_wrapper:after {
  display: block;
  clear: both;
  content: "";
}

.dataTables_wrapper table.dataTable {
  border-top: 1px solid #e1e5eb;
  font-size: 0.8125rem;
  display: table !important;
  width: 100% !important;
}

@media (max-width: 991.98px) {
  .dataTables_wrapper table.dataTable {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 0;
  }
}

.dataTables_wrapper table.dataTable thead tr:last-child th {
  padding-right: 1.7rem;
}

.dataTables_wrapper table.dataTable thead th {
  text-align: center;
  background-color: #fbfbfb;
  border: 1px solid #e1e5eb;
  font-weight: 400;
  padding: 0.75rem 1.0625rem;
}

.dataTables_wrapper table.dataTable thead th:focus {
  outline: 0;
}

.dataTables_wrapper table.dataTable thead th:first-child {
  border-left: none;
}

.dataTables_wrapper table.dataTable thead th:last-child {
  border-right: none;
}

.dataTables_wrapper table.dataTable thead th.hide-sort-icons:after {
  display: none;
}

.dataTables_wrapper table.dataTable thead th.sorting,
.dataTables_wrapper table.dataTable thead th.sorting_asc,
.dataTables_wrapper table.dataTable thead th.sorting_desc {
  position: relative;
}

.dataTables_wrapper table.dataTable thead th.sorting:after,
.dataTables_wrapper table.dataTable thead th.sorting_asc:after,
.dataTables_wrapper table.dataTable thead th.sorting_desc:after {
  content: "";
  position: absolute;
  background-position: 50%;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.2;
}

.dataTables_wrapper table.dataTable thead th.sorting:after {
  width: 10px;
  height: 13px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJub256ZXJvIj48cGF0aCBkPSJNMTAgNUw1IDAgMCA1ek0wIDcuMTY2bDUgNSA1LTV6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 90%;
}

.dataTables_wrapper table.dataTable thead th.sorting_asc:after {
  width: 10px;
  height: 6px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcgMTRsNS01IDUgNXoiLz48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PC9zdmc+);
}

.dataTables_wrapper table.dataTable thead th.sorting_desc:after {
  width: 10px;
  height: 6px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcgMTBsNSA1IDUtNXoiLz48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PC9zdmc+);
}

.dataTables_wrapper table.dataTable tbody td {
  padding: 0.4375rem 1.0625rem;
  border: 1px solid #e1e5eb;
  text-align: center;
}

.dataTables_wrapper table.dataTable tbody td:first-child {
  border-left: none;
}

.dataTables_wrapper table.dataTable tbody td::last-child {
  border-right: none;
}

.dataTables_wrapper table.dataTable tbody td.dataTables_empty {
  padding: 0.8125rem 1.0625rem;
}

.dataTables_wrapper table.dataTable td.child {
  padding: 0 !important;
}

.dataTables_wrapper table.dataTable td.child ul {
  width: 100%;
}

.dataTables_wrapper table.dataTable td.child ul li {
  padding: 1rem !important;
  display: flex;
}

.dataTables_wrapper table.dataTable td.child ul li:after {
  display: block;
  clear: both;
  content: "";
}

.dataTables_wrapper table.dataTable td.child .dtr-title {
  float: left;
  text-align: left;
  margin: auto 0;
}

.dataTables_wrapper table.dataTable td.child .dtr-data {
  margin-left: auto;
}

.dataTables_wrapper
  table.dataTable.dtr-inline.collapsed
  tbody
  > tr[role="row"]
  > td:first-child:before,
.dataTables_wrapper
  table.dataTable.dtr-inline.collapsed
  tbody
  > tr[role="row"]
  > th:first-child:before {
  box-shadow: none;
  text-align: center;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 22px;
  font-size: 1rem;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  box-shadow:
    0 2px 0 rgba(90, 97, 105, 0.11),
    0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06),
    0 7px 70px rgba(90, 97, 105, 0.1);
}

.dataTables_filter,
.dataTables_info,
.dataTables_length,
.dataTables_paginate {
  width: 50%;
  float: left;
  font-size: 13px;
  padding: 15px 20px;
  color: #818ea3;
}

@media (max-width: 575.98px) {
  .dataTables_filter,
  .dataTables_info,
  .dataTables_length,
  .dataTables_paginate {
    width: 100%;
    max-width: 100% !important;
  }
}

.dataTables_filter label,
.dataTables_info label,
.dataTables_length label,
.dataTables_paginate label {
  margin: 0;
}

@media (max-width: 575.98px) {
  .dataTables_length label {
    display: table;
    margin: 0 auto;
  }

  .dataTables_filter {
    width: 100%;
  }

  .dataTables_filter input,
  .dataTables_filter label {
    margin: 0 !important;
    width: 100%;
    background-size: 5% !important;
  }
}

.dataTables_info,
.dataTables_paginate {
  padding: 20px;
  background: #fbfbfb;
  border-radius: 0 0 0.375rem 0.375rem;
}

@media (max-width: 767.98px) {
  .dataTables_info,
  .dataTables_paginate {
    padding: 14px;
    width: 100%;
    text-align: center !important;
  }
}

@media (max-width: 767.98px) {
  .dataTables_info {
    border-bottom: 1px solid #e1e5eb;
    border-radius: 0;
  }
}

.dataTables_length select {
  border: 1px solid #e1e5eb;
  font-size: 0.625rem;
  color: #818ea3;
  height: 1.5625rem;
  background-color: #fff;
  border-radius: 0.375rem;
  margin: 0 0.1875rem;
}

.dataTables_paginate {
  text-align: right;
}

.dataTables_paginate .ellipsis,
.dataTables_paginate .paginate_button {
  background: #fff;
  padding: 0.4286rem 0.875rem;
  border: 1px solid #e1e5eb;
}

@media (max-width: 767.98px) {
  .dataTables_paginate .ellipsis,
  .dataTables_paginate .paginate_button {
    font-size: 0.625rem;
    padding: 0.4286rem 0.6rem;
  }
}

.dataTables_paginate .paginate_button {
  border-radius: 0.25rem;
  color: #3d5170;
  will-change: background-color, color, border-color;
  transition:
    background-color 0.25s ease-in-out,
    color 0.25s ease-in-out,
    border-color 0.25s ease-in-out;
}

.dataTables_paginate .paginate_button:hover {
  cursor: pointer;
  text-decoration: none;
}

.dataTables_paginate .paginate_button.current,
.dataTables_paginate .paginate_button:not(.disabled):hover {
  background: #007bff;
  color: #fff;
  border-color: #007bff;
}

.dataTables_paginate .paginate_button:focus {
  outline: 0;
}

.dataTables_paginate .paginate_button.disabled {
  background-color: #f2f4f5;
}

.dataTables_paginate .paginate_button.disabled:hover {
  cursor: not-allowed;
}

.dataTables_paginate .paginate_button.previous {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dataTables_paginate .paginate_button.next {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dataTables_paginate .ellipsis {
  border-left: none;
}

.dataTables_paginate span > .paginate_button {
  border-radius: 0;
  border-left: 0;
}

.dataTables_paginate span > .paginate_button:hover {
  border-color: #007bff;
}

.dataTables_paginate span > .paginate_button:last-child {
  border-right: 0;
}

.dataTables_filter {
  padding: 0.625rem;
}

@media (max-width: 767.98px) {
  .dataTables_filter {
    max-width: 200px;
    float: right;
  }
}

.dataTables_filter label {
  float: right;
  font-size: 0;
}

.dataTables_filter input {
  font-size: 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #e1e5eb;
  font-weight: 300;
  border-radius: 0.25rem;
  margin-left: 0.3125rem;
  padding: 0.4375rem 0.625rem 0.4375rem 1.75rem;
  min-width: 11.25rem;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjQ0FDRURCIiBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1LjUgMTRoLS43OWwtLjI4LS4yN0E2LjQ3MSA2LjQ3MSAwIDAgMCAxNiA5LjUgNi41IDYuNSAwIDEgMCA5LjUgMTZjMS42MSAwIDMuMDktLjU5IDQuMjMtMS41N2wuMjcuMjh2Ljc5bDUgNC45OUwyMC40OSAxOWwtNC45OS01em0tNiAwQzcuMDEgMTQgNSAxMS45OSA1IDkuNVM3LjAxIDUgOS41IDUgMTQgNy4wMSAxNCA5LjUgMTEuOTkgMTQgOS41IDE0eiIvPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=);
  background-position: left 7px center;
  background-repeat: no-repeat;
  background-size: 10%;
  will-change: border-color, box-shadow;
  box-shadow: none;
  transition:
    box-shadow 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.dataTables_filter input:hover {
  border-color: #b3bdcc;
}

.dataTables_filter input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #007bff;
  box-shadow:
    0 0.313rem 0.719rem rgba(0, 123, 255, 0.1),
    0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

.dataTables_filter input:focus:hover {
  border-color: #007bff;
}

.dataTables_filter input:focus {
  outline: 0;
}

.dataTables_filter input::placeholder {
  color: #868e96;
}

.dropzone {
  color: #818ea3;
}

.dropzone.dz-drag-hover .dz-default {
  background: #fff;
  border-color: #cbd3da;
}

.dropzone .dz-default {
  text-align: center;
  border: 1px dashed #dadfe4;
  padding: 0.75rem;
  position: relative;
  border-radius: 0.375rem;
  will-change: border-color, background;
  transition:
    border-color 0.25s ease-in-out,
    background 0.25s ease-in-out;
}

.dropzone .dz-default:hover {
  cursor: pointer;
}

.dropzone .dz-default + .dz-preview {
  margin-top: 0.75rem;
}

.dropzone .dz-preview {
  width: 100%;
  border: 1px solid #e9ecef;
  border-radius: 0.375rem;
  background: #fff;
  box-shadow: 0 1px 1px #e9ecef;
  padding: 0.75rem;
  display: flex;
  justify-content: space-between;
}

.dropzone .dz-preview + .dz-preview {
  margin-top: 0.75rem;
}

.dropzone .dz-preview .dz-image {
  max-width: 50px;
  border-radius: 0.375rem;
  overflow: hidden;
  margin-right: 20px;
}

.dropzone .dz-preview .dz-image img {
  width: 100%;
}

.dropzone .dz-preview .dz-details,
.dropzone .dz-preview .dz-error-message {
  justify-content: center;
  display: flex;
  flex-direction: column-reverse;
}

.dropzone .dz-preview .dz-details {
  margin-right: 3rem;
  flex: 2 1;
}

.dropzone .dz-preview .dz-filename {
  font-weight: 500;
  color: #3d5170;
  font-size: 0.875rem;
}

.dropzone .dz-preview .dz-size {
  font-size: 80%;
  color: #818ea3;
}

.dropzone .dz-preview .dz-error-message span {
  background-color: #c4183c;
  color: #fff;
  padding: 5px 12px;
  border-radius: 5px;
  font-size: 12px;
}

.dropzone .dz-preview .dz-error-mark svg,
.dropzone .dz-preview.dz-error .dz-success-mark,
.dropzone .dz-preview .dz-success-mark svg {
  display: none;
}

.bootstrap-tagsinput {
  padding: 0.4375rem 0.375rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #e1e5eb;
  border-radius: 0.25rem;
  transition:
    box-shadow 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.bootstrap-tagsinput.focus {
  color: #495057;
  background-color: #fff;
  border-color: #007bff;
  box-shadow:
    0 0.313rem 0.719rem rgba(0, 123, 255, 0.1),
    0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

.bootstrap-tagsinput > input {
  border: none;
  padding: 0;
}

.bootstrap-tagsinput > input:focus {
  outline: 0;
}

.bootstrap-tagsinput .tag {
  display: inline-block;
  background: #fbfbfb;
  padding: 0.125rem 1.375rem 0.125rem 0.625rem;
  margin-bottom: 0.3125rem;
  border-radius: 1.25rem;
  font-size: 0.6875rem;
  position: relative;
  text-transform: uppercase;
  border: 1px solid #e1e5eb;
  color: #818ea3;
}

.bootstrap-tagsinput .tag > span {
  position: absolute;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE5IDYuNDFMMTcuNTkgNSAxMiAxMC41OSA2LjQxIDUgNSA2LjQxIDEwLjU5IDEyIDUgMTcuNTkgNi40MSAxOSAxMiAxMy40MSAxNy41OSAxOSAxOSAxNy41OSAxMy40MSAxMnoiLz48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PC9zdmc+);
  right: 0.5rem;
  top: 50%;
  width: 0.625rem;
  height: 0.625rem;
  background-repeat: no-repeat;
  background-position: 50%;
  transform: translateY(-50%);
  background-size: 100%;
  opacity: 0.5;
}

.bootstrap-tagsinput .tag > span:hover {
  cursor: pointer;
}

html .ql-container.ql-snow,
html .ql-toolbar.ql-snow {
  border-color: #e1e5eb;
}

html .ql-container {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

html .ql-editor b,
html .ql-editor strong {
  font-weight: 600;
}

html .ql-toolbar {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #3d5170 !important;
}

html .ql-toolbar .ql-fill,
html .ql-toolbar .ql-stroke.ql-fill {
  fill: #818ea3;
}

html .ql-toolbar .ql-stroke {
  stroke: #818ea3;
}

html .ql-toolbar button:active .ql-fill,
html .ql-toolbar button:active .ql-stroke.ql-fill,
html .ql-toolbar button:focus .ql-fill,
html .ql-toolbar button:focus .ql-stroke.ql-fill,
html .ql-toolbar button:hover .ql-fill,
html .ql-toolbar button:hover .ql-stroke.ql-fill {
  fill: #007bff !important;
}

html .ql-toolbar button:active .ql-stroke,
html .ql-toolbar button:focus .ql-stroke,
html .ql-toolbar button:hover .ql-stroke {
  stroke: #007bff !important;
}

html .ql-toolbar .ql-picker .ql-picker-item:hover {
  color: #007bff !important;
}

html .ql-toolbar .ql-picker .ql-picker-options {
  padding: 6px 20px;
  border: 0 !important;
  box-shadow:
    0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
  border-radius: 0.375rem;
}

.fc .fc-toolbar h2 {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 1px;
  color: #b9c2cd;
}

.fc .fc-divider,
.fc .fc-list-heading td,
.fc .fc-popover .fc-header {
  background: #fafbfc !important;
}

.fc .fc-day-header {
  padding: 0.5rem 0;
}

.fc .fc-content,
.fc .fc-divider,
.fc .fc-list-heading td,
.fc .fc-list-view,
.fc .fc-popover,
.fc .fc-row,
.fc .fc-view td,
.fc .fc-view th,
.fc tbody,
.fc td,
.fc th,
.fc thead {
  border-color: #ededed !important;
}

.fc .fc-day-top .fc-day-number {
  padding: 0.625rem 0.75rem;
}

.fc .fc-event,
.fc .fc-event-dot {
  background: #007bff;
  border-color: #007bff;
}

.fc .fc-time,
.fc .fc-title {
  color: #fff;
  margin-left: 3px;
}

.fc td.fc-today {
  background: #fff3e8 !important;
}

.fc button.fc-button {
  background: #fff;
  box-shadow: none;
  border-color: #e1e5eb;
  padding: 7px 11px;
  height: auto;
  font-size: 0.6875rem;
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.fc button.fc-button:focus {
  outline: 0;
}

.fc button.fc-button:hover {
  box-shadow:
    0 0.125rem 0.625rem rgba(129, 142, 163, 0.2),
    0 0.0625rem 0.125rem rgba(129, 142, 163, 0.3);
}

.fc .fc-day-grid-event {
  padding: 3px 1px;
}

.main-navbar .navbar {
  height: 3.75rem;
}

.main-navbar .navbar > * {
  display: flex;
}

.main-navbar .navbar .nav-link {
  min-width: 3.75rem;
}

.main-navbar .navbar .notifications {
  position: relative;
}

@media (max-width: 767.98px) {
  .main-navbar .navbar .notifications {
    position: static;
  }

  .main-navbar .navbar .notifications .dropdown-menu {
    min-width: 100% !important;
    border-left: none;
  }
}

.main-navbar .navbar .notifications .badge {
  position: absolute;
  padding: 0.25rem 0.375rem;
  font-size: 0.5rem;
  left: 50%;
  top: 50%;
}

.main-navbar .navbar .notifications .dropdown-menu {
  padding: 0;
  min-width: 25rem;
  border-right: 0;
  left: auto;
}

@media (max-width: 767.98px) {
  .main-navbar .navbar .notifications .dropdown-menu {
    left: 0;
    right: 0;
  }
}

.main-navbar .navbar .notifications .dropdown-menu .dropdown-item {
  white-space: normal;
  display: flex;
  flex-flow: row;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  border-bottom: 1px solid #e1e5eb;
}

.main-navbar .navbar .notifications .dropdown-menu .dropdown-item:last-child {
  border-bottom: 0;
}

.main-navbar .navbar .notifications .dropdown-menu .notification__icon-wrapper {
  display: flex;
  padding: 0 5px;
}

.main-navbar .navbar .notifications .dropdown-menu .notification__icon {
  background-color: #f5f6f8;
  box-shadow:
    0 0 0 1px #fff,
    inset 0 0 3px rgba(0, 0, 0, 0.2);
  width: 2.1875rem;
  height: 2.1875rem;
  line-height: 0;
  display: block;
  text-align: center;
  margin: auto;
  border-radius: 50%;
}

.main-navbar .navbar .notifications .dropdown-menu .notification__icon i {
  color: #818ea3;
  line-height: 2.0625rem;
  font-size: 1.0625rem;
  margin: 0;
}

.main-navbar .navbar .notifications .dropdown-menu .notification__content {
  padding: 0 0.625rem;
}

.main-navbar .navbar .notifications .dropdown-menu .notification__content p {
  margin: 0;
  line-height: 1.5;
  font-size: 0.75rem;
}

.main-navbar .navbar .notifications .dropdown-menu .notification__category {
  font-size: 0.5625rem;
  color: #818ea3;
  letter-spacing: 0.0938rem;
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-weight: 500;
}

.main-navbar .navbar .notifications .dropdown-menu .notification__all {
  display: block;
  font-weight: 500;
  font-size: 11px;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.main-navbar .navbar .user-avatar {
  max-width: 2.5rem;
}

.main-navbar .navbar .navbar-nav .dropdown-menu {
  position: absolute;
  right: 0;
  left: auto;
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}

.main-navbar .navbar .nav-link-icon i {
  line-height: 2.5rem;
}

.main-content .header-navbar,
.main-content > .main-navbar {
  box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.12);
}

.main-sidebar {
  top: 0;
  position: fixed;
  height: 100vh;
  background: #fff;
  z-index: 1070;
  will-change: transform;
  transition: transform 0.2s ease-in-out;
  box-shadow:
    0 0.125rem 9.375rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.5rem rgba(90, 97, 105, 0.12),
    0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1),
    0 0.4375rem 2.1875rem rgba(165, 182, 201, 0.1);
}

@media (max-width: 767.98px) {
  .main-sidebar {
    transform: translateX(-100%);
    box-shadow: none;
  }
}

.main-sidebar.open {
  transform: translateX(0);
  box-shadow:
    0 0.125rem 9.375rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.5rem rgba(90, 97, 105, 0.12),
    0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1),
    0 0.4375rem 2.1875rem rgba(165, 182, 201, 0.1);
}

.main-sidebar .toggle-sidebar {
  position: absolute;
  right: 0;
  height: 100%;
  padding: 1.25rem;
  font-size: 1.25rem;
  border-left: 1px solid #e1e5eb;
}

.main-sidebar .toggle-sidebar:hover {
  cursor: pointer;
}

.main-sidebar .navbar-brand {
  overflow: hidden;
  height: 3.75rem;
  font-size: 1rem;
}

@media (max-width: 991.98px) {
  .main-sidebar .navbar-brand {
    font-size: 90%;
  }
}

.main-sidebar .nav-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 3.75rem - 1px);
}

.main-sidebar .nav {
  border-bottom: 1px solid #e1e5eb;
}

.main-sidebar .nav .nav-item,
.main-sidebar .nav .nav-link {
  white-space: nowrap;
  min-width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    sans-serif;
  will-change: background-color, box-shadow, color;
  transition:
    box-shadow 0.2s ease,
    color 0.2s ease,
    background-color 0.2s ease;
  font-size: 0.85rem;
}

.main-sidebar .nav .nav-item .nav-link {
  border-bottom: 1px solid #e1e5eb;
  font-weight: 400;
  color: #3d5170;
  padding: 0.9375rem 1.5625rem;
}

.main-sidebar .nav .nav-item .nav-link i {
  min-width: 1.25rem;
  font-size: 90%;
  text-align: center;
  vertical-align: middle;
  will-change: color;
  color: #cacedb;
  transition: color 0.2s ease;
  margin-right: 0.375rem;
}

.main-sidebar .nav .nav-item .nav-link i.material-icons {
  font-size: 1.125rem;
  top: -1px;
}

.main-sidebar .nav .nav-item.active,
.main-sidebar .nav .nav-item .nav-link.active,
.main-sidebar .nav .nav-item .nav-link:hover,
.main-sidebar .nav .nav-item:hover {
  box-shadow: inset 0.1875rem 0 0 #007bff;
  background-color: #fbfbfb;
  color: #007bff;
}

.main-sidebar .nav .nav-item.active i,
.main-sidebar .nav .nav-item .nav-link.active i,
.main-sidebar .nav .nav-item .nav-link:hover i,
.main-sidebar .nav .nav-item:hover i {
  color: #007bff;
}

.main-sidebar .nav--no-borders .nav-item .nav-link {
  border-bottom: 0;
}

.main-sidebar .nav--no-borders .dropdown-menu {
  box-shadow: inset 0 0 0.4375rem rgba(61, 81, 112, 0.2);
}

.main-sidebar .nav--no-borders .dropdown-menu .dropdown-item:first-child {
  border-top: 1px solid #e1e5eb;
}

.main-sidebar .dropdown-menu {
  position: static !important;
  transform: translate(0) !important;
  box-shadow: none;
  border-radius: 0;
  width: 100%;
  border: none;
  padding: 0;
  box-shadow: inset 0 -0.1875rem 0.1875rem rgba(61, 81, 112, 0.08);
}

.main-sidebar .dropdown-menu .dropdown-item {
  padding: 0.75rem 1.75rem;
  border-bottom: 1px solid #f0f2f5;
  color: #3d5170;
  font-size: 0.8125rem;
  font-weight: 400;
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
  .main-sidebar .dropdown-menu .dropdown-item {
    font-weight: 300;
  }
}

.main-sidebar .dropdown-menu .dropdown-item.active,
.main-sidebar .dropdown-menu .dropdown-item:hover {
  color: #007bff;
}

.main-sidebar .dropdown-menu .dropdown-item:hover {
  background: none;
}

.main-sidebar .dropdown-menu .dropdown-item.active {
  background-color: #fbfbfb;
}

.main-sidebar .dropdown-menu .dropdown-item:last-of-type {
  border-bottom: 1px solid #e1e5eb;
}

.main-sidebar .dropdown-menu .dropdown-divider {
  margin: 0;
}

.main-sidebar .dropdown-toggle {
  position: relative;
}

.main-sidebar .dropdown-toggle:after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcuNDEgNy44NEwxMiAxMi40Mmw0LjU5LTQuNThMMTggOS4yNWwtNiA2LTYtNnoiLz48cGF0aCBkPSJNMC0uNzVoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=);
  background-position: 50%;
  width: 0.875rem;
  height: 0.5625rem;
  transition: transform 0.25s ease-in-out;
  border: none;
  position: absolute;
  top: 50%;
  right: 0.625rem;
  transform: translateY(-50%);
  opacity: 0.1;
  will-change: transform;
}

.main-sidebar .dropdown.show .dropdown-toggle:after {
  transform: translateY(-50%) rotate(180deg);
}

@media (max-width: 767.98px) {
  .main-sidebar__search {
    box-sizing: border-box;
    border-right: 0 !important;
    padding: 0.625rem 0;
    border-bottom: 1px solid #e1e5eb;
  }

  .main-sidebar__search .input-group {
    margin: 0 !important;
  }
}

.main-sidebar__nav-title {
  text-transform: uppercase;
  margin: 0;
  font-size: 0.625rem;
  letter-spacing: 0.125rem;
  padding: 1px 1.5625rem;
  font-weight: 500;
  color: #9ea8b9;
  border-bottom: 1px solid #e1e5eb;
}

.icon-sidebar-nav .main-sidebar {
  transition:
    transform 0.2s ease-in-out,
    width 0.2s ease-in-out;
  width: 4.75rem;
}

@media (max-width: 767.98px) {
  .icon-sidebar-nav .main-sidebar {
    width: 100%;
  }

  .icon-sidebar-nav .main-sidebar span {
    opacity: 1 !important;
    font-size: inherit !important;
  }

  .icon-sidebar-nav .main-sidebar .main-sidebar__nav-title {
    color: #9ea8b9;
  }
}

.icon-sidebar-nav .main-sidebar__nav-title {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
  color: #fff;
  transition: color 0.25s ease-in-out;
  will-change: color;
}

.icon-sidebar-nav .main-sidebar:hover {
  width: 13.75rem;
}

@media (max-width: 767.98px) {
  .icon-sidebar-nav .main-sidebar:hover {
    width: 100%;
  }
}

.icon-sidebar-nav .main-sidebar:hover .nav-link {
  text-overflow: visible;
}

.icon-sidebar-nav .main-sidebar:hover .nav-link span {
  opacity: 1 !important;
  font-size: inherit !important;
}

.icon-sidebar-nav .main-sidebar:hover .main-sidebar__nav-title {
  color: #9ea8b9;
}

.icon-sidebar-nav .main-sidebar .nav-wrapper {
  overflow: visible;
}

.icon-sidebar-nav .main-sidebar .nav .nav-link {
  text-overflow: clip;
}

.icon-sidebar-nav .main-sidebar .nav .nav-link span {
  opacity: 0;
  font-size: 0;
  transition: opacity 0.2s ease-in-out;
}

.icon-sidebar-nav .main-sidebar .nav-item {
  overflow: visible;
}

.icon-sidebar-nav .main-sidebar .dropdown-toggle:after {
  background-size: 100%;
}

@media (min-width: 768px) {
  .icon-sidebar-nav .main-sidebar .dropdown-menu {
    border-radius: 0 0.375rem 0.375rem 0;
    position: absolute !important;
    left: 100% !important;
    width: 12.5rem;
    box-shadow:
      0 2px 0 rgba(90, 97, 105, 0.11),
      0 4px 8px rgba(90, 97, 105, 0.12),
      0 10px 10px rgba(90, 97, 105, 0.06),
      0 7px 70px rgba(90, 97, 105, 0.1);
    transition: opacity 0.25s ease-in-out;
    display: block;
    visibility: hidden;
    opacity: 0;
  }

  .icon-sidebar-nav .main-sidebar .dropdown-menu.show {
    opacity: 1;
    visibility: visible;
  }

  .icon-sidebar-nav .main-sidebar .dropdown-menu .dropdown-item:first-of-type {
    border-radius: 0 0.375rem 0 0;
  }

  .icon-sidebar-nav .main-sidebar .dropdown-menu .dropdown-item:last-of-type {
    border-bottom: 0;
    border-radius: 0 0 0.375rem 0;
  }
}

.icon-sidebar-nav .main-content {
  padding: 0 0 0 4.75rem;
}

@media (max-width: 767.98px) {
  .icon-sidebar-nav .main-content {
    padding-left: 0;
  }
}

/* Modo "Icon Sidebar Fixed" */
.icon-sidebar-nav-fixed .main-sidebar {
  width: 13.75rem; /* Fijo a 13.75rem */
  transition: none; /* Sin animaciones para cambio de ancho */
}

.icon-sidebar-nav-fixed .main-sidebar .nav-wrapper {
  overflow: hidden; /* Asegura que no haya comportamiento no deseado */
}

.icon-sidebar-nav-fixed .main-sidebar .nav-link span {
  opacity: 1 !important; /* Texto completamente visible */
  font-size: inherit !important; /* Tamaño de fuente normal */
}

.icon-sidebar-nav-fixed .main-sidebar .main-sidebar__nav-title {
  color: #9ea8b9; /* Color normal para los títulos */
}

.icon-sidebar-nav-fixed .main-sidebar .dropdown-menu {
  border-radius: 0 0.375rem 0.375rem 0;
  position: absolute !important;
  left: 100% !important;
  width: 12.5rem;
  box-shadow:
    0 2px 0 rgba(90, 97, 105, 0.11),
    0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06),
    0 7px 70px rgba(90, 97, 105, 0.1);
  transition: opacity 0.25s ease-in-out;
  display: block;
  visibility: visible;
  opacity: 1;
}

.icon-sidebar-nav-fixed .main-content {
  padding: 0 0 0 13.75rem; /* Ajustar contenido según el ancho del sidebar */
}

@media (max-width: 767.98px) {
  .icon-sidebar-nav-fixed .main-sidebar {
    width: 100%; /* Full width en pantallas pequeñas */
  }

  .icon-sidebar-nav-fixed .main-content {
    padding-left: 0; /* Ajusta el contenido para pantallas pequeñas */
  }
}

.main-navbar__search .input-group-prepend .input-group-text,
.main-sidebar__search .input-group-prepend .input-group-text {
  font-size: 0.6875rem;
  padding: 0.75rem 1.0625rem;
}

.main-navbar__search input.form-control,
.main-sidebar__search input.form-control {
  border: none;
  font-size: 0.8125rem;
  border-radius: 0;
}

.main-navbar__search input.form-control:focus,
.main-navbar__search input.form-control:hover,
.main-sidebar__search input.form-control:focus,
.main-sidebar__search input.form-control:hover {
  box-shadow: none;
}

.main-content > .main-content-container.container-fluid {
  min-height: calc(100vh - 7.5rem);
}

.main-footer {
  height: 3.75rem;
}

.main-footer .copyright {
  color: #818ea3;
}

@media (max-width: 767.98px) {
  .main-footer {
    display: block !important;
    height: auto;
  }

  .main-footer .nav {
    width: 100%;
    display: block;
    border-bottom: 1px solid #e1e5eb;
    padding-bottom: 0.75rem;
  }

  .main-footer .copyright {
    display: inline-block;
    width: 100%;
    padding: 1rem;
    text-align: center;
  }
}

.page-header .page-title {
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 1;
  margin: 0;
  padding: 0;
}

@media (max-width: 767.98px) {
  .page-header .page-title {
    font-size: 2rem;
  }
}

.page-header .page-subtitle {
  letter-spacing: 0.125rem;
  color: #818ea3;
  font-size: 0.625rem;
}

@media (max-width: 767.98px) {
  .page-header .page-subtitle {
    font-size: 0.8125rem;
    font-weight: 400;
  }
}

.header-navbar i {
  font-size: 0.875rem;
  margin-right: 0.125rem;
}

.header-navbar .nav-item.dropdown .nav-link {
  padding-right: 1.25rem;
}

.header-navbar .nav-item.dropdown .nav-link:after {
  content: "";
  display: block;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcuNDEgNy44NEwxMiAxMi40Mmw0LjU5LTQuNThMMTggOS4yNWwtNiA2LTYtNnoiLz48cGF0aCBkPSJNMC0uNzVoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=);
  background-position: 50%;
  width: 0.875rem;
  height: 0.5625rem;
  transition: transform 0.25s ease-in-out;
  border: none;
  position: absolute;
  top: 45%;
  right: 20px;
  transform: translateY(-50%);
  opacity: 0.2;
  will-change: transform;
  transform: scale(0.8);
}

.header-navbar .nav-link {
  color: #3d5170;
  padding: 1.125rem 0;
  margin-right: 1.25rem;
  line-height: 1;
  border-radius: 0;
  font-size: 0.8125rem;
  background: transparent;
  border: none;
  border-bottom: 1px solid transparent;
}

.header-navbar .nav-link i {
  color: #cacedb;
  transition: color 0.2s ease;
}

.header-navbar .nav-item.dropdown.show .nav-link,
.header-navbar .nav-link.active,
.header-navbar .nav-link:hover {
  border-bottom: 1px solid #007bff;
  color: #007bff;
  cursor: pointer;
}

.header-navbar .nav-item.dropdown.show .nav-link i,
.header-navbar .nav-link.active i,
.header-navbar .nav-link:hover i {
  color: #007bff;
}

@media (max-width: 991.98px) {
  .header-navbar .nav-item.dropdown .nav-link {
    position: relative;
    border: 0;
    padding-right: 0;
  }

  .header-navbar .nav-item.dropdown .nav-link:after {
    z-index: 1000;
    right: 5px;
  }

  .header-navbar .nav-item.dropdown .dropdown-menu {
    position: static !important;
    transform: none !important;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    border: none;
  }

  .header-navbar .nav-link {
    margin-right: 0;
    transition: none;
    font-size: 0.875rem;
  }

  .header-navbar .dropdown-item {
    border-radius: 5px;
    font-size: 0.875rem;
    padding: 0.625rem 1.375rem;
    font-weight: 400;
  }

  .header-navbar .nav-item.dropdown.show .nav-link,
  .header-navbar .nav-link.active,
  .header-navbar .nav-link:hover {
    border: 0;
  }
}

.country-stats .table td {
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
}

.country-stats .table tbody tr td {
  font-weight: 400;
}

.country-stats .table tbody tr td:first-child,
.country-stats .table tbody tr td:nth-child(2) {
  color: #3d5170;
}

.country-stats .table tbody tr td:nth-child(3) {
  color: #818ea3;
}

.country-stats .country-flag {
  max-width: 1.25rem;
}

.stats-small {
  min-height: 8.7rem;
  overflow: hidden !important;
}

.stats-small canvas {
  position: absolute;
  bottom: 0;
}

.stats-small__data {
  flex: 1 1;
  display: flex;
  justify-content: center;
  flex-flow: column;
  max-width: 50%;
  z-index: 1;
}

.stats-small__label {
  font-size: 0.625rem;
  letter-spacing: 0.0625rem;
  color: #818ea3;
}

.stats-small__value {
  font-family:
    Roboto,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
}

.stats-small__percentage {
  position: relative;
  display: table;
  margin-left: auto;
  padding-left: 0.9375rem;
}

.stats-small__percentage--decrease,
.stats-small__percentage--increase {
  font-size: 0.75rem;
}

.stats-small__percentage--decrease:before,
.stats-small__percentage--increase:before {
  content: "";
  width: 0.75rem;
  height: 0.375rem;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-position: 50%;
  background-repeat: no-repeat;
}

.stats-small__percentage--increase {
  color: #17c671;
}

.stats-small__percentage--increase:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMTdjNjcxIiBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcgMTRsNS01IDUgNXoiLz48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PC9zdmc+);
}

.stats-small__percentage--decrease {
  color: #c4183c;
}

.stats-small__percentage--decrease:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjYzQxODNjIiBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcgMTBsNSA1IDUtNXoiLz48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PC9zdmc+);
}

.stats-small--1 .stats-small__data {
  max-width: 100%;
}

.stats-small--1 .stats-small__percentage {
  margin: 0 auto;
}

.stats-small--1 .stats-small__value {
  font-size: 2.0625rem;
}

.stats-small--1 .stats-small__label,
.stats-small--1 .stats-small__percentage {
  font-size: 0.75rem;
}

.stats-small--1 canvas {
  opacity: 0.5;
}

.card-group .stats-small {
  position: relative;
  overflow: hidden;
}

.ubd-stats__item,
.ubd-stats__legend {
  display: flex;
}

.ubd-stats__legend {
  width: 100%;
}

.ubd-stats__item {
  flex-direction: column;
  flex: 1 1;
  text-align: center;
}

.ubd-stats__item i {
  font-size: 1.375rem;
  margin-bottom: 0.3125rem;
}

.ubd-stats__category {
  color: #818ea3;
  font-size: 0.8125rem;
  margin-bottom: 0.125rem;
}

.ubd-stats__value {
  font-size: 1.0625rem;
  color: #3d5170;
  font-weight: 500;
  line-height: 1rem;
}

.go-stats .list-group .list-group-item {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.go-stats__label {
  margin: 0;
  padding: 0;
  font-size: 0.95rem;
  font-weight: 500;
}

.go-stats__meta {
  font-size: 0.75rem;
  color: #818ea3;
}

.go-stats__meta strong {
  color: #3d5170;
}

.go-stats__value {
  flex: 1 1;
  padding-right: 0.625rem;
}

#analytics-overview-date-range {
  max-width: 350px;
}

@media (max-width: 575.98px) {
  #analytics-overview-date-range {
    max-width: 100%;
  }
}

.sc-stats {
  font-size: 0.875rem;
  font-weight: 400;
}

@media screen and (min-width: 992px) and (max-width: 1170px) {
  .sc-stats {
    font-size: 0.75rem;
  }
}

.sc-stats__image {
  max-width: 80px;
}

@media (max-width: 991.98px) {
  .sc-stats__image {
    max-width: 3.125rem;
  }
}

.sc-stats__image img {
  width: 2.8125rem;
  height: auto;
}

.sc-stats__title {
  padding-left: 0.25rem !important;
}

@media screen and (max-width: 1170px) {
  .sc-stats__title {
    padding-left: 0.625rem !important;
  }
}

.sc-stats__percentage {
  color: #818ea3;
}

.sc-stats .card-body {
  position: relative;
  overflow-y: auto;
  min-height: 12.5rem;
}

@media screen and (max-width: 992px) {
  .sc-stats .card-body {
    min-height: 21rem;
  }
}

.sc-stats .container-fluid {
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1px;
}

.sc-stats .container-fluid .row {
  display: flex;
  flex: 1 1;
  border-bottom: 1px solid #e1e5eb;
  min-height: 3.75rem;
}

.sc-stats .container-fluid .row:last-of-type {
  border-bottom: 0;
}

.sc-stats .container-fluid .row > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
}

.lo-stats {
  font-size: 0.8125rem;
}

.lo-stats .container-fluid {
  overflow-x: auto;
}

.lo-stats table {
  min-width: 600px;
}

.lo-stats thead th {
  font-size: 0.75rem;
  font-weight: 400;
  border-width: 1px;
  border-top: none;
  padding: 0.5rem 0.75rem;
}

.lo-stats td {
  vertical-align: middle;
}

.lo-stats__image {
  width: 70px;
}

.lo-stats__image img {
  width: 3.125rem;
  height: 3.125rem;
}

.lo-stats__items,
.lo-stats__total {
  font-weight: 400;
}

.lo-stats__order-details span {
  display: block;
}

.lo-stats__order-details span:first-child {
  font-weight: 500;
  font-size: 1rem;
}

.lo-stats__order-details span:last-child {
  font-size: 0.625rem;
  color: #818ea3;
}

.lo-stats .card-body {
  position: relative;
  overflow-x: auto;
  min-height: 21rem;
}

.lo-stats .container-fluid {
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 41rem;
}

.lo-stats .container-fluid .row:not(.lo-stats__header) {
  display: flex;
  flex: 1 1;
  border-bottom: 1px solid #e1e5eb;
  min-height: 3.75rem;
}

.lo-stats .container-fluid .row:not(.lo-stats__header):last-of-type {
  border-bottom: 0;
}

.lo-stats .container-fluid .row:not(.lo-stats__header) > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#sales-overview-date-range {
  max-width: 350px;
}

@media (max-width: 575.98px) {
  #sales-overview-date-range {
    max-width: 100%;
  }
}

.file-manager__group-title {
  display: block;
  font-size: 0.75rem;
  font-weight: 500;
  margin: 1rem 0;
}

.file-manager-list td,
.file-manager-list th {
  border-left: none !important;
  border-right: none !important;
}

.file-manager-list .file-manager__item-meta,
.file-manager-list .file-manager__item-title {
  margin: 0;
}

.file-manager-list .file-manager__item-title {
  display: block;
  font-size: 1rem;
  line-height: 1.25rem;
}

.file-manager-list .file-manager__item-meta {
  display: inline-block;
  font-size: 0.6875rem;
  color: #818ea3;
  line-height: 0.8125rem;
}

.file-manager-list .file-manager__item-icon {
  padding-right: 0 !important;
}

.file-manager-list .file-manager__item-icon div {
  border-radius: 50%;
  font-size: 0.9375rem;
  text-align: center;
  color: #818ea3;
  width: 2.1875rem;
  height: 2.1875rem;
  line-height: 2.1875rem;
  background: #f5f6f8;
  box-shadow:
    0 0 0 2px #fff,
    inset 0 0 3px rgba(0, 0, 0, 0.2);
}

.file-manager-list .file-manager__item-icon i {
  top: 2px;
}

.file-manager-cards__search {
  max-width: 200px;
}

@media (max-width: 991.98px) {
  .file-manager-cards__search {
    max-width: 100%;
  }
}

.file-manager-cards .file-manager__item {
  overflow: hidden;
  position: relative;
}

.file-manager-cards .file-manager__item .card-footer {
  display: flex;
}

.file-manager-cards .file-manager__item:hover {
  cursor: pointer;
}

.file-manager-cards .file-manager__item:after,
.file-manager-cards .file-manager__item:before {
  content: "";
  position: absolute;
}

.file-manager-cards .file-manager__item:before {
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: 50%;
  background-color: #007bff;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRkZGIiBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik05IDE2LjE3TDQuODMgMTJsLTEuNDIgMS40MUw5IDE5IDIxIDdsLTEuNDEtMS40MXoiLz48L3N2Zz4=);
  background-size: 1.0625rem;
  width: 1.5625rem;
  height: 1.5625rem;
  right: 0.625rem;
  top: -1.875rem;
  transform: rotate(-90deg);
  will-change: top, right, opacity, transform;
  transition:
    top 0.15s cubic-bezier(0.27, 0.01, 0.38, 1.06),
    right 0.15s cubic-bezier(0.27, 0.01, 0.38, 1.06),
    opacity 0.15s cubic-bezier(0.27, 0.01, 0.38, 1.06),
    transform 0.15s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.file-manager-cards .file-manager__item:after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  border-radius: 0.625rem;
  box-shadow: inset 0 0 0 0 rgba(0, 123, 255, 0.2);
  will-change: box-shadow;
  transition: box-shadow 0.25s ease-in-out;
}

.file-manager-cards .file-manager__item--selected:before {
  top: 0.625rem;
  transform: rotate(0);
}

.file-manager-cards .file-manager__item--selected:after {
  box-shadow:
    inset 0 0 3.125rem rgba(0, 123, 255, 0.2),
    inset 0 0 0.625rem rgba(0, 123, 255, 0.4),
    inset 0 0 0 0.125rem rgba(0, 123, 255, 0.75);
}

.file-manager-cards .file-manager__item--directory:before {
  width: 1.25rem;
  height: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  right: -1.25rem;
}

.file-manager-cards
  .file-manager__item--directory.file-manager__item--selected:before {
  right: 10px;
  background-size: 70%;
}

.file-manager-cards .file-manager__item-icon {
  position: relative;
  margin-right: 0.625rem;
  font-size: 1.0625rem;
  top: 2px;
}

.file-manager-cards .file-manager__item-icon i.material-icons {
  top: 0;
}

.file-manager-cards .file-manager__item-title {
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 0.875rem;
  padding-right: 1.25rem;
}

.file-manager-cards .file-manager__item-size {
  margin: auto 0;
  font-size: 0.6875rem;
}

.file-manager-cards .file-manager__item-preview {
  background: #f8f8f8;
}

.file-manager-cards .file-manager__item-preview img {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: table;
  box-shadow: 0 0.125rem 0.5625rem rgba(0, 0, 0, 0.13);
}

.user-details .card-header {
  overflow: hidden;
}

.user-details .card-body {
  margin-top: -3.125rem;
  z-index: 1;
}

.user-details .card-body h4 {
  font-weight: 500;
}

.user-details__bg {
  width: 100%;
  position: relative;
  max-height: 7.1875rem;
}

.user-details__bg img {
  width: 100%;
}

.user-details__bg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(90, 97, 105, 0.3);
}

.user-details__avatar {
  border-radius: 50%;
  overflow: hidden;
  max-width: 6.25rem;
  box-shadow:
    0 2px 0 rgba(90, 97, 105, 0.11),
    0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06),
    0 7px 70px rgba(90, 97, 105, 0.1);
}

.user-details__avatar img {
  width: 100%;
}

.user-details__social {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.user-details__social li {
  display: inline-block;
}

.user-details__social a {
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  background: #e9ecef;
  color: #818ea3;
}

.user-details__user-data .col span {
  display: block;
}

.user-details__user-data .col span:first-child {
  font-weight: 400;
  color: #3d5170;
}

.user-details__user-data .col span:last-child {
  color: #818ea3;
}

.user-details__tags .badge {
  font-weight: 300;
}

.user-stats h4 {
  font-weight: 600;
}

.user-stats span {
  font-size: 0.6875rem;
}

.user-teams__image {
  max-width: 3.125rem;
}

.user-teams__image img {
  width: 100%;
  max-width: 3.125rem;
}

.user-teams__info span:first-child {
  font-weight: 400;
}

.user-teams__info span:last-child {
  font-size: 80%;
}

.user-teams .card-body {
  position: relative;
  overflow-y: auto;
  min-height: 14.0625rem;
}

@media screen and (max-width: 992px) {
  .user-teams .card-body {
    min-height: 21rem;
  }
}

.user-teams .container-fluid {
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.user-teams .container-fluid .row {
  display: flex;
  flex: 1 1;
  border-bottom: 1px solid #e1e5eb;
  min-height: 4.6875rem;
}

.user-teams .container-fluid .row:last-of-type {
  border-bottom: 0;
}

.user-teams .container-fluid .row > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
}

.user-activity__item {
  display: flex;
  margin-left: 1.875rem;
  border-left: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  font-weight: 400;
}

.user-activity__item:last-child {
  border-bottom: 0;
}

.user-activity__item:after {
  display: block;
  clear: both;
  content: "";
}

.user-activity__item__icon {
  text-align: center;
  border-radius: 50%;
  float: left;
  width: 1.875rem;
  height: 1.875rem;
  min-width: 1.875rem;
  background: #f5f6f8;
  margin-left: -0.9375rem;
  margin-right: 0.9375rem;
  box-shadow:
    0 0 0 2px #fff,
    inset 0 0 3px rgba(0, 0, 0, 0.2);
}

.user-activity__item__icon i {
  font-size: 1rem;
  line-height: 1.875rem;
  color: #aeb9c4;
}

.user-activity__item__content {
  float: left;
}

.user-activity__item__content p {
  margin: 0;
}

.user-activity__item__content a {
  font-weight: 400;
}

.user-activity__item__content span {
  font-size: 80%;
}

.user-activity__item__action {
  float: right;
}

.user-activity__item__task-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.user-activity__item__task-list .custom-control {
  line-height: 1.5rem;
}

.edit-user-details .card-header {
  overflow: hidden;
}

.edit-user-details .card-body {
  z-index: 1;
}

.edit-user-details__bg {
  width: 100%;
  position: relative;
  max-height: 7.1875rem;
}

.edit-user-details__bg img {
  width: 100%;
}

.edit-user-details__bg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(90, 97, 105, 0.5);
  will-change: background-color;
  transition: background-color 0.25s ease-in-out;
}

.edit-user-details__bg .edit-user-details__change-background {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  opacity: 1;
  transform: translate(-50%, -50%);
  background-color: hsla(0, 0%, 100%, 0.06);
  border: 1px dashed rgba(233, 236, 239, 0.3);
  padding: 0.4375rem 0.9375rem;
  color: #fff;
  border-radius: 0.375rem;
  box-shadow: 0.375rem;
  font-size: 0.75rem;
  will-change: opacity;
  transition:
    opacity 0.25s ease-in-out,
    transform 0.25s ease-in-out;
}

.edit-user-details__bg .edit-user-details__change-background:hover {
  cursor: pointer;
  transform: translate(-50%, -50%) scale(1.05);
}

.edit-user-details__bg .edit-user-details__change-background i {
  font-size: 0.9375rem;
  top: 0.125rem;
}

.edit-user-details__avatar {
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  max-width: 7.5rem;
  box-shadow:
    0 2px 0 rgba(90, 97, 105, 0.11),
    0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06),
    0 7px 70px rgba(90, 97, 105, 0.1);
}

.edit-user-details__avatar img {
  width: 100%;
}

.edit-user-details__avatar__change {
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
  border-radius: 50%;
  font-size: 1.875rem;
  background: hsla(0, 0%, 100%, 0.95);
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.edit-user-details__avatar__change i {
  color: #9ea8b9;
  line-height: 7.5rem;
}

.edit-user-details__avatar:hover .edit-user-details__avatar__change {
  opacity: 1;
  cursor: pointer;
}

.auth-form {
  min-width: 19.375rem;
  max-width: 350px;
}

.auth-form .card-body {
  overflow: hidden;
  box-shadow: inset 0 4px 0 0 #007bff;
  border-radius: 0.625rem;
}

.auth-form__logo {
  max-width: 2.5rem;
}

.auth-form__title {
  font-size: 1.125rem;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    sans-serif;
}

.auth-form__social-icons {
  margin: 0;
  padding: 0;
}

.auth-form__social-icons li {
  list-style: none;
  display: inline-block;
}

.auth-form__social-icons li a {
  padding: 0 0.75rem;
  font-size: 1.25rem;
  color: #cacedb;
  transition: color 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.auth-form__social-icons li a:hover {
  color: #007bff;
}

.auth-form__meta a {
  font-weight: 400;
  color: #818ea3;
}

.auth-form__meta a:hover {
  color: #3d5170;
}

.quick-post-form {
  flex-flow: column;
}

.quick-post-form,
.quick-post-form .form-group:nth-child(2) {
  display: flex;
  flex: 1 1;
}

.quick-post-form textarea {
  resize: none;
  min-height: 100px;
}

.blog-comments__avatar img {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.25rem;
}

.blog-comments__item {
  padding: 0;
  border-bottom: 1px solid #e1e5eb;
}

.blog-comments__item:last-child {
  border: 0;
}

.blog-comments__actions {
  font-size: 95%;
}

.blog-comments__actions .btn-group button {
  padding: 0.25rem 0.5625rem;
}

.add-new-post__editor {
  min-height: 400px;
}

.card-post .card-body {
  padding: 1.5625rem;
}

.card-post .card-footer,
.card-post .card-header {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}

.card-post__image {
  position: relative;
  min-height: 10.3125rem;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
  background-repeat: no-repeat;
}

.card-post__author-avatar,
.card-post__image {
  background-size: cover;
  background-position: 50%;
}

.card-post__author-avatar {
  width: 2.8125rem;
  height: 2.8125rem;
  box-shadow:
    0 0 0 0.125rem #fff,
    0 0.1875rem 0.4375rem rgba(90, 97, 105, 0.5);
  display: block;
  border-radius: 50%;
  text-indent: -9999px;
}

.card-post__author-avatar--small {
  width: 2.1875rem;
  height: 2.1875rem;
}

.card-post__author-name {
  font-weight: 500;
}

.card-post--aside {
  display: flex;
  flex-flow: row;
}

.card-post--aside .card-post__image {
  border-top-right-radius: 0;
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
  min-width: 180px;
}

.card-post--1 .card-post__author,
.card-post--1 .card-post__category {
  position: absolute;
}

.card-post--1 .card-post__author {
  transform: translateY(50%);
  margin-left: 1.5625rem;
  position: absolute;
  bottom: 0;
}

.card-post--1 .card-post__category {
  top: 0.9375rem;
  right: 0.9375rem;
  position: absolute;
  text-transform: uppercase;
}

.card-post--1 .card-body {
  padding-top: 2.1875rem;
}

.card-post--1.card-post--aside .card-body {
  padding: 1.5625rem;
}

.card-post--1.card-post--aside .card-post__author {
  left: 0.9375rem;
  bottom: 0.9375rem;
  transform: none;
  margin: 0;
}

.card-post--1.card-post--aside .card-post__category {
  right: auto;
  top: 0.9375rem;
  left: 0.9375rem;
}

.error {
  height: calc(100vh - 3.75rem);
  display: flex;
}

.error__content {
  padding: 0 0.9375rem;
  display: flex;
  flex-flow: column;
  margin: auto;
  align-items: center;
  text-align: center;
}

.error__content h2 {
  color: #cacedb;
  font-weight: 700;
  font-size: 3.75rem;
  margin-bottom: 1.5625rem;
}

.error__content h3 {
  font-weight: 500;
  font-size: 2.1875rem;
  margin-bottom: 0.625rem;
}

.error__content p {
  color: #818ea3;
}

.dropzone {
  text-align: center;
  font-weight: 400;
  padding: 0.75rem 1.0625rem;
  font-size: 13px;
  border: 1px dashed #dadfe4;
  border-radius: 0.375rem;
}

.file-manager__dropzone {
  padding: 0.75rem 1.0625rem;
  border-bottom: 1px solid #e1e5eb;
}

.add-new-post__editor .ql-container,
.add-new-post__editor .ql-editor {
  min-height: 400px;
}

.date-range .react-datepicker-wrapper {
  display: flex;
  flex: 1 1;
  max-width: 160px;
}

.date-range .react-datepicker__input-container {
  width: 100%;
}

@media (max-width: 575px) {
  .date-range .react-datepicker-wrapper {
    max-width: 100%;
  }
}

.ReactTable {
  border: none;
}

.ReactTable .-pagination,
.ReactTable .rt-thead.-header {
  box-shadow: none;
}

.ReactTable .rt-tbody .rt-td {
  font-size: 0.8125rem;
  padding: 0.4375rem 1.0625rem;
  border-left: 1px solid #e1e5eb;
  border-bottom: 1px solid #e1e5eb;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReactTable .rt-tbody .rt-td:first-of-type {
  border-left: none;
}

.ReactTable .rt-tbody .tr-td {
  border-bottom: none !important;
}

.ReactTable .rt-tbody .rt-tr-group {
  border: none;
}

.ReactTable .rt-tr-group:last-of-type .rt-td {
  border-bottom: none !important;
}

.ReactTable .rt-thead .rt-tr {
  border-bottom: 1px solid #e1e5eb;
}

.ReactTable .rt-thead .rt-th {
  background-color: #fbfbfb;
  font-weight: 400;
  padding: 0.75rem 1.0625rem !important;
  font-size: 13px !important;
  border: 13px !important;
}

.ReactTable .rt-thead .rt-td.-sort-asc,
.ReactTable .rt-thead .rt-th.-sort-asc {
  box-shadow: inset 0 3px 0 0 #007bff;
}

.ReactTable .rt-thead .rt-td.-sort-desc,
.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #007bff;
}

.ReactTable .-pagination {
  font-size: 13px;
  border: none;
  border-top: 1px solid #e1e5eb;
  padding: 0.4375rem 1.0625rem;
}

.ReactTable .-pagination .-btn {
  padding: 0.4286rem 0.875rem;
  width: auto;
  color: #3d5170;
  font-weight: 500;
}

.ReactTable .-pagination .-pageJump input {
  width: 50px;
}

.ReactTable .-pagination .-next button {
  margin-left: auto;
}

.ReactTable .-pagination button {
  padding: 0.4286rem 0.875rem;
  background: #fff !important;
  border: 1px solid #e1e5eb !important;
  border-radius: 0.25rem !important;
}

.ReactTable .-pagination button:hover {
  color: #fff;
  background: #007bff !important;
}

.ReactTable .-pagination .-btn[disabled],
.ReactTable .-pagination .-btn[disabled]:hover {
  background-color: #f2f4f5 !important;
  color: #3d5170;
}

.file-manager__filters {
  padding: 0.625rem;
}

.file-manager__filters__rows {
  font-size: 13px;
  font-weight: 400;
  color: #818ea3;
}

.file-manager__filters__rows span {
  line-height: 30px;
  margin-right: 5px;
}

.file-manager__filters__rows .custom-select {
  display: table;
  width: auto;
  border: 1px solid #e1e5eb;
  font-size: 0.625rem;
  color: #818ea3;
  height: 1.5625rem !important;
  background-color: #fff;
  border-radius: 0.375rem;
  padding: 0 25px 0 10px;
  margin: 3px 0.1875rem 0;
}

.file-manager__filters__search input {
  display: table;
  min-width: 11.25rem;
}

.file-manager__filters__search .input-group {
  width: 11.25rem;
}

.react-tagsinput {
  height: auto;
  padding: 0.4375rem 0.75rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #e1e5eb;
  font-weight: 300;
  will-change: border-color, box-shadow;
  border-radius: 0.25rem;
  box-shadow: none;
  transition:
    box-shadow 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.react-tagsinput:hover {
  cursor: text;
}

.react-tagsinput--focused {
  color: #495057;
  background-color: #fff;
  border-color: #007bff;
  box-shadow:
    0 0.313rem 0.719rem rgba(0, 123, 255, 0.1),
    0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

.react-tagsinput-tag {
  display: inline-block;
  background: #fbfbfb;
  padding: 0.125rem 1.375rem 0.125rem 0.625rem;
  border-radius: 1.25rem;
  font-size: 0.6875rem;
  position: relative;
  text-transform: uppercase;
  border: 1px solid #e1e5eb;
  color: #818ea3;
  margin-right: 4px;
  margin-bottom: 4px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: 700;
}

.react-tagsinput a:before {
  content: "";
  position: absolute;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE5IDYuNDFMMTcuNTkgNSAxMiAxMC41OSA2LjQxIDUgNSA2LjQxIDEwLjU5IDEyIDUgMTcuNTkgNi40MSAxOSAxMiAxMy40MSAxNy41OSAxOSAxOSAxNy41OSAxMy40MSAxMnoiLz48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PC9zdmc+);
  right: 0.5rem;
  top: 50%;
  width: 0.625rem;
  height: 0.625rem;
  background-repeat: no-repeat;
  background-position: 50%;
  transform: translateY(-50%);
  background-size: 100%;
  opacity: 0.5;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 80px;
}

.rbc-calendar .rbc-toolbar {
  margin-bottom: 1em;
}

.rbc-calendar .rbc-toolbar button {
  background: #fff;
  box-shadow: none;
  border-color: #e1e5eb;
  padding: 7px 11px;
  height: auto;
  font-size: 0.6875rem;
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.rbc-calendar .rbc-toolbar button:focus {
  outline: 0;
}

.rbc-calendar .rbc-toolbar button:hover {
  border-color: #e1e5eb;
  background-color: initial;
  cursor: pointer;
  box-shadow:
    0 0.125rem 0.625rem rgba(129, 142, 163, 0.2),
    0 0.0625rem 0.125rem rgba(129, 142, 163, 0.3);
}

.rbc-calendar .rbc-toolbar button.rbc-active {
  border-color: #007bff;
  background: #007bff;
  color: #fff;
}

.rbc-calendar .rbc-toolbar-label {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 1px;
  color: #b9c2cd;
}

.rbc-calendar .rbc-month-view .rbc-header {
  padding: 0.5rem 0;
}

.rbc-calendar .rbc-date-cell {
  padding: 5px;
}

.rbc-calendar .rbc-event {
  background: #007bff;
  padding: 3px 1px;
  border-radius: 5px;
}

.rbc-calendar .rbc-event .rbc-event-content {
  font-size: 0.85em;
  margin-left: 3px;
}

.rbc-calendar .rbc-day-slot .rbc-event {
  border-color: #007bff;
  padding: 5px;
}

.rbc-calendar .rbc-day-slot .rbc-time-slot {
  border-color: #e3e3e3;
}

.rbc-calendar .rbc-agenda-view table.rbc-agenda-table,
.rbc-calendar .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td,
.rbc-calendar .rbc-day-bg + .rbc-day-bg,
.rbc-calendar .rbc-header,
.rbc-calendar .rbc-month-row + .rbc-month-row,
.rbc-calendar .rbc-month-view,
.rbc-calendar .rbc-time-content,
.rbc-calendar .rbc-time-content > * + * > *,
.rbc-calendar .rbc-time-header-content,
.rbc-calendar .rbc-time-view,
.rbc-calendar .rbc-timeslot-group {
  border-color: #ededed !important;
}

.rbc-calendar .rbc-current-time-indicator {
  height: 2px;
  background-color: #da0000;
}

.rbc-calendar .rbc-current-time-indicator:after {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  right: 0;
  background-color: #da0000;
  border-radius: 50%;
  top: 50%;
  margin-top: -3px;
}

.rbc-calendar .rbc-time-column.rbc-today {
  background: #fff3e8;
}

.rbc-calendar .rbc-time-column.rbc-today .rbc-time-slot,
.rbc-calendar .rbc-time-column.rbc-today .rbc-timeslot-group {
  border-color: #ecded1 !important;
}

.rbc-calendar .rbc-today {
  background: transparent;
}

.rbc-calendar .rbc-month-row {
  min-height: 125px;
}

.rbc-calendar .rbc-off-range-bg {
  background-color: #f7f7f7;
}

.rbc-calendar .rbc-label {
  font-size: 10px;
}

.rbc-calendar .rbc-time-content {
  border-width: 1px;
}

.rbc-calendar .rbc-timeslot-group:last-child,
.rbc-calendar .rbc-timeslot-group:last-child .rbc-time-slot:last-child {
  border: none;
}

.rbc-calendar .rbc-timeslot-group .rbc-time-slot:first-child {
  border-top: none;
}

.rbc-calendar .rbc-time-view .rbc-header {
  min-height: 25px;
}

.rbc-calendar .rbc-time-view .rbc-header a {
  padding: 3px;
  display: inline-block;
}

@media (max-width: 991.98px) {
  .rbc-calendar .rbc-toolbar {
    display: flex;
    flex-flow: column;
  }

  .rbc-calendar .rbc-toolbar-label {
    margin: 10px 0;
  }
}

