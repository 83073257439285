.divClient {
  /* height: calc(100vh - 299px); */
  height: calc(100vh - 315px);
  border-left: 1px solid lightgray;
  border-right: 1px solid #d3d3d3;
  overflow: auto;
}

.rowCaja {
  height: calc(100vh - 320px);
}

.rowTablaCaja {
  height: calc(100vh - 450px);
}

.tablaCaja {
  max-height: calc(100vh - 450px);
}

.divClient3 {
  height: calc(100vh - 350px);
}

.tablaDetalleVenta {
  height: calc(100vh - 380px);
  /* border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray; */
  overflow: auto;
}

.colum {
  padding: 10px;
}

.ml-sm-auto {
  padding-top: 0px;
  padding-bottom: 0px;
  padding: 0px;
}

.page-header.py-4.no-gutters.row {
  padding-bottom: 10px !important;
  padding-top: 7px !important;
}

.botonCaja {
  height: 46px;
  width: 105px;
}

.colBoton {
  padding-right: 10px !important;
  padding-left: 5px !important;
  padding-top: 15px !important;
}

.rowBtn {
  padding-top: 12px;
}

.inputCash {
  padding-bottom: 8px;
}

.inputCashArqueo {
  border-radius: 10px !important;
  font-size: 20px !important;
}

.ventasDiv {
  height: 350px;
}

.labelCajaNombre {
  font-size: 18px !important;
  color: black;
}

.labelCaja {
  font-size: 18px !important;
  border: 1px solid gray;
  border-radius: 3px;
  width: 130px;
  text-align: center;
  color: black;
}

.labelCajaMenor-0 {
  font-size: 18px !important;
  background-color: rgba(211, 64, 28, 0.788);
  border: 1px solid gray;
  border-radius: 3px;
  width: 130px;
  text-align: center;
  color: white;
}

.caja-entrada {
  color: darkgreen;
  font-weight: bold;
  text-align: center;
}

.caja-salida {
  color: darkred;
  font-weight: bold;
  text-align: center;
}

.tableSelected {
  border: 2px solid rgba(93, 136, 216, 0.37) !important;
  background-color: rgba(54, 54, 77, 0.137) !important;
  margin-top: 1px;
  margin-bottom: 1px;
}

.noSelect {
  font-weight: bold;
  font-size: 15px;
}

.headerToggle {
  padding: 0px !important;
  padding-left: 15px !important;
  padding-top: 7px !important;
}

/* ------TABLA DE MOSTRAR VENTAS----- */
.mostrarVentas {
  height: calc(100vh - 330px);
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  overflow: overlay;
}

/* --------------------- */

/* ---------TAMAÑO Y DE TEXTO------ */
.FS14 {
  font-size: 14px;
}

/* ------------------------------------ */

/* --------------BOTONES DE CAJA------------ */
.FSBTNCAJA {
  font-size: 0.875rem;
}

/* ----------------------------------- */

/* ------------LABEL DE ARQUEO---------- */
.colArq {
  border-radius: 10px !important;
  background-color: rgb(238, 238, 238) !important;
  border: 1px solid lightgrey !important;
}

.lalbelArq {
  margin-top: 4px;
  margin-bottom: 4px;
}
